import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import {connect} from "react-redux";
import {compose} from "redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {bookingAudience} from "../../actions";

class BookingModal extends PureComponent {
    handleSave = () => {
        const {data,title} = this.state;
        this.props.bookingAudience({
            title,
            audienceId: data.audienceId,
            date: data.date,
            lessonNumber: data.lessonNumber
        });
        this.props.hide("bookingAudienceModal")
    };

    constructor(props) {
        super(props);
        console.log(props,"props")
        this.state = {
            title: "",
            data: props.data,

        }
    }

    render() {
        const {show, hide} = this.props;
        return (
            <PureModal
                header={"Бронювання аудиторій"}
                footer={<div className="float-right">
                    <Button color={"red"} icon onClick={() => hide("bookingAudienceModal")}>
                        <Icon name='remove'/> Закрити
                    </Button>
                    <Button primary onClick={this.handleSave}>
                        <Icon name='save'/> Зберегти
                    </Button>
                </div>}
                width={"40vw"}
                isOpen={show}
                onClose={() => {
                    this.props.hide("bookingAudienceModal");
                }}
            >
                <Table celled textAlign={"center"} verticalAlign={"middle"}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Причина бронювання аудиторії
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <input className={"form-control"}
                                       value={this.state.title}
                                       placeholder={"Причина бронювання аудиторії"}
                                       onChange={e => this.setState({title: e.target.value})}/>
                            </Table.Cell>
                        </Table.Row>

                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {}
}

export default compose(
    connectModal({
        name: 'bookingAudienceModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        bookingAudience
    })
)(BookingModal);