import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {
    DELETE_TEACHER_RATE,
    EDIT_TEACHER_RATES,
    FETCH_TEACHERS_RATES,
    INSERT_TEACHER_RATE,
} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchTeachersRates() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/teachers/rates`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHERS_RATES, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }
}


export function editTeachersRatesInfo( row) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/university/teachers/rates/edit`, row, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_TEACHER_RATES, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertTeachersRates(row) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/university/teachers/rate/create`, row, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_TEACHER_RATE, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteTeacherRates(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/university/teachers/rate/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_TEACHER_RATE, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}



