import React, {Component} from "react";
import {ContextMenu, MenuItem} from "react-contextmenu";
import {connect} from "react-redux";
import {show} from 'redux-modal';
import {
    audienceCoincidences,
    compCoincidences,
    deleteAudience,
    deleteLesson,
    fetchLessonNumbers,
    fetchWeeksTemplate,
    getLessonsCount,
    insertExtraLesson,
    projectorCoincidences,
    validateAudiences
} from "../../../actions";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {fetchUserTimetable} from "../../../../Timetable/actions";
import {toast} from "react-toastify";

class LessonContextMenu extends Component {


    handleDeleteLesson = (e, data) => {
        const lesson = data.lesson;
        this.props.deleteLesson({id: lesson.id}, lesson.teacherLoadId);
    };

    handleDeleteAudience = (e, data) => {
        const lesson = data.lesson;
        this.props.deleteAudience({id: lesson.id});
    };

    handleAddExtraLesson = (e, data) => {
        const lesson = data.lesson;
        this.props.insertExtraLesson(
            {id: lesson.id},
            {
                id: lesson.teacherLoadId,
                isExtraLesson: lesson.isExtraLesson
            });
    };

    onShowAudiences = (e, data) => {
        this.props.audienceCoincidences({
            id: data.lesson.id,
            facultyId: "test"
        });

        this.props.compCoincidences({id: data.lesson.id});
        this.props.projectorCoincidences({id: data.lesson.id});
        this.props.validateAudiences(data.lesson.id);
        this.props.show("audiencesModal", {id: data.lesson.id, lesson: data.lesson});
    };

    onCountLessonsByWeek = (e, data) => {
        getLessonsCount(data.lesson.id)
    };

    onEditLesson = (e, data) => {
        this.props.show("editLessonInfo", {id: data.lesson.id, lesson: data.lesson});
    };

    onCopyLessons = (e, data) => {
        this.props.fetchLessonNumbers({id: data.lesson.teacherLoadId});
        this.props.fetchWeeksTemplate({});
        this.props.show("createLessonTemplateModal", {lesson: data.lesson});
    };
    showTeacherTimetable = (e, data) => {
        if(data.lesson.teacherId) {
            const week = localStorage.getItem('OA.UM-week') !== null && localStorage.getItem('OA.UM-week').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-week')).value : "";
            this.props.fetchUserTimetable({
                me: false,
                week: week,
                teacher: data.lesson.teacherId,
                showEmptyRows: false
            });
            this.props.show("teacherTimetableModal", {lesson: data.lesson});
        }else{
            toast.info("Виберіть пару з 1 викладачем", {
                position: "top-center",
                autoClose: 5000,
            });

        }

    };

    render() {
        return (
            <ContextMenu id="lesson_contextMenu">
                <MenuItem onClick={this.onCopyLessons}>
                    <Icon name={"copy"} color={"blue"}/> Скопіювати
                </MenuItem>
                <MenuItem onClick={this.onShowAudiences}>
                    <Icon name={"checkmark box"} color={"green"}/>
                    Вибрати аудиторію
                </MenuItem>
                <MenuItem onClick={this.handleDeleteAudience}>
                    <Icon name={"trash"} color={"red"}/>
                    Видалити аудиторію
                </MenuItem>
                <MenuItem onClick={this.handleDeleteLesson}>
                    <Icon name={"remove"} color={"red"}/> Видалити запис
                </MenuItem>
                <MenuItem onClick={this.onEditLesson}>
                    <Icon name={"info"} color={"orange"}/> Даталі
                </MenuItem>
                <MenuItem onClick={this.handleAddExtraLesson}>
                    <Icon name={"add"}/>
                    Додаткова пара
                </MenuItem>
                <MenuItem onClick={this.showTeacherTimetable}>
                    <Icon name={"clock outline"}/>
                    Переглянути розклад викладача
                </MenuItem>
                <MenuItem onClick={this.onCountLessonsByWeek}>
                    <Icon name="ordered list"/>
                    Переглянути к-сть пар
                </MenuItem>

            </ContextMenu>
        );
    }

}


export default connect(null, {
    deleteLesson,
    deleteAudience,
    insertExtraLesson,
    show,
    validateAudiences,
    fetchWeeksTemplate,
    audienceCoincidences,
    projectorCoincidences,
    compCoincidences,
    fetchLessonNumbers,
    fetchUserTimetable
})(LessonContextMenu);