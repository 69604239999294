import {List} from "immutable"
import {EDIT_DEPARTMENT_REQUEST_INFO, FETCH_DEPARTMENT_REQUEST, MARGE_DEPARTMENT_REQUESTS,} from "./types"

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case MARGE_DEPARTMENT_REQUESTS:
        case FETCH_DEPARTMENT_REQUEST:
            return List(action.payload);
        case EDIT_DEPARTMENT_REQUEST_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        default:
            return state;
    }
}