import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Loader from "semantic-ui-react/dist/es/elements/Loader/Loader";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {Accordion, AccordionItem} from "react-sanfona";
import "./styles.css";
import {fetchStudents} from "../Students/actions";
import Students from "../Students";
import UniversityGroupsContextMenu from "./components/UniversityGroupsContextMenu";
import GroupsModal from "./components/GroupsModal";
import EditUniversityGroupModal from "./components/EditUniversityGroupModal";
import {ContextMenuTrigger} from "react-contextmenu";
import {
    fetchSpecialities4SelectWithStudyLevel,
    fetchUniversityGroups4Lessons
} from "../../../components/Data4Select/actions";
import {writeErrors} from "../../../utils/logs";
import {fetchHierarchyUniversityGroups} from "./actions";


class HierarchyUniversityGroups extends PureComponent {
    state = {
        selectedCell: '',
        name: '',
    };
    renderAccordion = (speciality, i) => {
        return <Accordion key={`accordion_${i}`}>
            <AccordionItem key={speciality.specialityId}
                           title={speciality.speciality}
                           expanded={false}>
                <Table celled textAlign={"center"} verticalAlign={"middle"}>
                    <Table.Body>
                        {this.renderTableRows(speciality.universityGroups)}
                    </Table.Body>
                </Table>
            </AccordionItem>
        </Accordion>
    };
    renderTableRows = (rows) => {
        return rows.map((row, i) => {
            return <Table.Row key={`row_${i}`}>
                {this.renderTableCells(row)}
            </Table.Row>
        })
    };
    renderTableCells = (cells) => {
        return cells.map(cell => {
            return <Table.Cell
                onClick={() => {
                    this.setState({selectedCell: cell.id, name: cell.name});
                    this.props.fetchStudents(cell.id)
                }}
                colSpan={cell.colSpan}
                rowSpan={cell.rowSpan}
                className={`${this.state.selectedCell === cell.id ? "highlight " : ''}`}
                key={cell.id}>
                <ContextMenuTrigger holdToDisplay={-1} id="universityGroups_contextMenu" data={cell}
                                    collect={props => props.data}>
                    {cell.name} <em>({cell.numberStudents})</em>
                </ContextMenuTrigger>
            </Table.Cell>
        })
    };

    componentDidMount() {
        this.props.fetchUniversityGroups4Lessons();
        this.props.fetchSpecialities4SelectWithStudyLevel();
        this.props.fetchHierarchyUniversityGroups();
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    render() {
        if (this.props.groups.size < 1) {
            return <Loader active size={"massive"} content='Завантаження списку студентів'/>
        }
        return <div>
            <UniversityGroupsContextMenu/>
            <GroupsModal/>
            <EditUniversityGroupModal/>
            <div>
                <Tabs>
                    <TabList>
                        {this.props.groups.map((items, i) => {
                            return <Tab key={`tabList_${i}`}>{i + 1 + " курс"}</Tab>
                        })}
                    </TabList>


                    {this.props.groups.map((items, i) => {
                        return <TabPanel key={`tabPanel_${i}`}>
                            <div style={{overflow: 'auto', height: '24vh'}}>
                                {items.map((speciality, i) => {
                                    return this.renderAccordion(speciality, i);
                                })}
                            </div>
                        </TabPanel>
                    })}


                </Tabs>
            </div>
            <h3 className={"text-center"}>Вибрано групу: {this.state.name}</h3>
            <div style={{overflow: 'auto', height: '43vh'}}>
                <Students selectedGroup={this.state.selectedCell}/>
            </div>
        </div>;
    }
}

function mapStateToProps(state) {
    return {
        groups: state.get("groups")
    }
}

export default connect(mapStateToProps, {
    fetchHierarchyUniversityGroups,
    fetchStudents,
    fetchSpecialities4SelectWithStudyLevel,
    fetchUniversityGroups4Lessons,
})(HierarchyUniversityGroups)
