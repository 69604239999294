import React, {PureComponent} from "react";
import {connect} from "react-redux";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import {compose} from "redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {writeErrors} from "../../../../../utils/logs";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import {removeLesson} from "../../../actions";
import {isUserAdmin} from "../../../../../utils";

class TeacherJournalModal extends PureComponent {


    removeLesson(id) {
        this.props.removeLesson({id});
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    render() {
        const isAdmin = isUserAdmin();

        const {show, hide, discipline} = this.props;
        return (
            <PureModal
                header={`Журнал пар - ${discipline}`}
                footer={<div className={"float-right"}>
                    <Button color={"red"} icon onClick={() => hide("teacherJournalModal")}>
                        <Icon name='remove'/> Закрити
                    </Button>
                </div>}
                onClose={() => {
                    this.props.hide("teacherJournalModal");
                }}
                width={"40vw"}
                isOpen={show}
            >
                <Table celled textAlign={"center"} verticalAlign={"middle"}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>№</Table.HeaderCell>
                            <Table.HeaderCell>Пара</Table.HeaderCell>
                            <Table.HeaderCell>Дата</Table.HeaderCell>
                            {isAdmin &&
                            <Table.HeaderCell>
                                Видалити пару
                            </Table.HeaderCell>}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            this.props.teacherJournal.map(lesson => {
                                return (
                                    <Table.Row key={lesson.id}>
                                        <Table.Cell>
                                            {lesson.number}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {lesson.lessonNumber}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {lesson.date}
                                        </Table.Cell>
                                        {isAdmin && <Table.Cell>
                                            <Popup
                                                trigger={
                                                    <Icon name={"trash"} size={"large"}
                                                          color={"red"}
                                                          onClick={this.removeLesson.bind(this, lesson.id)}/>
                                                }
                                                content={"Видалити пару"}
                                            />

                                        </Table.Cell>}
                                    </Table.Row>
                                );
                            })
                        }
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        teacherJournal: state.get("lessons").get("teacherJournal"),
    }
}

export default compose(
    connectModal({
        name: 'teacherJournalModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        removeLesson
    })
)(TeacherJournalModal);