import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {DELETE_SCHOLARSHIP, EDIT_SCHOLARSHIP_INFO, FETCH_SCHOLARSHIPS, INSERT_SCHOLARSHIP} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchScholarships() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/student/scholarships`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request
            .then(({data}) => {
                dispatch({type: FETCH_SCHOLARSHIPS, payload: data})

            }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function editScholarshipInfo(scholarship) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/student/scholarships/edit`, scholarship, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_SCHOLARSHIP_INFO, payload: scholarship})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertScholarship(scholarship) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/student/scholarship/create`, scholarship, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_SCHOLARSHIP, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteScholarship(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/student/scholarship/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_SCHOLARSHIP, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


