import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editDepartmentRequests} from "../../actions";
import {loadingMessage, NoOptionsMessage} from "../../../../../utils";
import Select from "react-select";
import AsyncSelect from 'react-select/lib/Async';
import {fetchTeachers4Select} from "../../../../../components/Data4Select/actions";

const actions = [
    {value: 2, label: 'Надіслано',},
    {value: 3, label: 'Підтверджено',},
    {value: 4, label: 'Скасовано',},
];

class DepartmentRequestModal extends PureComponent {
    handleSave = () => {
        const object = {
            id: this.state.id,
            teacherId: this.state.teacher ? this.state.teacher.value : null,
            teacherReplacementId: this.state.teacherReplacement ? this.state.teacherReplacement.value : null,
            requestStatus: this.state.action.value,
        };
        this.props.editDepartmentRequests(object);

        this.props.hide("departmentRequestModal")
    };
    loadOptions = inputValue => fetchTeachers4Select(inputValue);
    constructor(props) {
        super(props);
        let row = props.row._original;

        this.state = {
            id: row.id,
            discipline: row.discipline,
            numberHours: row.numberHours,
            semester: row.semester,
            disciplineType: row.disciplineType,
            teacher: row.teacherId ? {
                value: row.teacherId,
                label: row.teacher
            } : null,

            teacherReplacement: row.teaherReplacementId ? {
                value: row.teacherReplacementId,
                label: row.teacherReplacement
            } : null,
            group: row.groups,
            action: row.requestStatus ? actions[parseInt(row.requestStatus, 10) - 1] : actions[0],
        }
    }

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Редагування інформації по заявці"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("departmentRequestModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
                onClose={() => this.props.hide("departmentRequestModal")}
            >
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Предмет
                            </Table.Cell>
                            <Table.Cell>
                                {this.state.discipline}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Години
                            </Table.Cell>
                            <Table.Cell>
                                {this.state.numberHours}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Тип заняття
                            </Table.Cell>
                            <Table.Cell>
                                {this.state.disciplineType}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Наскрізний семестр
                            </Table.Cell>
                            <Table.Cell>
                                {this.state.semester}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Група/Потік
                            </Table.Cell>
                            <Table.Cell>
                                {this.state.group}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Викладач
                            </Table.Cell>
                            <Table.Cell>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={this.loadOptions}
                                    noOptionsMessage={NoOptionsMessage}
                                    loadingMessage={loadingMessage}
                                    isClearable={false}
                                    defaultValue={this.state.teacher}
                                    onChange={(selectedValue) => this.setState({teacher: selectedValue})}
                                    placeholder={"Введіть ПІП викладача"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Заміна викладача
                            </Table.Cell>
                            <Table.Cell>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={this.loadOptions}
                                    noOptionsMessage={NoOptionsMessage}
                                    loadingMessage={loadingMessage}
                                    isClearable={false}
                                    defaultValue={this.state.teacherReplacement}
                                    onChange={(selectedValue) => this.setState({teacherReplacement: selectedValue})}
                                    placeholder={"Введіть ПІП заміни викладача"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Статус заявки
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={actions}
                                    onChange={(selectedValue) => {
                                        this.setState({
                                            action: selectedValue,
                                        })
                                    }}
                                    defaultValue={this.state.action}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Вкажіть статус заявки"}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        groups: state.get("data4Select").get("groups"),
        teachers: state.get("data4Select").get("teachers"),
    }
}

export default compose(
    connectModal({
        name: 'departmentRequestModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editDepartmentRequests
    }))(DepartmentRequestModal);

