import {List} from "immutable";
import {
    DELETE_TEACHERS_CONSULTATION,
    EDIT_TEACHERS_CONSULTATION_INFO,
    FETCH_TEACHERS_CONSULTATIONS,
    INSERT_TEACHERS_CONSULTATION,
} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_TEACHERS_CONSULTATIONS:
            return List(action.payload);
        case EDIT_TEACHERS_CONSULTATION_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case INSERT_TEACHERS_CONSULTATION:
            if (action.payload) {
                return state.insert(0, action.payload);
            }
            return state;
        case DELETE_TEACHERS_CONSULTATION:
            return state.delete(state.findIndex(i => i.id === action.payload));
        default:
            return state;
    }
}