import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {show} from "redux-modal";
import Loader from "semantic-ui-react/dist/es/elements/Loader/Loader";
import {fetchTeachersRanks} from "../Ranks/actions";
import {fetchTeachersPositions} from "../Positions/actions";
import {writeErrors} from "../../../utils/logs";
import {loadingText, nextText, noDataText, ofText, pageText, previousText, rowsText} from "../../../utils/locale";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {deleteTeacher, fetchTeachers} from "./actions";
import TeacherModal from "./components/TeacherModal";

class Teachers extends PureComponent {

    componentDidMount() {
        this.props.fetchTeachers();
        this.props.fetchTeachersRanks();
        this.props.fetchTeachersPositions();
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    handleAddTeacher() {
        this.props.show("teacherModal", {isCreate: true});
    }

    handleEditTeacher(row) {
        this.props.show("teacherModal", {row, isCreate: false});
    }

    handleDeleteTeacher(id) {
        this.props.deleteTeacher(id);
    }


    render() {
        if (this.props.rows.size < 1 || this.props.ranks.size < 1 || this.props.positions.size < 1) {
            return (<Loader active size={"massive"} content='Завантаження викладацького складу'/>)
        }
        return (<div>
            <TeacherModal {...this.state}/>
            <ReactTable
                data={this.props.rows.toArray()}
                filterable
                columns={[

                    {
                        Header: "ПІП",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["fullName"]}),
                        filterAll: true,
                        accessor: "fullName",
                    },
                    {
                        Header: "email",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["email"]}),
                        filterAll: true,
                        accessor: "email",
                    },
                    {
                        Header: "Звання",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["rank"]}),
                        filterAll: true,
                        accessor: "rank",
                        className: "text-center",
                    },
                    {
                        Header: "Посада",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["position"]}),
                        filterAll: true,
                        accessor: "position",
                        className: "text-center",
                    },
                    {

                        Header: <Popup
                            trigger={
                                <Icon name='add' size={"big"}
                                      color={"green"}
                                      onClick={
                                          this.handleAddTeacher.bind(this)}/>
                            }
                            content='Додати запис'
                            on={'hover'}
                            style={{zIndex: 3001}}
                        />,
                        accessor: "id",
                        width: 100,
                        Cell: ({row}) => {
                            return (<div style={{marginTop: "15px"}}>

                                <Popup
                                    trigger={<Icon name={"edit"}
                                                   color={"blue"}
                                                   onClick={this.handleEditTeacher.bind(this, row)}
                                    />}
                                    content="Редагувати інформацію"
                                    hideOnScroll
                                    on='hover'
                                />
                                <Popup
                                    trigger={<Icon name={"trash"}
                                                   color={"red"}
                                                   onClick={this.handleDeleteTeacher.bind(this, row._original.id)}
                                    />}
                                    content="Видалити запис"
                                    hideOnScroll
                                    on='hover'
                                />
                            </div>)
                        },
                        sortable: false,
                        filterable: false,
                        style: {
                            cursor: "pointer",
                            fontSize: 25,
                            padding: "0",
                            textAlign: "center",
                            userSelect: "none"
                        }
                    },
                ]}
                defaultPageSize={10}
                style={{
                    height: "80vh",
                }}
                className="table-bordered table-striped white-bg"
                noDataText={noDataText}
                previousText={previousText}
                nextText={nextText}
                loadingText={loadingText}
                pageText={pageText}
                ofText={ofText}
                rowsText={rowsText}
            />
        </div>);


    }
}


function mapStateToProps(state) {
    return {
        rows: state.get("teachers"),
        ranks: state.get("ranks"),
        positions: state.get("positions"),
    }
}

export default connect(mapStateToProps, {
    deleteTeacher,
    fetchTeachers,
    fetchTeachersRanks,
    fetchTeachersPositions,
    show
})(Teachers)