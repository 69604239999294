import React, {Component} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import DatePicker from "react-date-picker";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {NoOptionsMessage} from "../../../utils";
import Select from "react-select";
import {editUserSpecialtyInfo} from "../actions";

class UserSpecialtyInfo extends Component {
    constructor(props) {
        super(props);
        const row = props.row;
        console.log(row);
        this.state = {
            dateStart: new Date(row.dateStartValue),
            dateEnd: new Date(row.dateEndValue),
            id: row.id,
            name: row.name,
            studentPaymentForm: {label: row.studentPaymentForm, value: row.studentPaymentFormValue}
        };
    }

    handleSave = () => {
        const object = {
            id: this.state.id,
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd,
            studentPaymentForm: this.state.studentPaymentForm.value
        };

        this.props.editUserSpecialtyInfo(object);
        this.props.hide("editUserSpecialtyModal")
    };


    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={`Редагування інформації про спеціальность користувача`}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("editUserSpecialtyModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                onClose={() => {
                    this.props.hide("editUserSpecialtyModal");
                }}
                isOpen={show}
            >


                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Спеціальність
                            </Table.Cell>
                            <Table.Cell>
                                {this.state.name}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Дата вступу
                            </Table.Cell>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) => this.setState({dateStart: value})}
                                    locale={"uk-UA"}
                                    value={this.state.dateStart}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Дата випуску
                            </Table.Cell>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) => this.setState({dateEnd: value})}
                                    locale={"uk-UA"}
                                    value={this.state.dateEnd}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Форма навчання
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={[{label: "Державна", value: 2}, {
                                        label: "Контракт",
                                        value: 1
                                    }]}
                                    onChange={(selected) => {
                                        this.setState({studentPaymentForm: selected})
                                    }}
                                    defaultValue={this.state.studentPaymentForm}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Форма навчання"}
                                />
                            </Table.Cell>
                        </Table.Row>

                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        scholarships: state.get("scholarships"),

    }
}

export default compose(
    connectModal({
        name: 'editUserSpecialtyModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editUserSpecialtyInfo
    }))(UserSpecialtyInfo);

