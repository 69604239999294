import {List} from "immutable";
import {DELETE_WEEK, EDIT_WEEK_INFO, FETCH_WEEKS, GENERATE_WEEKS} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_WEEKS:
            return List(action.payload);
        case EDIT_WEEK_INFO:
            const index = state.findIndex(i => i.id === action.payload.id);
            let row = state.get(index);
            action.payload.lineNumber = row.lineNumber;
            action.payload.semester = row.semester;
            return state.set(index, action.payload);
        case GENERATE_WEEKS:
            return List(action.payload);
        case DELETE_WEEK:
            return state.delete(state.findIndex(i => i.id === action.payload));
        default:
            return state;
    }
}