import Cookie from "js-cookie";
import {ROOT_URL} from "../../../../../../utils";
import axios from "axios";

export function changeUserDepartment(id) {
    const token = Cookie.get('OA.UM-Auth');
    axios.post(`${ROOT_URL}/v1/user/changeDepartment`, null, {
        params: {id},
        headers: {"Authorization": `Bearer ${token}`}
    });
}