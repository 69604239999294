import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {
    DELETE_USER_TYPE,
    EDIT_USER_TYPE_INFO,
    FETCH_USER_TYPES,
    INSERT_USER_TYPE
} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchUserTypes() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/user/types`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request
            .then(({data}) => {
                dispatch({type: FETCH_USER_TYPES, payload: data})

            }).catch((error) => {
            writeErrors(error);
        });
    }

}



export function editUserTypeInfo(UserType) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/user/types/edit`, UserType, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_USER_TYPE_INFO, payload: UserType})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertUserType(UserType) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/user/type/create`, UserType, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_USER_TYPE, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteUserType(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/user/type/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_USER_TYPE, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


