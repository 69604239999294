import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editDisciplinesInfo, insertDiscipline} from "../../actions";
import Cookie from "js-cookie";
import {getDefaultValue, getOptions, NoOptionsMessage, ROOT_URL} from "../../../../../utils";
import axios from "axios/index";
import debounce from "lodash.debounce";
import Select from "react-select";

class DisciplinesModal extends PureComponent {
    handleInputChange = (e) => {
        const token = Cookie.get('OA.UM-Auth');
        const request = axios.get(`${ROOT_URL}/v1/university/disciplines-suggestions`, {
            params: {name: e.target.value},
            headers: {"Authorization": `Bearer ${token}`}
        });
        request.then(({data}) => {
            this.setState({disciplines: data})
        });
        this.setState({name: e.target.value})

    };
    handleSave = () => {
        const object = {
            id: this.state.id,
            name: this.state.name,
            shortName: this.state.shortName,
            disciplineCode: this.state.disciplineCode,
            isArchived: this.state.isArchived.value,
        };
        if (this.state.isCreate) {
            this.props.insertDiscipline(object);
        } else {
            this.props.editDisciplinesInfo(object);
        }

        this.props.hide("disciplinesModal")
    };

    constructor(props) {
        super(props);
        let row = {};
        if (props.isCreate) {
            row = {};
        } else {
            row = props.row._original
        }
        const defaultValue = getDefaultValue();
        this.state = {
            id: row.id || "",
            isCreate: props.isCreate,
            name: row.name || "",
            shortName: row.shortName || "",
            disciplineCode: row.disciplineCode || "",
            isArchived: row.isArchived ? {value: row.isArchived, label: row.isArchived} : defaultValue,
            disciplines: []

        }
    }

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Створення та редагування інформації про предмет"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={e =>
                            this.props.hide("disciplinesModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
                onClose={() => {
                    this.props.hide("disciplinesModal");
                }}
            >
                <div>
                    <Table celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    Назва предмету
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={debounce(this.handleInputChange, 500, {leading: true})}
                                        className={"form-control"}
                                        value={this.state.name}
                                        placeholder={"Введіть назву дисциплніни"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Скорочена назва предмету
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({shortName: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.shortName}
                                        placeholder={"Введіть скорочену назву предмету"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Код предмету
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({disciplineCode: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.disciplineCode}
                                        placeholder={"Введіть код предмету"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    В архів
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={getOptions()}
                                        onChange={(selected) => {
                                            this.setState({
                                                isArchived: selected || getDefaultValue()
                                            })
                                        }}
                                        defaultValue={this.state.isArchived}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Перемістити запис в архів"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
                {this.state.disciplines.length > 0 && <div>
                    <h3 className={"text-center"}>Наявні такі дисципліни:</h3>
                    <div style={{
                        overflow: "auto",
                        maxHeight: "45vh"
                    }}>
                        <Table celled compact={"very"}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell textAlign={"center"}
                                                      verticalAlign={"middle"}>Назва предмету</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={"center"}
                                                      verticalAlign={"middle"}>Скорочена назва
                                        предмету</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={"center"}
                                                      verticalAlign={"middle"}>Код предмету</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.disciplines.map(discipline => {
                                    return (<Table.Row key={discipline.id}>
                                        <Table.Cell>
                                            {discipline.name}
                                        </Table.Cell>
                                        <Table.Cell> {discipline.shortName}</Table.Cell>
                                        <Table.Cell> {discipline.disciplineCode}</Table.Cell>
                                    </Table.Row>)
                                })}
                            </Table.Body>
                        </Table>
                    </div>
                </div>}
            </PureModal>
        );
    }
}


export default compose(
    connectModal({
        name: 'disciplinesModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(null, {
        hide,
        editDisciplinesInfo,
        insertDiscipline
    }))(DisciplinesModal);

