import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editTeachersInfo, fetchEmails, insertTeacher} from "../../actions";
import Select from "react-select";
import {loadingMessage, NoOptionsMessage} from "../../../../../utils";
import AsyncSelect from 'react-select/lib/Async';

class FlowsModal extends PureComponent {
    handleSave = () => {
        const object = {
            id: this.state.id,
            rankId: this.state.rank ? this.state.rank.id : '',
            positionId: this.state.position ? this.state.position.id : '',
            email: this.state.email.value,
        };
        if (this.state.isCreate) {
            this.props.insertTeacher(object);
        } else {
            this.props.editTeachersInfo(object);
        }

        this.props.hide("teacherModal")
    };
    loadOptions = inputValue => fetchEmails(inputValue);

    constructor(props) {
        super(props);
        let row = {};
        if (props.isCreate) {
            row = {};
        } else {
            row = props.row._original
        }
        this.state = {
            id: row.id || "",
            isCreate: props.isCreate,
            email: row.email ? {
                value: row.email,
                label: row.fullName
            } : null,
            rank: row.rankId ? {
                id: row.rankId,
                name: row.rank
            } : null,
            position: row.positionId ? {
                id: row.positionId,
                name: row.position
            } : null,
        }
    }

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Створення та редагування інформації про викладача"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("teacherModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button disabled={this.state.email && this.state.email.length < 1} primary
                                onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                onClose={() => this.props.hide("teacherModal")}
                isOpen={show}
            >
                <Table celled>
                    <Table.Body>

                        <Table.Row>
                            <Table.Cell className={"w-15"}>
                                Пошта викладача<em> (@oa.edu.ua)</em>
                            </Table.Cell>
                            <Table.Cell>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={this.loadOptions}
                                    noOptionsMessage={NoOptionsMessage}
                                    loadingMessage={loadingMessage}
                                    isClearable={false}
                                    defaultValue={this.state.email}
                                    isDisabled={!this.state.isCreate}
                                    onChange={(selectedValue) => this.setState({email: selectedValue})}
                                    placeholder={"Вкажіть пошту або ПІП викладача"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell className={"w-15"}>
                                Звання викладача
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={this.props.ranks.toArray()}
                                    onChange={(selected) => {
                                        this.setState({rank: selected})
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    defaultValue={this.state.rank}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Вкажіть звання викладача"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell className={"w-15"}>
                                Посада викладача
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={this.props.positions.toArray()}
                                    onChange={(selected) => {
                                        this.setState({position: selected})
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    defaultValue={this.state.position}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Вкажіть посаду викладача"}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        ranks: state.get("ranks"),
        positions: state.get("positions"),
    }
}

export default compose(
    connectModal({
        name: 'teacherModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editTeachersInfo,
        insertTeacher
    }))(FlowsModal);

