import React, {PureComponent} from "react";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {connect} from "react-redux";
import {editOptionsInfo} from "../../actions";
import Loader from "semantic-ui-react/dist/es/elements/Loader/Loader";
import {writeErrors} from "../../../../utils/logs";

class Options extends PureComponent {


    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    handleInputChange(e) {
        const {name, value} = e.target;
        this.props.editOptionsInfo({
            key: name,
            value
        });
    }

    handleCheckboxChange(name) {
        const index = this.props.options.findIndex(i=>i.key === name);
        const value = this.props.options.get(index).value;
        this.props.editOptionsInfo({
            key: name,
            value: value.toLowerCase() !== "true"
        });
    }

    render() {
        const {options} = this.props;
        if (options.size < 1) {
            return (<Loader active size={"massive"} content='Завантаження налаштувань'/>)
        }
        const studyYear = options.filter(i => i.key === "studyYear").get(0);
        const studyWorkplan = options.filter(i => i.key === "studyWorkplan").get(0);
        const teacherLoad = options.filter(i => i.key === "teacherLoad").get(0);
        const teacherLoadHourly = options.filter(i => i.key === "teacherLoadHourly").get(0);
        return (

            <div style={{overflow: "auto", height: "80vh"}}>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell cstyle={{
                                textAlign: "center"
                            }}>Ключ</Table.HeaderCell>
                            <Table.HeaderCell style={{
                                textAlign: "center"
                            }}>Значення</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                {studyYear.name || "Навчальний план"}
                            </Table.Cell>
                            <Table.Cell
                                style={{
                                    textAlign: "center"
                                }}>
                                <div className="pretty p-default  p-curve p-fill" style={{
                                    fontSize: "24px"
                                }}>
                                    <input
                                        onChange={this.handleCheckboxChange.bind(this, studyYear.key)}
                                        type="checkbox"
                                        defaultChecked={studyYear.value.toLowerCase() === "true"}
                                        disabled={studyYear.value.toLowerCase() === "true"}/>
                                    <div className="state p-info">
                                        <label/>
                                    </div>
                                </div>
                            </Table.Cell>
                        </Table.Row><Table.Row>
                        <Table.Cell>
                            {studyWorkplan.name || "Робочий навчальний план"}
                        </Table.Cell>
                        <Table.Cell
                            style={{
                                textAlign: "center"
                            }}>
                            <div className="pretty p-default  p-curve p-fill" style={{
                                fontSize: "24px"
                            }}>
                                <input
                                    onChange={this.handleCheckboxChange.bind(this, studyWorkplan.key)}
                                    type="checkbox"
                                    defaultChecked={studyWorkplan.value.toLowerCase() === "true"}
                                    disabled={studyWorkplan.value.toLowerCase() === "true"}/>
                                <div className="state p-info">
                                    <label/>
                                </div>
                            </div>
                        </Table.Cell>
                    </Table.Row><Table.Row>
                        <Table.Cell>
                            {teacherLoad.name || "Навантаження"}
                        </Table.Cell>
                        <Table.Cell
                            style={{
                                textAlign: "center"
                            }}>
                            <div className="pretty p-default  p-curve p-fill" style={{
                                fontSize: "24px"
                            }}>
                                <input
                                    onChange={this.handleCheckboxChange.bind(this, teacherLoad.key)}
                                    type="checkbox"
                                    defaultChecked={teacherLoad.value.toLowerCase() === "true"}
                                    disabled={teacherLoad.value.toLowerCase() === "true"}/>
                                <div className="state p-info">
                                    <label/>
                                </div>
                            </div>
                        </Table.Cell>
                    </Table.Row><Table.Row>
                        <Table.Cell>
                            {teacherLoadHourly.name || "Годин на скавку"}
                        </Table.Cell>
                        <Table.Cell>
                            <input
                                value={teacherLoadHourly.value}
                                type="number"
                                className={"form-control w-100"}
                                name={teacherLoadHourly.key}
                                onChange={this.handleInputChange.bind(this)}
                            />
                        </Table.Cell>
                    </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        options: state.get("options")
    }
}

export default connect(mapStateToProps, {
    editOptionsInfo
})(Options)