import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {fetchLessonTimes} from "./actions";
import Loader from "semantic-ui-react/dist/es/elements/Loader/Loader";
import {writeErrors} from "../../../utils/logs";
import ReactTable from "react-table";


class LessonsTimes extends PureComponent {
    componentDidMount() {
        this.props.fetchLessonTimes();
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    gridColumn() {
        return [
            {
                key: 'numberLesson',
                name: '№',
                editable: false,
                resizable: true,
            },
            {
                key: 'start',
                name: 'Початок пари',
                editable: false,
                resizable: true,
            },
            {
                key: 'end',
                name: 'Кінець пари',
                editable: false,
                resizable: true,
            },
            {
                key: 'breakTime',
                name: 'Перерва(хв)',
                editable: false,
                resizable: true,
            }
        ]
            ;
    }

    rowGetter(index) {
        if (index < 0) {
            return undefined;
        }
        return this.props.lessonsTimes.get(index);
    }


    render() {
        if (this.props.lessonsTimes.size < 1) {
            return (<Loader active size={"massive"} content='Завантаження розкладу звінків'/>)
        }
        return (
            <ReactTable
                data={this.props.lessonsTimes.toArray()}
                columns={[

                    {
                        Header: "№",
                        accessor: "numberLesson",
                    }, {
                        Header: "Початок пари",
                        accessor: "start",
                    }, {
                        Header: "Кінець пари",
                        accessor: "end",
                    }, {
                        Header: "Перерва(хв)",
                        accessor: "breakTime",
                    },
                ]}
                style={{
                    maxHeight: "80vh",
                }}
                minRows={9}
                showPagination={false}
                className="table-bordered table-striped white-bg text-center"
            />

        );
    }
}

function mapStateToProps(state) {
    return {
        lessonsTimes: state.get("lessonsTimes"),
    }
}

export default connect(mapStateToProps, {
    fetchLessonTimes
})(LessonsTimes)
