import React from 'react'
import ReactDOM from 'react-dom'
import {unregister} from './registerServiceWorker';
import {applyMiddleware, createStore} from 'redux'
import {Provider} from 'react-redux'

import createHistory from 'history/createBrowserHistory'
import thunk from "redux-thunk";

import {Route} from 'react-router-dom'

import {ConnectedRouter, routerMiddleware} from 'react-router-redux'

import {Map} from "immutable";

import logger from "redux-logger";
//Css
import "bootstrap-css-only/css/bootstrap.css";
import "semantic-ui-css/semantic.css";
import 'react-toastify/dist/ReactToastify.css';

import "pretty-checkbox/dist/pretty-checkbox.css";
import 'react-pure-modal/dist/react-pure-modal.min.css';
import 'react-tabs/style/react-tabs.css';
import 'react-table/react-table.css'

import "./index.css";
// Components
import reducers from './reducers';
import Faculties from "./scenes/University/Faculties";

import Scholarships from "./scenes/Students/Scholarships";
import PreferentialCategories from "./scenes/Students/PreferentialCategories";
import StudentStatuses from "./scenes/Students/StudentStatuses";
import UserDocumentType from "./scenes/Users/UserDocumentType";
import UserType from "./scenes/Users/UserType";

import AdministrationPanel from "./scenes/AdministrationPanel";
import Departments from "./scenes/University/Departments";
import Audiences from "./scenes/University/Audiences";
import Specialities from "./scenes/University/Specialities";
import StudyYears from "./scenes/University/StudyYears";
import Flows from "./scenes/University/Flows";
import Weeks from "./scenes/University/Weeks";
import Weekends from "./scenes/University/Weekends";
import DisciplinesCycles from "./scenes/University/DisciplinesCycles";
import DisciplinesControls from "./scenes/University/DisciplinesControls";
import Disciplines from "./scenes/University/Disciplines";
import LessonsTimes from "./scenes/University/LessonsTimes";
import TeacherPositions from "./scenes/TeachersInfo/Positions";
import Rates from "./scenes/TeachersInfo/Rates";
import TeacherStatus from "./scenes/TeachersInfo/Status";
import TeacherRanks from "./scenes/TeachersInfo/Ranks";
import TeachersConsultations from "./scenes/TeachersInfo/TeachersConsultations";
import Teachers from "./scenes/TeachersInfo/Teachers";
import AppRoute from "./components/AppRoute";
import MainLayout from "./components/Layouts";
import Login from "./scenes/Login";
import HierarchyUniversityGroups from "./scenes/University/Groups";

import FreeAudiences from "./scenes/FreeAudiences";

import Users from "./scenes/Users";
import CustomLessons from "./scenes/CustomLessons";
import TeacherLoadColumns from "./scenes/Documents/Columns";


import Lessons from "./scenes/Lessons";
import UserLessons from "./scenes/Timetable/UserTimetable";

import ArchivedUserLessons from "./scenes/Timetable/Archive/UserTimetable";
import ArchivedFacultyTimetable from "./scenes/Timetable/Archive/FacultyTimetable";
import ArchivedDepartmentTimetable from "./scenes/Timetable/Archive/DepartmentTimetable";

import FacultyTimetable from "./scenes/Timetable/FacultyTimetable";
import DepartmentTimetable from "./scenes/Timetable/DepartmentTimetable";
import CalendarPlan from "./scenes/Documents/CalendarPlan";
import DepartmentsRequests from "./scenes/Documents/DepartmentsRequests";
import WorkPlanWithLoad from "./scenes/Documents/WorkPlanWithLoad";
import TeacherLoad from "./scenes/Documents/TeacherLoad";
import {ADMIN, DEPARTMENT, EDUCATIONAL_DEPARTMENT, FACULTY} from "./utils/roles";
import NotFound from "./components/NotFound";
import NotAuth from "./components/NotAuth";
import {ToastContainer} from "react-toastify";

// Or wherever you keep your reducers

// Create a history of your choosing (we're using a browser history in this case)
const history = createHistory();


// Build the middleware for intercepting and dispatching navigation actions
let middleware;
if (process.env.NODE_ENV === 'development') {
    middleware =
        applyMiddleware(routerMiddleware(history), thunk, logger);
} else {
    middleware =
        applyMiddleware(routerMiddleware(history), thunk);
}

const initialState = Map();

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const store = createStore(
    reducers,
    initialState,
    middleware
);


ReactDOM.render(
    <Provider store={store}>
        {/* ConnectedRouter will use the store from Provider automatically */}
        <ConnectedRouter history={history}>
            <div>
                <Route exact path="/" component={Login}/>

                <AppRoute allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/settings"
                          title={`Панель управління`} layout={MainLayout}
                          component={AdministrationPanel}/>

                <AppRoute allowed={[ADMIN, FACULTY, EDUCATIONAL_DEPARTMENT]}
                          path="/users"
                          title={`Користувачі`} layout={MainLayout}
                          component={Users}/>


                <AppRoute allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/university/weeks"
                          title={`Тижні`} layout={MainLayout}
                          component={Weeks}/>

                <AppRoute allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/university/audiences"
                          title={`Аудиторії`} layout={MainLayout}
                          component={Audiences}/>

                <AppRoute allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/university/faculties"
                          title={`Факультети`} layout={MainLayout}
                          component={Faculties}/>

                <AppRoute allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/university/departments"
                          title={`Кафедри`} layout={MainLayout}
                          component={Departments}/>

                <AppRoute allowed={[ADMIN, EDUCATIONAL_DEPARTMENT, DEPARTMENT]}
                          path="/university/specialities"
                          title={`Спеціальності`} layout={MainLayout}
                          component={Specialities}/>

                <AppRoute allowed={[ADMIN, DEPARTMENT, EDUCATIONAL_DEPARTMENT]}
                          path="/university/disciplines"
                          title={`Дисципліни`} layout={MainLayout}
                          component={Disciplines}/>

                <AppRoute allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/university/disciplines-controls"
                          title={`Види підсумкового контролю`} layout={MainLayout}
                          component={DisciplinesControls}/>
                <AppRoute allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/university/disciplines-cycles"
                          title={`Цикли дисциплін`} layout={MainLayout}
                          component={DisciplinesCycles}/>

                <AppRoute allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/university/study-years"
                          title={`Навчальні роки/Семестри`} layout={MainLayout}
                          component={StudyYears}/>

                <AppRoute allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/university/weekends"
                          title={`Вихідні дні`} layout={MainLayout}
                          component={Weekends}/>
                <AppRoute allowed={[ADMIN, DEPARTMENT, EDUCATIONAL_DEPARTMENT]}
                          path="/university/teachers"
                          title={`Викладачі`} layout={MainLayout}
                          component={Teachers}/>
                <AppRoute allowed={[ADMIN, DEPARTMENT, EDUCATIONAL_DEPARTMENT]}
                          path="/teachers/rates"
                          title={`Ставки викладачів кафедри: ${localStorage.getItem("OA.UM.Dekanat_userDepartment") || ""} за ${localStorage.getItem("OA.UM.Dekanat_studyYear") || ""}`}
                          layout={MainLayout}
                          component={Rates}/>
                <AppRoute allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/teachers/ranks"
                          title={`Звання викладачів`} layout={MainLayout}
                          component={TeacherRanks}/>
                <AppRoute allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/teachers/positions"
                          title={`Посади викладачів`} layout={MainLayout}
                          component={TeacherPositions}/>

                <AppRoute allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/teachers/statuses"
                          title={`Статуси викладачів на кафедрі`} layout={MainLayout}
                          component={TeacherStatus}/>
                <AppRoute allowed={[ADMIN, DEPARTMENT, EDUCATIONAL_DEPARTMENT]}
                          path="/teachers/consultations"
                          title={`Консультації викладачів`} layout={MainLayout}
                          component={TeachersConsultations}/>

                <AppRoute allowed={[ADMIN, DEPARTMENT, FACULTY, EDUCATIONAL_DEPARTMENT]}
                          path="/university/free-audiences"
                          title={`Вільні аудиторії`} layout={MainLayout}
                          component={FreeAudiences}/>


                <AppRoute exact allowed={[ADMIN, FACULTY, EDUCATIONAL_DEPARTMENT]}
                          path="/lessons"
                          title={`Створити розклад занять`}
                          layout={MainLayout}
                          showSettings
                          component={Lessons}/>
                <AppRoute exact allowed={[ADMIN, FACULTY, EDUCATIONAL_DEPARTMENT]}

                          path="/custom-lessons"
                          title={`Спеціальні дисципліни`}
                          layout={MainLayout}
                          component={CustomLessons}/>


                <AppRoute exact allowed={[ADMIN, FACULTY, DEPARTMENT, EDUCATIONAL_DEPARTMENT]}

                          path="/schedule" title={`Розклад дзвінків`}
                          layout={MainLayout}
                          component={LessonsTimes}/>


                <AppRoute exact allowed={[ADMIN, FACULTY, DEPARTMENT, EDUCATIONAL_DEPARTMENT]}
                          path="/user/lessons" title={`Розклад занять`} layout={MainLayout}
                          component={UserLessons}/>
                <AppRoute exact allowed={[ADMIN, FACULTY, DEPARTMENT, EDUCATIONAL_DEPARTMENT]}
                          path="/archive/user/lessons" title={`Архів розкладів занять`} layout={MainLayout}
                          component={ArchivedUserLessons}/>
                <AppRoute exact allowed={[ADMIN, DEPARTMENT, FACULTY, EDUCATIONAL_DEPARTMENT]}
                          path="/archive/department-timetable" title={`Архів розкладів кафедри`} layout={MainLayout}
                          component={ArchivedDepartmentTimetable}/>
                <AppRoute exact allowed={[ADMIN, DEPARTMENT, FACULTY, EDUCATIONAL_DEPARTMENT]}
                          path="/archive/faculty-timetable" title={`Архів розкладів факультету`} layout={MainLayout}
                          component={ArchivedFacultyTimetable}/>
                <AppRoute exact allowed={[ADMIN, DEPARTMENT, FACULTY, EDUCATIONAL_DEPARTMENT]}
                          path="/department-timetable" title={`Розклад кафедри`} layout={MainLayout}
                          component={DepartmentTimetable}/>
                <AppRoute exact allowed={[ADMIN, DEPARTMENT, FACULTY, EDUCATIONAL_DEPARTMENT]}
                          path="/faculty-timetable" title={`Розклад факультету`} layout={MainLayout}
                          component={FacultyTimetable}/>


                <AppRoute exact allowed={[ADMIN, FACULTY, EDUCATIONAL_DEPARTMENT]}
                          path="/university/groups" title={`Академічні групи`} layout={MainLayout}
                          component={HierarchyUniversityGroups}/>

                <AppRoute exact allowed={[ADMIN, DEPARTMENT, FACULTY, EDUCATIONAL_DEPARTMENT]}
                          path="/university/flows" title={`Потоки`} layout={MainLayout}
                          component={Flows}/>


                <AppRoute exact allowed={[ADMIN, DEPARTMENT, EDUCATIONAL_DEPARTMENT]}
                          path="/documents/calendar-plan"
                          title={`Графік освітнього процесу`} layout={MainLayout}
                          component={CalendarPlan}/>


                <AppRoute exact allowed={[ADMIN, DEPARTMENT, EDUCATIONAL_DEPARTMENT]}
                          path="/documents/department-request"
                          title={`Отримані заявки кафедри ${localStorage.getItem("OA.UM.Dekanat_userDepartment")}`}
                          layout={MainLayout}
                          component={DepartmentsRequests}/>

                <AppRoute exact allowed={[ADMIN, DEPARTMENT, EDUCATIONAL_DEPARTMENT]}
                          path="/documents/work-plan-load" title={`Робочий навчальний план+Навантаження`}
                          layout={MainLayout}
                          showSettings
                          component={WorkPlanWithLoad}/>

                <AppRoute exact allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/documents/teacher-load/columns" title={`Конструктор`} layout={MainLayout}
                          component={TeacherLoadColumns}/>
                <AppRoute exact allowed={[ADMIN, DEPARTMENT, EDUCATIONAL_DEPARTMENT]}
                          path="/documents/teacher-load"
                          title={`Навантаження`}
                          layout={MainLayout}
                          component={TeacherLoad}/>

                <AppRoute exact allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/students/scholarship"
                          title={`Види стипендій`}
                          layout={MainLayout}
                          component={Scholarships}/>

                <AppRoute exact allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/students/preferential-categories"
                          title={`Види пільг`}
                          layout={MainLayout}
                          component={PreferentialCategories}/>

                <AppRoute exact allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/students/statuses"
                          title={`Види статусів студентів`}
                          layout={MainLayout}
                          component={StudentStatuses}/>

                <AppRoute exact allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/user/document-types"
                          title={`Види документів користувача`}
                          layout={MainLayout}
                          component={UserDocumentType}/>

                <AppRoute exact allowed={[ADMIN, EDUCATIONAL_DEPARTMENT]}
                          path="/user/types"
                          title={`Типи користувачів`}
                          layout={MainLayout}
                          component={UserType}/>

                <Route path={"/not-auth"} component={NotAuth}/>
                <Route path={"/not-found"} component={NotFound}/>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
            </div>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);

unregister();
