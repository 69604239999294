import React, {PureComponent} from "react";
import {connectModal, hide, show} from 'redux-modal'
import PureModal from 'react-pure-modal';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {connect} from "react-redux";
import {compose} from "redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Input from "semantic-ui-react/dist/es/elements/Input/Input";
import {Accordion, AccordionItem} from "react-sanfona";
import {DAYS} from "../../../../../utils";
import {copyLessons, fetchCopyTemplate, fetchLessonsTeacherProposals, updateTemplateCell} from "../../../actions";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";

class CreateLessonTemplateModal extends PureComponent {
    state = {
        countWeek: undefined,
        days: DAYS,
        selectedWeek: 0
    };
    getTableRows = (rows) => {
        return rows.map((cells, i) => {
            return <Table.Row key={`row_${i}`}>
                <Table.Cell key={`cell_${i}`}>
                    {i}
                </Table.Cell>
                {this.getTableCells(cells)}
            </Table.Row>
        })
    };
    getCheckbox = (cell) => {
        return (
            <div className="pretty p-default m-0  p-curve p-fill" style={{fontSize: "16px"}}>
                <input
                    type="checkbox"
                    onChange={() => {
                        if (!cell.isDisable) {
                            this.props.updateTemplateCell({
                                week: this.state.selectedWeek,
                                id: this.props.lesson.id,
                                colIdx: cell.column,
                                rowIdx: cell.row
                            }, cell.column, cell.row)
                        }
                    }}
                    checked={cell.isChecked} disabled={cell.isDisable}
                />
                <div className="state p-info">
                    <label/>

                </div>
            </div>
        )
    };


    render() {
        const {lesson, show, hide, template} = this.props;
        let id, rows;
        if (template && template.size > 0) {
            id = template.get("id");
            rows = template.get("rows");
        }
        return (
            <PureModal
                header={"Шаблон копіювання розкладу занять"}
                footer={<div className={"float-right"}>
                    <Button color={"red"} icon onClick={() => hide("createLessonTemplateModal")}>
                        <Icon name='remove'/> Закрити
                    </Button>
                </div>}
                onClose={() => {
                    this.props.hide("createLessonTemplateModal");
                }}
                width={"80vw"}
                isOpen={show}
            >
                <div>
                    <div>
                        <Table celled textAlign={"center"} verticalAlign={"middle"}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Предмет</Table.HeaderCell>
                                    <Table.HeaderCell>Викладач</Table.HeaderCell>
                                    <Table.HeaderCell>Пари</Table.HeaderCell>
                                    <Table.HeaderCell> Рекомендована к-сть пар на тиждень</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        {lesson.discipline}
                                    </Table.Cell>

                                    <Table.Cell>
                                        {lesson.teacherFullName}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {lesson.number + lesson.maxNumber}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {this.props.recommendNumberLessons}
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                    <br/>
                    <div>
                        <Table celled compact={"very"} textAlign={"center"} verticalAlign={"middle"}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>К-сть тижнів </Table.HeaderCell>
                                    <Table.HeaderCell/>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        <Input className={"w-100"} type={"number"} min={0}
                                               placeholder='Введіть к-сть тижнів...'
                                               onChange={(e) => this.setState({countWeek: e.target.value})}
                                               value={this.state.countWeek}/>
                                    </Table.Cell>
                                    <Table.Cell className={"w-15"}>
                                        <Button primary icon onClick={() => {
                                            this.props.copyLessons({
                                                countWeeks: this.state.countWeek,
                                                id: lesson.id
                                            });
                                            this.props.hide("createLessonTemplateModal");
                                        }}>
                                            <Icon name={"copy"}/> Скопіювати
                                        </Button>
                                    </Table.Cell>

                                </Table.Row>


                            </Table.Body>
                        </Table>
                    </div>
                    <br/>
                    <div>
                        <Accordion
                            onChange={(data) => {
                                if (data.activeItems) {
                                    if (data.activeItems.length > 0) {
                                        const week = this.props.weeks.get(data.activeItems[0]).id;
                                        this.props.fetchCopyTemplate({
                                            id: this.props.lesson.id,
                                            week: week
                                        });
                                        this.setState({selectedWeek: week})
                                    }
                                }
                            }}>
                            {this.props.weeks.toArray().map((week) => {
                                return (
                                    <AccordionItem key={week.id} title={week.name}>
                                        {id === week.id ? <Table celled textAlign={"center"} verticalAlign={"middle"}>
                                            <Table.Header>
                                                <Table.Row>
                                                    {this.state.days.map((day, i) => {
                                                        return (<Table.HeaderCell key={`day_${i}`}>
                                                            {day}
                                                        </Table.HeaderCell>);
                                                    })}
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {this.getTableRows(rows)}
                                            </Table.Body>
                                        </Table> : <div/>}
                                    </AccordionItem>
                                );
                            })}
                        </Accordion>
                    </div>
                </div>

            </PureModal>
        );
    }

    getTableCells(cells) {
        return cells.map((cell, i) => {
            return <Table.Cell key={`cell_${i}`}>
                <div>
                    {cell.id ? <Popup trigger={this.getCheckbox(cell)}
                                      content={<em>{cell.groups} {cell.teacher}</em>}/>
                        : this.getCheckbox(cell)}

                    <Popup trigger={<Icon name={"info"} onClick={() => {
                        const id = this.props.lesson.teacherLoadId;
                        this.props.fetchLessonsTeacherProposals(id);
                        this.props.show("lesson_teacherProposalsModal");
                    }}/>
                    } content={"Переглянути пропозиції викладача"}/>


                </div>
            </Table.Cell>
        })
    }
}

function mapStateToProps(state) {
    return {
        weeks: state.get("lessons").get("weeksTemplate").get("weeks"),
        template: state.get("lessons").get("weeksTemplate").get("template"),
        recommendNumberLessons: state.get("lessons").get("recommendNumberLessons")
    }
}

export default compose(
    connectModal({
        name: 'createLessonTemplateModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        copyLessons,
        show,
        fetchCopyTemplate,
        fetchLessonsTeacherProposals,
        updateTemplateCell,
    }))(CreateLessonTemplateModal);