import axios from "axios";
import {
    DELETE_UNIVERSITY_FLOW,
    EDIT_UNIVERSITY_FLOW_INFO,
    FETCH_UNIVERSITY_FLOWS,
    INSERT_UNIVERSITY_FLOW,
} from "./types";
import {ROOT_URL} from "../../../utils";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchUniversityGroupsFlow() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/allFlows`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_UNIVERSITY_FLOWS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function editUniversityGroupsFlowInfo(flow,universityGroupsFlow) {
    const token = Cookie.get('OA.UM-Auth');
    console.log(universityGroupsFlow);
    const request = axios.put(`${ROOT_URL}/v1/university/universityGroupsFlows/edit`, flow, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_UNIVERSITY_FLOW_INFO, payload: universityGroupsFlow})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertUniversityGroupsFlow(universityFlow) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/university/universityGroupsFlow/create`, universityFlow, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_UNIVERSITY_FLOW, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteUniversityGroupsFlow(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/university/universityGroupsFlow/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_UNIVERSITY_FLOW, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


