import axios from "axios";
import {ROOT_URL} from "../../utils";
import {
    AUDIENCES_COINCIDENCES,
    COMP_COINCIDENCES,
    COPY_LESSONS,
    DELETE_LESSON,
    DELETE_LESSON_AUDIENCE,
    EDIT_LESSON,
    EDIT_LESSONS_TEACHER_LOADS,
    FETCH_COPY_TEMPLATE,
    FETCH_LESSON_NUMBER,
    FETCH_LESSON_TEACHER_JOURNAL,
    FETCH_LESSONS,
    FETCH_LESSONS_TEACHER_PROPOSALS,
    FETCH_ONLY_LESSONS,
    FETCH_WEEKS_TEMPLATE,
    HASPROJECTOR_COINCIDENCES,
    INSERT_LESSON,
    INSERT_LESSON_AUDIENCE,
    INSERT_LESSON_TEACHER_LOADS,
    REMOVE_LESSON,
    TOGGLE_EXTRA_LESSON,
    TOGGLE_EXTRA_LESSON_TEACHER_LOADS,
    UPDATE_FILTER_VALUE,
    UPDATE_TEMPLATE_CELL,
    VALIDATE_AUDIENCES_COINCIDENCES,
    VALIDATE_LESSONS
} from "./types";

import Cookie from "js-cookie";
import {writeErrors} from "../../utils/logs";
import {toast} from "react-toastify";


export function fetchLessons(filter) {
    createLessonObject(filter);

    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/timetable/lessons`, {
        params: filter,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_LESSONS, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchOnlyLessons() {
    const lesson = {};
    createLessonObject(lesson);
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/timetable/onlyLessons`, {
        params: lesson,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_ONLY_LESSONS, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function copyLessons(lesson) {
    createLessonObject(lesson);

    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/timetable/copy-lessons`, {
        params: lesson,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: COPY_LESSONS, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchWeeksTemplate(filter) {
    createLessonObject(filter);
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/timetable/week-template`, {
        params: filter,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_WEEKS_TEMPLATE, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchCopyTemplate(filter) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/timetable/weeks-template`, {
        params: filter,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_COPY_TEMPLATE, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchLessonsTeacherProposals(id) {
    const filter = {
        week: localStorage.getItem('OA.UM-week') !== null && localStorage.getItem('OA.UM-week').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-week')).value : "",
        id
    };
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/timetable/teacher-proposals`, {
        params: filter,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_LESSONS_TEACHER_PROPOSALS, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchTeacherJournal(lessonId) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/timetable/teacher-journal`, {
        params: {id: lessonId},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_LESSON_TEACHER_JOURNAL, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchLessonNumbers(filter) {
    filter.week = localStorage.getItem('OA.UM-week') !== null && localStorage.getItem('OA.UM-week').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-week')).value : "";
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/timetable/numberLesson`, {
        params: filter,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_LESSON_NUMBER, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function insertLesson(lesson) {
    createLessonObject(lesson);
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/timetable/lessons/create`, lesson, {headers: {"Authorization": `Bearer ${token}`}});


    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_LESSON, payload: data.lessons});
            dispatch({type: INSERT_LESSON_TEACHER_LOADS, payload: lesson.id})
            if (data.countLessons) {
                toast.info(data.countLessons, {
                    position: "top-center",
                    autoClose: 5500,
                });
            }
        }).catch((error) => {
            writeErrors(error);
        });
    }
}

export function insertExtraLesson(lesson, teacherLoad) {
    createLessonObject(lesson);
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/timetable/lessons/insertExtraLesson`, lesson, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: TOGGLE_EXTRA_LESSON, payload: lesson.id});
            dispatch({type: TOGGLE_EXTRA_LESSON_TEACHER_LOADS, payload: teacherLoad});
        }).catch((error) => {
            writeErrors(error);
        });
    }
}

export function editLesson(lesson) {
    createLessonObject(lesson);
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/timetable/lessons/edit`, lesson, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_LESSON, payload: data.lessons})
            if (data.massage) {
                toast.warn(data.massage, {
                    position: "top-center",
                    autoClose: 5500,
                });
            }
        }).catch((error) => {
            writeErrors(error);
        });
    }
}

export function editLessonInfo(lesson) {
    createLessonObject(lesson);
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/timetable/lesson/edit`, lesson, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_LESSON, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }
}

export function insertLessonAudience(lesson) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/timetable/lessons/insertAudience`, lesson, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: INSERT_LESSON_AUDIENCE, payload: lesson})
        }).catch((error) => {
            writeErrors(error);
        });
    }
}

export function deleteAudience(lesson) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/timetable/lessons/deleteAudience`, lesson, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_LESSON_AUDIENCE, payload: lesson.id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function validateLessons(lesson) {
    createLessonObject(lesson);
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/timetable/lessons/validate`, {
        params: lesson,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: VALIDATE_LESSONS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function validateAudiences(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/timetable/audiencesCoincidences`, {
        params: {id},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: VALIDATE_AUDIENCES_COINCIDENCES, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function audienceCoincidences(filter) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/timetable/audience-coincidences`, {
        params: filter,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: AUDIENCES_COINCIDENCES, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function projectorCoincidences(filter) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/timetable/projector-coincidences`, {
        params: filter,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: HASPROJECTOR_COINCIDENCES, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function compCoincidences(filter) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/timetable/comp-coincidences`, {
        params: filter,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: COMP_COINCIDENCES, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteLesson(lesson, teacherLoadId) {
    createLessonObject(lesson);
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/timetable/lessons/delete`, {
        params: lesson,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: DELETE_LESSON, payload: data.lessons});
            dispatch({type: EDIT_LESSONS_TEACHER_LOADS, payload: teacherLoadId})
            if (data.countLessons) {
                toast.info(data.countLessons, {
                    position: "top-center",
                    autoClose: 5500,
                });
            }
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function removeLesson(filter) {
    createLessonObject(filter);
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/timetable/lesson/remove`, {
        params: filter,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: REMOVE_LESSON, payload: filter.id});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function updateTemplateCell(filter, column, row) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/timetable/copy-lesson`, filter, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {

            dispatch({type: UPDATE_TEMPLATE_CELL, payload: data, column, row});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function getLessonsCount(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/timetable/lessonNumber`, {
        params: {id},
        headers: {"Authorization": `Bearer ${token}`}
    });
    request.then(({data}) => {
        if (data) {
            toast.info(data, {
                position: "top-center",
                autoClose: 4000,
            });
        }
    }).catch((error) => {
        writeErrors(error);
    });

}

export function updateFilters(filterName, value) {
    return (dispatch) => {
        dispatch({type: UPDATE_FILTER_VALUE, payload: value, filterName});
    }

}

export function createLessonObject(lesson) {
    lesson.validations = localStorage.getItem('OA.UM-user_validations') === 'true';
    lesson.groups = localStorage.getItem('OA.UM-groups') !== null && localStorage.getItem('OA.UM-groups').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-groups')).map(i => i.value).join(",") : '';
    lesson.week = localStorage.getItem('OA.UM-week') !== null && localStorage.getItem('OA.UM-week').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-week')).value : "";
    lesson.teacher = localStorage.getItem('OA.UM-teacher') !== null && localStorage.getItem('OA.UM-teacher').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-teacher')).value : "";
    lesson.discipline = localStorage.getItem('OA.UM-discipline') && localStorage.getItem('OA.UM-discipline').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-discipline')).value : "";
    lesson.semesters = localStorage.getItem('OA.UM-semesters') !== null && localStorage.getItem('OA.UM-semesters').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-semesters')).map(i => i.value).join(",") : [];
}

