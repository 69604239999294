import {combineReducers} from 'redux-immutable';
import {routerReducer} from "react-router-redux";
import {reducer as modal} from 'redux-modal';
import data4Select from "./components/Data4Select/reducer";

import audiences from "./scenes/University/Audiences/reducer";
import roles from "./scenes/Roles/reducer";
import customLessons from "./scenes/CustomLessons/reducer";
import users from "./scenes/Users/reducer";
import departments from "./scenes/University/Departments/reducer";
import disciplinesControls from "./scenes/University/DisciplinesControls/reducer";
import disciplinesCycles from "./scenes/University/DisciplinesCycles/reducer";
import disciplines from "./scenes/University/Disciplines/reducer";
import faculties from "./scenes/University/Faculties/reducer";
import specialities from "./scenes/University/Specialities/reducer";
import flows from "./scenes/University/Flows/reducer";
import groups from "./scenes/University/Groups/reducer";
import students from "./scenes/University/Students/reducer";
import studyYears from "./scenes/University/StudyYears/reducer";
import lessonTypes from "./scenes/University/LessonTypes/reducer";
import weeks from "./scenes/University/Weeks/reducer";
import weekends from "./scenes/University/Weekends/reducer";
import lessonsTimes from "./scenes/University/LessonsTimes/reducer";
import studyPlan from "./scenes/Documents/StudyPlan/reducer";
import teacherLoad from "./scenes/Documents/TeacherLoad/reducer";
import teacherLoadColumns from "./scenes/Documents/Columns/reducer";
import workPlanWithLoad from "./scenes/Documents/WorkPlanWithLoad/reducer";
import calendarPlan from "./scenes/Documents/CalendarPlan/reducer";
import teachers from "./scenes/TeachersInfo/Teachers/reducer";
import consultations from "./scenes/TeachersInfo/TeachersConsultations/reducer";
import positions from "./scenes/TeachersInfo/Positions/reducer";
import ranks from "./scenes/TeachersInfo/Ranks/reducer";
import teachersStatus from "./scenes/TeachersInfo/Status/reducer";
import teachersRates from "./scenes/TeachersInfo/Rates/reducer";
import auth from "./scenes/Login/reducer";

import timetable from "./scenes/Timetable/reducer";
import lessons from "./scenes/Lessons/reducer";


import scholarships from "./scenes/Students/Scholarships/reducer";
import preferentialCategories from "./scenes/Students/PreferentialCategories/reducer";
import studentStatuses from "./scenes/Students/StudentStatuses/reducer";
import userDocumentTypes from "./scenes/Users/UserDocumentType/reducer";
import userTypes from "./scenes/Users/UserType/reducer";

import options from "./scenes/AdministrationPanel/components/Setting/reducer";
import backups from "./scenes/AdministrationPanel/components/Backups/reducer";
import logs from "./scenes/AdministrationPanel/components/Logging/reducer";

import notifications from './components/Layouts/components/AppMenu/components/NotificationCenter/reducer';
import departmentsRequests from "./scenes/Documents/DepartmentsRequests/reducer";

const reducers = combineReducers({
    modal,
    router: routerReducer,
    auth,
    options,
    backups,
    logs,


    timetable,
    lessons,
    customLessons,

    //university
    audiences,
    departments,
    faculties,
    specialities,
    studyYears,
    lessonTypes,
    flows,
    groups,
    weeks,
    weekends,
    lessonsTimes,

    //Disciplines
    disciplines,
    disciplinesControls,
    disciplinesCycles,
    calendarPlan,
    departmentsRequests,

    // students
    students,
    scholarships,
    preferentialCategories,
    studentStatuses,

    // users
    users,
    roles,
    userDocumentTypes,
    userTypes,


    //documents
    studyPlan,
    teacherLoad,
    teacherLoadColumns,
    workPlanWithLoad,

    //teachers
    teachers,
    consultations,
    ranks,
    positions,
    teachersRates,
    teachersStatus,

    notifications,

    data4Select,

});

export default reducers;
