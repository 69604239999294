import React, {PureComponent} from 'react';
import Footer from "./components/Footer";
import AppHeader from "./components/AppHeader";
import AppMenu from "./components/AppMenu";
import Sidebar from "./components/Sidebar";
import "./styles.css";
import PropTypes from "prop-types";
import Cookie from "js-cookie";
import LessonsSettings from "../../scenes/Lessons/components/LessonsSettings";
import StudyWorkPlanWithLoadsSettings
    from "../../scenes/Documents/WorkPlanWithLoad/components/StudyWorkPlanWithLoadsSettings";
import UserDepartmentModal from "./components/AppMenu/components/UserDepartmentModal";

class MainLayout extends PureComponent {
    static defaultProps = {
        showSettings: false
    };
    static propTypes = {
        showSettings: PropTypes.bool
    };

    constructor(props) {
        super(props);
        let showSettings = false;
        if (props.path === "/documents/work-plan-load") {
            showSettings = localStorage.getItem("OA.UM.Dekanat_work-plan-load_sidebar") !== null ? localStorage.getItem("OA.UM.Dekanat_work-plan-load_sidebar") === 'true' : true;
        } else if (props.path === "/lessons") {
            showSettings = localStorage.getItem("OA.UM.Dekanat_lessons_sidebar") !== null ? localStorage.getItem("OA.UM.Dekanat_lessons_sidebar") === 'true' : true;
        }
        this.state = {
            menuVisible: localStorage.getItem("OA.UM.Dekanat_menu") === null ? true : localStorage.getItem("OA.UM.Dekanat_menu") === 'true',
            showSettings: showSettings,
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.logOut = this.logOut.bind(this);
        this.toggleSettings = this.toggleSettings.bind(this);
    }

    logOut() {
        Cookie.remove('OA.UM-Auth');
        Cookie.remove('OA.UM-Auth_data');
        this.props.history.push("/");
    };

    toggleMenu() {
        const {menuVisible} = this.state;
        this.setState({menuVisible: !menuVisible});
    };

    toggleSettings() {
        const {showSettings} = this.state;
        this.setState({showSettings: !showSettings});

    };

    render() {
        const {menuVisible, showSettings} = this.state;
        localStorage.setItem("OA.UM.Dekanat_menu", (menuVisible).toString());
        if (this.props.path === "/documents/work-plan-load") {
            localStorage.setItem("OA.UM.Dekanat_work-plan-load_sidebar", (showSettings).toString());
        } else if (this.props.path === "/lessons") {
            localStorage.setItem("OA.UM.Dekanat_lessons_sidebar", (showSettings).toString());
        }

        return (
            <div className={"body-container white-bg"}>

                <nav className={`d-print-none sidebar ${menuVisible ? "d-initial" : "d-none"}`}>
                    <Sidebar {...this.props}/>
                </nav>
                <div className="layout-column">
                    <UserDepartmentModal/>
                    <AppMenu className={"d-print-none"}
                             {...this.props}
                             showSidebar={this.toggleMenu}
                             toggleSettings={this.toggleSettings}
                             logOut={this.logOut}
                             showSettings={this.props.showSettings}/>
                    <header className={"d-print-none"}>
                        <AppHeader {...this.props}/>
                    </header>
                    <main className="gray-bg">
                        <article>
                            {this.props.children}
                        </article>
                        <aside
                            className={`p-0 d-print-none ${this.state.showSettings ? "" : "d-none"}`}>
                            <div className="text-center mb-3 font-weight-bold h4">
                                Налаштування
                            </div>
                            {
                                this.props.path === '/lessons'
                                && this.props.showSettings
                                && <LessonsSettings/>
                            }
                            {
                                this.props.path === '/documents/work-plan-load'
                                && this.props.showSettings
                                && <StudyWorkPlanWithLoadsSettings
                                />
                            }

                        </aside>
                    </main>
                    <footer className={"d-print-none"}>
                        <Footer/>
                    </footer>
                </div>
            </div>

        );
    }

}


export default MainLayout;

