import React, {PureComponent} from "react";
import {connect} from "react-redux";
import DatePicker from 'react-date-picker';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {writeErrors} from "../../../../utils/logs";
import {loadingText, nextText, noDataText, ofText, pageText, previousText, rowsText} from "../../../../utils/locale";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import {fetchLogs} from "../../actions";


class Logging extends PureComponent {
    state = {
        date: new Date()
    };

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    render() {

        return (
            <div>
                <div>
                    <Table compact={"very"}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell textAlign='center'>Дата</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell textAlign='center'>
                                    <DatePicker
                                        onChange={(value) => {
                                            this.props.fetchLogs(value);
                                            this.setState({date: value})
                                        }}
                                        locale={"uk-UA"}
                                        value={this.state.date}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>

                </div>
                <div>
                    <ReactTable
                        data={this.props.rows.toArray()}
                        filterable
                        columns={[

                            {
                                Header: "Користувач",
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["user"]}),
                                filterAll: true,
                                accessor: "user",
                            }, {
                                Header: "Дата",
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["date"]}),
                                filterAll: true,
                                accessor: "date",
                            },
                            {
                                Header: "Подія",
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["text"]}),
                                filterAll: true,
                                accessor: "text",

                            }
                        ]}
                        defaultPageSize={10}
                        style={{
                            height: "65vh",
                        }}
                        className="table-bordered table-striped white-bg"
                        noDataText={noDataText}
                        previousText={previousText}
                        nextText={nextText}
                        loadingText={loadingText}
                        pageText={pageText}
                        ofText={ofText}
                        rowsText={rowsText}
                    />
                </div>
            </div>);


    }
}


function mapStateToProps(state) {
    return {
        rows: state.get("logs")
    }
}

export default connect(mapStateToProps,{
    fetchLogs
})(Logging)