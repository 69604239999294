import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import DatePicker from 'react-date-picker'
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editWeekInfo} from "../../actions";
import {getDefaultValue, getOptions, NoOptionsMessage} from "../../../../../utils";
import Select from "react-select";

class EditWeekModal extends PureComponent {

    handleSave = () => {
        this.props.editWeekInfo({
            id: this.state.id,
            startValue: this.state.start,
            endValue: this.state.end,
            isApproved: this.state.isTimetableApproved.value
        });
        this.props.hide("editWeekModal");
    };

    constructor(props) {
        super(props);
        const row = props.row._original;
        this.state = {
            id: row.id,
            start: row.start ? new Date(row.start) : new Date(),
            end: row.end ? new Date(row.end) : new Date(),
            isTimetableApproved: row.isTimetableApproved,
            isApproved: row.isTimetableApproved.value === "Так",

        }
    }

    render() {
        const {show} = this.props;
        const options = getOptions();
        return (
            <PureModal
                header={"Редагування інформації про навчальний тиждень"}
                width={"95vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() => this.props.hide("editWeekModal")}>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
                onClose={() => {
                    this.props.hide("weekModal");
                }}
            >
                <Table celled compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Початок тижня</Table.HeaderCell>
                            <Table.HeaderCell>Кінець тижня</Table.HeaderCell>
                            <Table.HeaderCell>Затверджений розклад</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) => this.setState({start: value})}
                                    locale={"uk-UA"}
                                    value={this.state.start}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) => this.setState({end: value})}
                                    locale={"uk-UA"}
                                    value={this.state.end}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={options}
                                    onChange={(selected) => {
                                        this.setState({
                                            isTimetableApproved: selected || getDefaultValue()
                                        })
                                    }}
                                    isDisabled={this.state.isApproved}
                                    defaultValue={this.state.isTimetableApproved}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Затвердити розклад на тижень"}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}


export default compose(
    connectModal({
        name: 'editWeekModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(null, {
        hide,
        editWeekInfo
    }))(EditWeekModal);
