import React, {Component} from "react";
import {connect} from "react-redux";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import matchSorter from 'match-sorter'
import {show} from 'redux-modal'
import {fetchDepartments4Select, fetchDisciplinesCycles4Select} from "../../../components/Data4Select/actions";
import {
    copyStudyWorkPlanRow,
    copyTeacherLoadRow,
    deleteStudyWorkPlanItem,
    deleteTeacherLoadItem,
    fetchStudyWorkPlansWithTeacherLoads,
    fetchStudyWorkPlansWithTeacherLoadsBySemester,
    insertStudyWorkPlanItem,
    insertTeacherLoadItem,
    toggleRequestStatus,
} from "./actions";
import {fetchLessonTypes} from "../../University/LessonTypes/actions";
import ReactTable from "react-table";
import WorkPlanModal from "./components/WorkPlanModal";
import TeacherLoadsModal from "./components/TeacherLoadsModal";
import {writeErrors} from "../../../utils/logs";
import {loadingText, nextText, noDataText, ofText, pageText, previousText, rowsText} from "../../../utils/locale";

class WorkPlanWithLoad extends Component {
    state = {
        expanded: {}
    };
    getIconColor = (row) => {
        switch (row.requestStatus) {
            case 1:
                return "grey";
            case 2:
                return "orange";
            case 3:
                return "green";
            case 4:
                return "red";
            default:
                return "black";
        }
    };
    getPopupContent = (row) => {
        switch (row.requestStatus) {
            case 1:
                return `Надіслати заявку`;
            case 2:
                return `Заявку надіслано`;
            case 3:
                return `Заявку   прийнято`;
            case 4:
                return `Заявку  скасовано`;
            default:
                return "black";
        }
    };
    handleRowExpanded = (newExpanded, index) => {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    };
    getSubComponent = (studyPlanItem) => {
        return (
            <table className={"table-bordered table-striped table text-center"}>
                <thead>
                <tr>
                    <th className={"text-center"}>
                        <Popup
                            trigger={<div>Год.</div>}
                            content="Кількість годин"
                            hideOnScroll
                            on='hover'
                        />
                    </th>
                    <th className={"text-center"}>
                        <Popup
                            trigger={<div>Тип</div>}
                            content="Тип дисципліни"
                            hideOnScroll
                            on='hover'
                        />
                    </th>
                    <th className={"text-center"}>
                        <Popup
                            trigger={<div>Семестр</div>}
                            content="Наскрізний семестр"
                            hideOnScroll
                            on='hover'
                        />
                    </th>
                    <th className={"text-center"}>
                        <Popup
                            trigger={<div>Викл.</div>}
                            content="Викладач"
                            hideOnScroll
                            on='hover'
                        />
                    </th>
                    <th className={"text-center"}>
                        <Popup
                            trigger={<div>Група</div>}
                            content="Група/Потік"
                            hideOnScroll
                            on='hover'
                        />
                    </th>
                    <th className={"text-center"}>
                        <Popup
                            trigger={<div>Заміна</div>}
                            content="Заміна"
                            hideOnScroll
                            on='hover'
                        />
                    </th>
                    <th className={"text-center"}>
                        <Popup
                            trigger={<div>Заявка на кафедру</div>}
                            content="Заявка на кафедру"
                            hideOnScroll
                            on='hover'
                        />
                    </th>
                    <th className={"text-center"}>
                        <div>
                            <Popup
                                trigger={
                                    <Icon name='add' size={"big"}
                                          color={"green"}
                                          onClick={
                                              this.handleAddTeacherLoad.bind(this, studyPlanItem.id)}/>
                                }
                                content='Додати запис в навантаження викладача'
                                on={'hover'}
                                style={{zIndex: 3001}}
                            />
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {this.getTableRows(studyPlanItem)}
                </tbody>
            </table>
        )
    };

    getTableRows = (studyPlanItem) => {
        let style = {};

        return studyPlanItem.loads.map(row => {

            if (row.requestStatus === 4) {
                style.backgroundColor = "#DCDCDC";
            } else {
                style = {}
            }
            return (<tr key={row.id} style={style}>
                <td>{row.numberHours}</td>
                <td>{row.disciplineType}</td>
                <td>{row.semester}</td>
                <td>{row.teacher}</td>
                <td>{row.groups}</td>
                <td>{row.teacherReplacement}</td>
                <td>{row.department}</td>
                <td>
                    <div>
                        <Popup
                            trigger={<Icon name={"copy"}
                                           size={"big"}
                                           color={"green"}
                                           onClick={this.handleCopyTeacherLoadRow.bind(this, row.id)}
                            />}
                            content="Скоріювати запис навантаження"
                            hideOnScroll
                            on='hover'
                        />
                        <Popup
                            trigger={<Icon name={"edit"}
                                           size={"big"}
                                           color={"blue"}
                                           onClick={this.handleEditTeacherLoad.bind(this, row)}
                            />}
                            content="Редагувати інформацію"
                            hideOnScroll
                            on='hover'
                        />
                        <Popup
                            trigger={<Icon name={"trash"}
                                           size={"big"}
                                           color={"red"}
                                           onClick={this.handleDeleteLoad.bind(this, row.id, studyPlanItem)}
                            />}
                            content="Видалити запис робочого навчального плану"
                            hideOnScroll
                            on='hover' h
                        />
                        <Popup
                            trigger={<Icon name={`send`}
                                           size={"big"}
                                           color={this.getIconColor(row)}
                                           onClick={this.sendRequest.bind(this, row.id)}
                            />}
                            content={this.getPopupContent(row)}
                            hideOnScroll
                            on='hover'
                        />
                    </div>
                </td>
            </tr>)
        })
    };

    componentDidMount() {
        const semester = localStorage.getItem("OA.UM-StudyWotkPlanWithLoad_semesters") && localStorage.getItem("OA.UM-StudyWotkPlanWithLoad_semesters").indexOf("{") > -1 ? JSON.parse(localStorage.getItem("OA.UM-StudyWotkPlanWithLoad_semesters")) : null;
        this.props.fetchLessonTypes();
        this.props.fetchDepartments4Select();
        this.props.fetchDisciplinesCycles4Select();
        semester ? this.props.fetchStudyWorkPlansWithTeacherLoadsBySemester(semester ? semester.value : 0) : this.props.fetchStudyWorkPlansWithTeacherLoads();
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    handleAddTeacherLoad(id) {
        this.props.show("teacherLoadsModal", {row: {id}, isCreate: true});
    }

    handleAddStudyWorkPlan() {
        this.props.show("workPlanModal", {row: {id: ''}});
    }

    handleDeleteWorkPlan(id) {
        this.props.deleteStudyWorkPlanItem(id);
    }

    handleEditStudyWorkPlan(row) {
        this.props.show("workPlanModal", {row});
    }

    handleEditTeacherLoad(row) {
        this.props.show("teacherLoadsModal", {row});
    }

    handleCopyTeacherLoadRow(id) {
        this.props.copyTeacherLoadRow(id);
    }

    handleCopyStudyWorkPlanRow(id) {
        this.props.copyStudyWorkPlanRow(id);
    }

    handleDeleteLoad(id, row) {
        this.props.deleteTeacherLoadItem(id, row.id);
    }

    sendRequest(id) {
        this.props.toggleRequestStatus(id);
    }

    render() {
        return (
            <div>
                <WorkPlanModal/>
                <TeacherLoadsModal/>
                <ReactTable
                    data={this.props.workPlanWithLoad.toArray()}
                    filterable
                    expanded={this.state.expanded}
                    onExpandedChange={(newExpanded, index, event) => this.handleRowExpanded(newExpanded, index, event)}

                    columns={[
                        {
                            expander: true,
                            width: 50,
                            Expander: ({isExpanded}) => {
                                return (
                                    <div>
                                        <Popup
                                            trigger={<Icon name={`chevron ${isExpanded ? "down" : "up"}`}
                                                           size={"small"}
                                                           bordered circular
                                            />}
                                            content="Переглянути навантаження"
                                            hideOnScroll
                                            on='hover'
                                        />
                                    </div>

                                )
                            },
                            sortable: false,
                            style: {
                                cursor: "pointer",
                                fontSize: 25,
                                padding: "0",
                                textAlign: "center",
                                userSelect: "none"
                            }

                        }, {
                            Header: <Popup
                                trigger={<div>Дисципліна</div>}
                                content="Назва навчальної дисципліни"
                                hideOnScroll
                                on='hover'
                                style={{zIndex: 3001}}
                            />,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["discipline"]}),
                            filterAll: true,
                            accessor: "discipline",
                        },
                        {
                            Header: <Popup
                                trigger={<div>Л.</div>}
                                content="Годин лекцій"
                                hideOnScroll
                                on='hover'
                                style={{zIndex: 3001}}
                            />,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["hoursLectures"]}),
                            filterAll: true,
                            accessor: "hoursLectures",
                        }, {
                            Header: <Popup
                                trigger={<div>Пр.</div>}
                                content="Годин практичних занять"
                                hideOnScroll
                                on='hover'
                                style={{zIndex: 3001}}
                            />,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["hoursPractical"]}),
                            filterAll: true,
                            accessor: "hoursPractical",
                        }, {
                            Header: <Popup
                                trigger={<div>С.р.</div>}
                                content="Годин самостійної роботи"
                                hideOnScroll
                                style={{zIndex: 3001}}
                                on='hover'
                            />,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["hoursIndividualWork"]}),
                            filterAll: true,
                            accessor: "hoursIndividualWork",
                        }, {
                            Header: <Popup
                                trigger={<div>Конс.</div>}
                                content="Годин консультацій"
                                hideOnScroll
                                style={{zIndex: 3001}}
                                on='hover'
                            />,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["consultation"]}),
                            filterAll: true,
                            accessor: "consultation",
                        }, {
                            Header: <Popup
                                trigger={<div>Цикл.</div>}
                                content="Цикл дисциплін"
                                hideOnScroll
                                style={{zIndex: 3001}}
                                on='hover'
                            />,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["disciplineCycle"]}),
                            filterAll: true,
                            accessor: "disciplineCycle"
                        }, {
                            Header: <Popup
                                trigger={<div>Кредити</div>}
                                content="Кількість кредитів"
                                hideOnScroll
                                on='hover'
                                style={{zIndex: 3001}}
                            />,
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["numberCredits"]}),
                            filterAll: true,
                            accessor: "numberCredits",

                        },
                        {

                            Header: <div>
                                <Popup
                                    trigger={
                                        <Icon name='add' size={"big"}
                                              color={"green"}
                                              onClick={
                                                  this.handleAddStudyWorkPlan.bind(this)}/>
                                    }
                                    content='Додати запис в робочий план'
                                    on={'hover'}
                                    style={{zIndex: 3001}}
                                />
                            </div>,
                            accessor: "id",
                            width: 150,
                            Cell: ({row}) => {
                                return (
                                    <div style={{marginTop: "15px"}}>
                                        <Popup
                                            trigger={<Icon name={"copy"}
                                                           color={"green"}
                                                           onClick={this.handleCopyStudyWorkPlanRow.bind(this, row.id)}
                                            />}
                                            content="Скоріювати запис робочого навчального плану та навантаження"
                                            hideOnScroll
                                            on='hover'
                                        />
                                        <Popup
                                            trigger={<Icon name={"edit"}
                                                           color={"blue"}
                                                           onClick={this.handleEditStudyWorkPlan.bind(this, row)}
                                            />}
                                            content="Редагувати інформацію"
                                            hideOnScroll
                                            on='hover'
                                        />
                                        <Popup
                                            trigger={<Icon name={"trash"}
                                                           color={"red"}
                                                           onClick={this.handleDeleteWorkPlan.bind(this, row._original.id)}
                                            />}
                                            content="Видалити запис робочого навчального плану"
                                            hideOnScroll
                                            on='hover'
                                        />
                                    </div>)
                            },
                            sortable: false,
                            filterable: false,
                            style: {
                                cursor: "pointer",
                                fontSize: 25,
                                padding: "0",
                                textAlign: "center",
                                userSelect: "none"
                            }

                        },
                    ]}
                    defaultPageSize={10}
                    SubComponent={({row}) => {
                        const studyPlanItem = row._original;
                        return this.getSubComponent(studyPlanItem);
                    }}
                    style={{
                        height: "80vh",
                    }}
                    className="table-bordered table-striped white-bg text-center"
                    noDataText={noDataText}
                    previousText={previousText}
                    nextText={nextText}
                    loadingText={loadingText}
                    pageText={pageText}
                    ofText={ofText}
                    rowsText={rowsText}
                />
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        workPlanWithLoad: state.get("workPlanWithLoad"),
    }
}

export default connect(mapStateToProps, {
    fetchDepartments4Select,
    fetchDisciplinesCycles4Select,
    fetchStudyWorkPlansWithTeacherLoads,
    fetchLessonTypes,
    deleteStudyWorkPlanItem,
    copyTeacherLoadRow,
    copyStudyWorkPlanRow,
    insertTeacherLoadItem,
    insertStudyWorkPlanItem,
    deleteTeacherLoadItem,
    show,
    toggleRequestStatus,
    fetchStudyWorkPlansWithTeacherLoadsBySemester,
})(WorkPlanWithLoad)
