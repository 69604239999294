import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import DatePicker from "react-date-picker";
import TimeInput from 'react-time-input';
import {editTeacherConsultationInfo, insertTeacherConsultation} from "../../actions";
import {getDefaultOption, loadingMessage, NoOptionsMessage} from "../../../../../utils";
import Select from "react-select";
import AsyncSelect from 'react-select/lib/Async';
import {fetchTeachers4Select} from "../../../../../components/Data4Select/actions";

class TeachersConsultationsModal extends PureComponent {
    handleSave = () => {
        const object = {
            id: this.state.id,
            teacherId: this.state.teacher.value,
            startTime: this.state.startTeacherConsultation,
            endTime: this.state.endTeacherConsultation,
            dayValue: this.state.dayTeacherConsultation,
            audienceId: this.state.audienceId?this.state.audienceId.value:''
        };
        if (this.state.isCreate) {
            this.props.insertTeacherConsultation(object);
        } else {
            this.props.editTeacherConsultationInfo(object);
        }
        this.props.hide("teachersConsultationsModal");
    };
    loadOptions = inputValue => fetchTeachers4Select(inputValue);

    constructor(props) {
        super(props);
        let row = {};
        if (props.isCreate) {
            row = {};
        } else {
            row = props.row._original;
        }
        console.log(row, "row");
        this.state = {
            id: row.id || "",
            dayTeacherConsultation: row.day ? new Date(row.day) : new Date(),
            startTeacherConsultation: row.startTime || '',
            endTeacherConsultation: row.endTime || '',
            teacher: row.teacherId ? {
                value: row.teacherId,
                label: row.teacher
            } : null,
            isCreate: props.isCreate,
            allowSave: !props.isCreate,
            audience: row.audience,
            audienceId: row.audienceId ? {
                value: row.audienceId,
                label: row.audience
            } : null,
        }
    }

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Створення та редагування інформації про консультацію викладача"}
                width={"90vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("teachersConsultationsModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button disabled={!this.state.allowSave} primary
                                onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                onClose={() => this.props.hide("teachersConsultationsModal")}
                isOpen={show}
            >
                <Table celled compact>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell className={"w-30"}>Викладач</Table.Cell>
                            <Table.Cell>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={this.loadOptions}
                                    onChange={(selectedValue) => {
                                        if (selectedValue !== null) {
                                            const id = selectedValue.value;
                                            this.setState({
                                                allowSave: this.props.consultations.filter(i => i.teacherId === id).size < 1,
                                                teacher: selectedValue
                                            })
                                        } else {
                                            this.setState({
                                                allowSave: false,
                                                teacher: null
                                            })
                                        }
                                    }}
                                    isDisabled={!this.state.isCreate}
                                    defaultValue={this.state.teacher}
                                    noOptionsMessage={NoOptionsMessage}
                                    loadingMessage={loadingMessage}
                                    isClearable={false}
                                    placeholder={"Вкажіть викладача"}
                                />
                                {!this.state.allowSave && this.state.teacher &&
                                <div className={"text-center"}>
                                    <label className="text-danger">
                                        Консультацію викладачу вже зазначено
                                    </label></div>}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Аудиторія</Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={this.props.audiences.toArray()}
                                    onChange={(selectedValue) => {
                                        this.setState({
                                            audienceId: selectedValue || getDefaultOption(),
                                            audience: selectedValue.label || null
                                        })
                                    }}
                                    defaultValue={this.state.audienceId}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Вкажіть аудиторію"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>День тижня</Table.Cell>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) =>
                                        this.setState({dayTeacherConsultation: value})}
                                    locale={"uk-UA"}
                                    value={this.state.dayTeacherConsultation}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Початок консультації</Table.Cell>
                            <Table.Cell>
                                <TimeInput
                                    initTime={this.state.startTeacherConsultation}
                                    className={"w-100 form-control"}
                                    onTimeChange={
                                        (value) =>
                                            this.setState({startTeacherConsultation: value})
                                    }
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Кінець консультації</Table.Cell>
                            <Table.Cell>
                                <TimeInput
                                    initTime={this.state.endTeacherConsultation}
                                    className={"w-100 form-control"}
                                    onTimeChange={
                                        (value) =>
                                            this.setState({endTeacherConsultation: value})
                                    }
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        consultations: state.get("consultations"),
        audiences: state.get("data4Select").get("audiences"),
    }
}

export default compose(
    connectModal({
        name: 'teachersConsultationsModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editTeacherConsultationInfo,
        insertTeacherConsultation
    }))(TeachersConsultationsModal);
