import {List} from "immutable"
import {EDIT_TEACHER_RATES,FETCH_TEACHERS_RATES,DELETE_TEACHER_RATE,INSERT_TEACHER_RATE} from "./types";
const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_TEACHERS_RATES:
            return List(action.payload);
        case EDIT_TEACHER_RATES:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case INSERT_TEACHER_RATE:
            return state.insert(0, action.payload);
        case DELETE_TEACHER_RATE:
            return state.delete(state.findIndex(i => i.id === action.payload));
        default:
            return state;
    }
}