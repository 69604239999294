import axios from "axios";
import {DELETE_WEEKEND, EDIT_WEEKEND_INFO, FETCH_WEEKENDS, INSERT_WEEKEND} from "./types";
import {ROOT_URL} from "../../../utils";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchWeekends() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/weekends`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_WEEKENDS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function editWeekendInfo(weekend) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/university/weekends/edit`, weekend, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_WEEKEND_INFO, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertWeekend(weekend) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/university/weekend/create`, weekend, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_WEEKEND, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function deleteWeekend(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/university/weekend/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_WEEKEND, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}



