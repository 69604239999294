import {List} from "immutable"
import {DELETE_SCHOLARSHIP, EDIT_SCHOLARSHIP_INFO, FETCH_SCHOLARSHIPS, INSERT_SCHOLARSHIP} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_SCHOLARSHIPS:
            return List(action.payload);
        case EDIT_SCHOLARSHIP_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case INSERT_SCHOLARSHIP:
            return state.insert(0, action.payload);
        case DELETE_SCHOLARSHIP:
            return state.delete(state.findIndex(i => i.id === action.payload));
        default:
            return state;
    }
}
