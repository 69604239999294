import React, {PureComponent} from "react";
import PureModal from 'react-pure-modal';
import AudienceInfo from "./components/AudienceInfo";
import {audienceCoincidences, insertLessonAudience} from "../../../actions";
import "./styles.css"
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import {compose} from "redux";
import {connectModal, hide} from "redux-modal";
import {connect} from "react-redux";
import {Accordion, AccordionItem} from "react-sanfona";

class AudiencesModal extends PureComponent {
    state = {
        selected: ''
    };

    changeSelected = (id) => {
        this.setState({selected: id});
    };

    renderAudienceRows = (audiences) => {
        let rows = [];
        if (audiences) {
            let i = 0;
            for (let k = 0; k < Math.ceil(audiences.length / 10); k++) {
                let cells = [];
                for (let j = 0; j < 10; j++) {
                    if (typeof audiences[i] === 'undefined') {
                        cells.push(<Table.Cell key={i}/>);
                    } else {
                        cells.push(this.renderCell(audiences[i]));
                    }
                    ++i;
                }
                rows.push(<Table.Row key={k}>{cells}</Table.Row>);
            }
        }
        return rows;

    };
    renderCell = (audience) => {
        return <Table.Cell key={audience.id}
                           onDoubleClick={() => {
                               this.props.insertLessonAudience(
                                   {audience: audience.id, id: this.props.id, name: audience.name});
                               this.props.hide("audiencesModal")
                           }}
                           onClick={() => {
                               this.changeSelected(audience.id)
                           }}
                           className={`${this.state.selected.toString() === audience.id.toString() ? "selected" :
                               audience.includesAudience ? 'includesAudience ' :
                                   audience.enoughSeats ? ' ' : " notEnoughSeats"}`}>
            {
                audience.includesAudience ?
                    <Popup
                        trigger={<div>{audience.name} <em>({audience.countSeats})</em></div>}
                        content={
                            <div>
                                <em>{audience.groups} </em>
                                <span> {audience.teacher}</span>
                            </div>
                        }
                    /> : audience.enoughSeats ? <div>{audience.name} <em>({audience.countSeats})</em></div> : <Popup
                        trigger={<div>{audience.name} <em>({audience.countSeats})</em></div>}
                        content={
                            <div>
                                {"Недостатньо місць"}
                            </div>
                        }
                    />
            }

        </Table.Cell>
    };

    renderAccordion = (audiences, faculties, facultyId) => {
        return (
            <Accordion onChange={(data) => {
                if (data.activeItems) {
                    if (data.activeItems.length > 0) {
                        this.props.audienceCoincidences({
                            id: this.props.lesson.id,
                            facultyId: faculties[data.activeItems[0]].id
                        });
                    }
                }
            }}>
                {faculties.map((faculty) => {
                    return (
                        <AccordionItem key={faculty.id} title={faculty.name}
                                       expanded={faculty.id === facultyId}>
                            {audiences.length > 0 && faculty.id === facultyId ?
                                <Table celled textAlign={"center"} verticalAlign={"middle"}>
                                    <Table.Body>
                                        {this.renderAudienceRows(audiences)}
                                    </Table.Body>
                                </Table> : <div/>}

                        </AccordionItem>
                    );
                })}

            </Accordion>
        )
    };

    render() {
        const {show} = this.props;
        let facultyId = "";
        let all = [], freeAudience = [], faculties = [], suggestions = [];
        if (Object.keys(this.props.audiences.get("audienceCoincidences")).length > 0) {
            facultyId = this.props.audiences.get("audienceCoincidences").facultyId;
            all = this.props.audiences.get("audienceCoincidences").all;
            freeAudience = this.props.audiences.get("audienceCoincidences").freeAudience;
            faculties = this.props.audiences.get("faculties");
            suggestions = this.props.audiences.get("audiences");
        }
        return (
            <PureModal
                header={<div><h4>Аудиторний фонд університету</h4></div>}
                width={"90vw"}
                isOpen={show}
                onClose={() => {
                    this.props.hide("audiencesModal");
                }}
            >
                <div>
                    <AudienceInfo lesssonInfo={this.props.lesson}/>
                    <br/>
                    {suggestions.length > 0 && <div>
                        <h4 className={"text-center"}>Рекомендовані аудиторії</h4>
                        <Table celled textAlign={"center"} verticalAlign={"middle"}>
                            <Table.Body>
                                <Table.Row>
                                    {suggestions.map((audience) => {
                                        return (<Table.Cell
                                            onDoubleClick={() => {
                                                this.props.insertLessonAudience(
                                                    {audience: audience.id, id: this.props.id, name: audience.name});
                                                this.props.hide("audiencesModal")
                                            }}
                                            onClick={this.changeSelected.bind(this, audience.id)}
                                            className={`${this.state.selected === audience.id ? "selected" : !audience.enoughSeats ? "notEnoughSeats" : ''}`}
                                            key={`suggestion_${audience.id}`}>
                                            <div>{audience.name} <em>({audience.countSeats})</em></div>
                                        </Table.Cell>)
                                    })}
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>}
                    <br/>
                    <div>
                        <Tabs>
                            <TabList>
                                <Tab>Всі</Tab>
                                <Tab>Вільні</Tab>
                                <Tab>Проектор</Tab>
                                <Tab>Комп'ютер</Tab>
                            </TabList>


                            <TabPanel>
                                {this.renderAccordion(all, faculties, facultyId)}
                            </TabPanel>
                            <TabPanel>
                                {this.renderAccordion(freeAudience, faculties, facultyId)}
                            </TabPanel>
                            <TabPanel>
                                <Table celled textAlign={"center"} verticalAlign={"middle"}>
                                    <Table.Body>
                                        {this.renderAudienceRows(this.props.audiences.get("hasProjector"))}
                                    </Table.Body>
                                </Table>
                            </TabPanel>
                            <TabPanel>
                                <Table celled textAlign={"center"} verticalAlign={"middle"}>
                                    <Table.Body>
                                        {this.renderAudienceRows(this.props.audiences.get("compAudience"))}
                                    </Table.Body>
                                </Table>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>

            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        audiences: state.get("lessons").get("audiences"),

    }
}

export default compose(
    connectModal({
        name: 'audiencesModal',
        getModalState: (state) => state.get("modal")
    }), connect(mapStateToProps, {
        insertLessonAudience,
        hide,
        audienceCoincidences
    })
)(AudiencesModal);