import axios from "axios";
import {
    DELETE_UNIVERSITY_GROUP,
    EDIT_UNIVERSITY_GROUP_INFO,
    FETCH_UNIVERSITY_GROUPS,
    GENERATE_UNIVERSITY_GROUP,
} from "./types";
import {ROOT_URL} from "../../../utils";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchHierarchyUniversityGroups() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/hierarchyGroups`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            console.log("data",data)
            dispatch({type: FETCH_UNIVERSITY_GROUPS, payload: data})

        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function editUniversityGroupsInfo(universityGroup) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/university/groups/edit`, universityGroup, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_UNIVERSITY_GROUP_INFO, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function generateUniversityGroup(groups) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/university/group/generate`, groups, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: GENERATE_UNIVERSITY_GROUP, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteUniversityGroup(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/university/group/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: DELETE_UNIVERSITY_GROUP, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}




