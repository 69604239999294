import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import DatePicker from 'react-date-picker'
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {generateWeeks} from "../../actions";

class WeekModal extends PureComponent {
    state = {
        start: new Date(),
        end: new Date(),
    };
    handleSave = () => {
        this.props.generateWeeks({
            start: this.state.start,
            end: this.state.end,
        });
        this.props.hide("weekModal")
    };


    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Механізм генерації тижнів на основі семестру"}
                width={"95vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() => this.props.hide("weekModal")}>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
                onClose={() => {
                    this.props.hide("weekModal");
                }}
            >
                <Table celled compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Початок семестру/тижня</Table.HeaderCell>
                            <Table.HeaderCell>Кінець семестру/тижня</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) => this.setState({start: value})}
                                    locale={"uk-UA"}
                                    value={this.state.start}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) => this.setState({end: value})}
                                    locale={"uk-UA"}
                                    value={this.state.end}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}


export default compose(
    connectModal({
        name: 'weekModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(null, {
        hide,
        generateWeeks
    }))(WeekModal);
