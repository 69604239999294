import React, {PureComponent} from "react";
import {connect} from "react-redux";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import ReactToPrint from "react-to-print";
import {
    fetchStudyYears4Select,
    fetchTeachers4Select,
    fetchWeeks4Select,
    fetchWeeksBySemester
} from "../../../../components/Data4Select/actions";
import UserTimetable from "../../UserTimetable/components/UserTimetable"
import {writeErrors} from "../../../../utils/logs";
import {fetchDefaultUserTimetable, fetchUserTimetable} from "../../actions";
import Select from "react-select";
import {loadingMessage, NoOptionsMessage} from "../../../../utils";
import AsyncSelect from 'react-select/lib/Async';
import {getPagePrintStyle} from "../../printPageStyle";

class UserLessons extends PureComponent {
    state = {
        studyYear: null,
        week: null,
        teacher: null,
        showEmptyRows: false,
        defaultLoadTimetable: true
    };
    loadOptions = inputValue => fetchTeachers4Select(inputValue);
    fetchUserTimetable = (object) => {
        this.props.fetchUserTimetable({
            me: this.state.teacher === null,
            teacher: this.state.teacher,
            showEmptyRows: this.state.showEmptyRows,
            ...object
        });
    };
    prevWeek = () => {
        let index = this.state.week ? this.props.weeks.findIndex(i => i.value === this.state.week.value) - 1 : this.props.weeks.findIndex(i => i.isSelected) - 1;
        if (index > -1) {
            const week = this.props.weeks.get(index);
            this.setState({
                week: week
            });
            this.fetchUserTimetable({
                week: week.value,
            })
        }
    };
    nextWeek = () => {
        let index = this.state.week ? this.props.weeks.findIndex(i => i.value === this.state.week.value) + 1 : this.props.weeks.findIndex(i => i.isSelected) + 1;
        if (index < this.props.weeks.size) {
            const week = this.props.weeks.get(index);
            this.setState({
                week: week
            });
            this.fetchUserTimetable({
                week: week.value,
            })
        }

    };

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    componentDidMount() {
        this.props.fetchStudyYears4Select();
        this.props.fetchWeeks4Select();
        this.props.fetchDefaultUserTimetable();
    }

    render() {
        let weekValue = this.state.week;
        let studyYearValue = this.state.studyYear;
        if (!weekValue && this.props.weeks.size > 0) {
            const filter = this.props.weeks.filter(i => i.isSelected);
            weekValue = filter.size > 0 ? filter.get(0) : this.props.weeks.get(0);
        }
        if (!studyYearValue && this.props.studyYears.size > 0) {
            const filter = this.props.studyYears.filter(i => i.isSelected);
            studyYearValue = filter.size > 0 ? filter.get(0) : this.props.studyYears.get(0);
        }
        return (
            <div style={{overflow: "auto", height: "79vh"}}>
                <div>
                    <Table celled compact={"very"}>
                        <Table.Header className={"text-center"}>
                            <Table.Row>
                                <Table.HeaderCell className={"text-center"}>Навчальний рік</Table.HeaderCell>
                                <Table.HeaderCell className={"text-center"}>Тижні</Table.HeaderCell>
                                <Table.HeaderCell className={"text-center"}>Викладач</Table.HeaderCell>
                                <Table.HeaderCell className={"text-center"}>Показати "Вікна"</Table.HeaderCell>
                                <Table.HeaderCell/>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Select
                                        options={this.props.studyYears.toArray()}
                                        onChange={selectedValue => {
                                            let studyYear = null;
                                            if (selectedValue !== null) {
                                                studyYear = selectedValue;
                                            }
                                            this.setState({studyYear});
                                            this.props.fetchWeeksBySemester(selectedValue.value)
                                        }}
                                        value={studyYearValue}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Виберіть навчальний рік"}
                                    />
                                </Table.Cell>

                                <Table.Cell className={"w-30"}>
                                    <div className={"w-100"}
                                         style={{
                                             display: "inline-flex",
                                             direction: "row"
                                         }}>
                                        <div>
                                            <Button icon='left chevron'
                                                    onClick={
                                                        this.prevWeek
                                                    }
                                                    style={{
                                                        padding: "0.78571429em 0.8571429em 1.1em",
                                                        margin: "0"
                                                    }}/>
                                        </div>
                                        <div style={{
                                            flex: "1 0 auto"
                                        }}>
                                            <Select
                                                options={this.props.weeks.toArray()}
                                                onChange={selectedValue => {
                                                    let week = null;
                                                    if (selectedValue !== null) {
                                                        week = selectedValue;
                                                    }
                                                    this.setState({week});
                                                    this.fetchUserTimetable({
                                                        week: week.value,
                                                    })
                                                }}
                                                value={weekValue}
                                                noOptionsMessage={NoOptionsMessage}
                                                isClearable={false}
                                                placeholder={"Виберіть навчальний тиждень"}
                                            />

                                        </div>
                                        <div>
                                            <Button icon='right chevron' onClick={
                                                this.nextWeek
                                            }
                                                    style={{
                                                        padding: "0.78571429em 0.8571429em 1em"
                                                    }}/>
                                        </div>
                                    </div>
                                </Table.Cell>
                                <Table.Cell className={"w-15"}>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={this.loadOptions}
                                        noOptionsMessage={NoOptionsMessage}
                                        loadingMessage={loadingMessage}
                                        isClearable={false}
                                        defaultValue={this.state.teacher}
                                        onChange={selectedValue => {
                                            let teacher = null;
                                            if (selectedValue) {
                                                teacher = selectedValue.value;
                                            }
                                            this.setState({teacher});
                                            this.fetchUserTimetable({
                                                me: false,
                                                week: weekValue.value,
                                                teacher: teacher,
                                            })
                                        }}
                                        placeholder={"Виберіть викладача"}
                                    />
                                </Table.Cell>
                                <Table.Cell className={"w-10"}
                                            style={{
                                                textAlign: "center",
                                                verticalAlign: "middle"
                                            }}
                                >
                                    <div className="pretty p-default  text-center  p-curve p-fill"
                                         style={{fontSize: "20px"}}
                                    >
                                        <input
                                            onChange={() => {
                                                const {showEmptyRows} = this.state;
                                                this.setState({showEmptyRows: !showEmptyRows});
                                                this.fetchUserTimetable({
                                                    week: weekValue.value,
                                                    showEmptyRows: !showEmptyRows
                                                })
                                            }}
                                            type="checkbox" defaultChecked={this.state.showEmptyRows}/>
                                        <div className="state p-info">
                                            <label/>
                                        </div>
                                    </div>
                                </Table.Cell>
                                <Table.Cell className={"w-15"} style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                }}>
                                    <ReactToPrint
                                        trigger={() => <Button icon color={"teal"}> <Icon
                                            name={"print"}/> Друк</Button>}
                                        content={() => this.componentRef}
                                        copyStyles={false}
                                        pageStyle={getPagePrintStyle()}
                                    />

                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
                <div>
                    <UserTimetable ref={el => (this.componentRef = el)}/>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        weeks: state.get("data4Select").get("weeksBySemester"),
        studyYears: state.get("data4Select").get("studyYears4Select"),
    }
}

export default connect(mapStateToProps, {
    fetchWeeks4Select,
    fetchStudyYears4Select,
    fetchUserTimetable,
    fetchDefaultUserTimetable,
    fetchWeeksBySemester
})(UserLessons);
