import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editCustomLessonsInfo, insertCustomLesson} from "../../actions";
import {getSemesters, loadingMessage, NoOptionsMessage} from "../../../../utils";
import AsyncSelect from 'react-select/lib/Async';
import {fetchTeachers4Select, fetchUniversityGroupsWithFlows} from "../../../../components/Data4Select/actions";
import Select from "react-select";
import {toast} from "react-toastify";

class CustomLessonsModal extends PureComponent {
    loadOptions = inputValue => fetchTeachers4Select(inputValue);
    loadUniversityGroupsOptions = inputValue => fetchUniversityGroupsWithFlows(inputValue);
    handleSave = () => {
        if (!this.state.group.value){
            toast.warn("Вкажіть групу", {
                position: "top-right",
                autoClose: 5000,
            });
            return;
        }
        const object = {
            id: this.state.id,
            disciplineName: this.state.name,
            semester: this.state.semester?this.state.semester.value : "",
            teacherReplacement: this.state.teacherReplacement?this.state.teacherReplacement.value : "",
            teachers: this.state.teachers.map(i => i.value).join(","),
            universityGroup: this.state.group?this.state.group.value : "",
        };
        if (this.state.isCreate) {
            this.props.insertCustomLesson(object);
        } else {
            this.props.editCustomLessonsInfo(object);
        }
        this.props.hide("customLessonsModal");
    };

    constructor(props) {
        super(props);
        let row = {};
        if (props.isCreate) {
            row = {};
        } else {
            row = props.row._original
        }

        this.state = {
            id: row.id || "",
            isCreate: props.isCreate,
            name: row.disciplineName || "",
            semester: row.semester ? getSemesters()[row.semester - 1] : getSemesters()[0],
            teacherReplacement: row.teacherReplacementId || null,
            teachers: row.teachers ? row.teacherIds : [],
            group: row.universityGroupId || "",

        }
    }

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Створення та редагування інформації про спеціальну дисципліну"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("customLessonsModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
                onClose={() => {
                    this.props.hide("customLessonsModal");
                }}
            >
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Назва предмету
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({name: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.name}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Наскрізний семестр
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={getSemesters()}
                                    onChange={(selectedValue) => {
                                        this.setState({
                                            semester: selectedValue,
                                        })
                                    }}
                                    defaultValue={this.state.semester}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Вкажіть наскрізний семестр"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Група/ Потік
                            </Table.Cell>
                            <Table.Cell>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={this.loadUniversityGroupsOptions}
                                    noOptionsMessage={NoOptionsMessage}
                                    loadingMessage={loadingMessage}
                                    isClearable={false}
                                    defaultValue={this.state.group}
                                    onChange={(selected) => {
                                        this.setState({group: selected})
                                    }}
                                    placeholder={"Виберіть групу/потік"}

                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Викладачі
                            </Table.Cell>
                            <Table.Cell>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={this.loadOptions}
                                    noOptionsMessage={NoOptionsMessage}
                                    loadingMessage={loadingMessage}
                                    isMulti
                                    isClearable
                                    defaultValue={this.state.teachers}
                                    onChange={(selectedValue) => this.setState({teachers: selectedValue})}
                                    placeholder={"Виберіть викладача"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell className={"w-20"}>
                                Заміна
                            </Table.Cell>
                            <Table.Cell>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={this.loadOptions}
                                    noOptionsMessage={NoOptionsMessage}
                                    loadingMessage={loadingMessage}
                                    defaultValue={this.state.teacherReplacement}
                                    onChange={(selectedValue) => this.setState({teacherReplacement: selectedValue})}
                                    isClearable
                                    placeholder={"Вкажіть заміну викладача"}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        teachers: state.get("data4Select").get("teachers"),
    }
}

export default compose(
    connectModal({
        name: 'customLessonsModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editCustomLessonsInfo,
        insertCustomLesson
    }))(CustomLessonsModal);

