import {List} from "immutable"
import {DELETE_STUDY_YEAR, EDIT_STUDY_YEAR_INFO, FETCH_STUDY_YEARS, INSERT_STUDY_YEAR} from "./types"
import {DELETE_SEMESTER, EDIT_SEMESTER_INFO, INSERT_SEMESTER} from "../Semesters/types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_STUDY_YEARS:
            return List(action.payload);
        case EDIT_STUDY_YEAR_INFO:
            return List(action.payload);
        case INSERT_STUDY_YEAR:
            return List(action.payload);
        case DELETE_STUDY_YEAR:
            return List(action.payload);
        case EDIT_SEMESTER_INFO:
            return List(action.payload);
        case INSERT_SEMESTER:
            return List(action.payload);
        case DELETE_SEMESTER:
            return List(action.payload);
        default:
            return state;
    }
}