import {List} from "immutable"
import {
   DELETE_USER_DOCUMENT_TYPE,
    EDIT_USER_DOCUMENT_TYPE_INFO,
    FETCH_USER_DOCUMENT_TYPES,INSERT_USER_DOCUMENT_TYPE
} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_USER_DOCUMENT_TYPES:
            return List(action.payload);
        case EDIT_USER_DOCUMENT_TYPE_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case INSERT_USER_DOCUMENT_TYPE:
            return state.insert(0, action.payload);
        case DELETE_USER_DOCUMENT_TYPE:
            return state.delete(state.findIndex(i => i.id === action.payload));
        default:
            return state;
    }
}
