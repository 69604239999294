import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editTeachersRatesInfo, insertTeachersRates,} from "../../actions";
import DatePicker from "react-date-picker";
import {getDefaultOption, loadingMessage, NoOptionsMessage} from "../../../../../utils";
import Select from "react-select";
import AsyncSelect from 'react-select/lib/Async';
import {fetchTeachers4Select} from "../../../../../components/Data4Select/actions";

class RateModal extends PureComponent {
    handleSave = () => {
        const object = {
            id: this.state.id,
            teacherId: this.state.teacherId.value,
            teacherStatusId: this.state.teacherStatusId.value,
            normHours: this.state.normHours,
            rateValue: this.state.rateValue,
            rateHourly: this.state.rateHourly,
            start: this.state.dateStart,
            end: this.state.dateEnd,
        };
        if (this.state.isCreate) {
            this.props.insertTeachersRates(object);
        } else {
            this.props.editTeachersRatesInfo(object);
        }
        this.props.hide("rateModal")
    };
    loadOptions = inputValue => fetchTeachers4Select(inputValue);

    constructor(props) {
        super(props);
        console.log(this.props);
        let row = {};
        if (props.isCreate) {
            row = {};
        } else {
            row = props.row._original
        }
        const year = new Date().getFullYear();
        const dateEnd = new Date(year, 8, 1);
        const dateStart = new Date(year + 1, 6, 1);
        this.state = {
            id: row.id || "",
            isCreate: props.isCreate,
            department: row.department || localStorage.getItem("OA.UM.Dekanat_userDepartment") || '',
            teacherId: row.teacherId ? {
                value: row.teacherId,
                label: row.teacher
            } : null,
            teacherStatusId: row.teacherStatusId ? {
                value: row.teacherStatusId,
                label: row.teacherStatus
            } : null,
            normHours: row.normHours || 0,
            rateValue: row.rateValue || 0,
            rateHourly: row.rateHourly || 0,
            dateEnd: row.dateEnd ? new Date(row.dateEnd) : dateStart,
            dateStart: row.dateStart ? new Date(row.dateStart) : dateEnd,

        }
    }

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Створення та редагування інформації про ставку викладача"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={e =>
                            this.props.hide("rateModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                onClose={() => this.props.hide("rateModal")}
                isOpen={show}
            >
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell className={"w-25"}>
                                Викладач
                            </Table.Cell>
                            <Table.Cell>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={this.loadOptions}
                                    noOptionsMessage={NoOptionsMessage}
                                    loadingMessage={loadingMessage}
                                    isDisabled={!this.state.isCreate}
                                    isClearable={false}
                                    defaultValue={this.state.teacherId}
                                    onChange={selected => this.setState({
                                        teacherId: selected ||
                                        getDefaultOption()
                                    })}
                                    placeholder={"Виберіть викладача"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Початок
                            </Table.Cell>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) => this.setState({dateStart: value})}
                                    locale={"uk-UA"}
                                    value={this.state.dateStart}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Кінець
                            </Table.Cell>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) => this.setState({dateEnd: value})}
                                    locale={"uk-UA"}
                                    value={this.state.dateEnd}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Статус викладача на кафедрі
                            </Table.Cell>
                            <Table.Cell>

                                <Select
                                    options={this.props.teachersStatus.toArray()}
                                    onChange={selected => {
                                        this.setState({teacherStatusId: selected || getDefaultOption()})
                                    }}
                                    defaultValue={this.state.teacherStatusId}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Вкажіть статус викладача на кафедрі"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Норма годин
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({normHours: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.normHours}
                                    min={0}
                                    type="number"
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Ставка
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({rateValue: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.rateValue}
                                    min={0}
                                    type="number"
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Погодинка
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({rateHourly: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.rateHourly}
                                    min={0}
                                    type="number"
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Кафедра
                            </Table.Cell>
                            <Table.Cell>
                                {this.state.department}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        teachersStatus: state.get("data4Select").get("teachersStatus"),
    }
}

export default compose(
    connectModal({
        name: 'rateModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editTeachersRatesInfo,
        insertTeachersRates,
    }))(RateModal);

