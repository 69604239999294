import {List} from "immutable"
import {DELETE_FACULTY, EDIT_FACULTY_INFO, FETCH_FACULTIES, INSERT_FACULTY} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_FACULTIES:
            return List(action.payload);
        case EDIT_FACULTY_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case INSERT_FACULTY:
            return state.insert(0, action.payload);
        case DELETE_FACULTY:
            return state.delete(state.findIndex(i => i.id === action.payload));
        default:
            return state;
    }
}