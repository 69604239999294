import {List} from "immutable";
import {
    DELETE_UNIVERSITY_FLOW,
    EDIT_UNIVERSITY_FLOW_INFO,
    FETCH_UNIVERSITY_FLOWS,
    INSERT_UNIVERSITY_FLOW,
} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_UNIVERSITY_FLOWS:
            return List(action.payload);
        case EDIT_UNIVERSITY_FLOW_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case INSERT_UNIVERSITY_FLOW:
            return state.insert(0, action.payload);
        case DELETE_UNIVERSITY_FLOW:
            return state.delete(state.findIndex(i => i.id === action.payload));
        default:
            return state;
    }
}