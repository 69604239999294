import {List, Map} from "immutable"
import {
    FETCH_AUDIENCE_FOR_SELECT,
    FETCH_DEPARTMENTS_FOR_SELECT,
    FETCH_DISCIPLINES_CYCLES_FOR_SELECT,
    FETCH_DISCIPLINES_FOR_LESSONS,
    FETCH_FACULTIES_FOR_SELECT_WITH_DEFAULT,
    FETCH_FREE_AUDIENCES,
    FETCH_SPECIALITIES_FOR_SELECT,
    FETCH_SPECIALITIES_WITH_STUDY_LEVEL_FOR_SELECT,
    FETCH_STUDY_YEARS_FOR_SELECT,
    FETCH_TEACHER_STATUS,
    FETCH_TEACHERS_FOR_LESSONS,
    FETCH_UNIVERSITY_GROUPS_FOR_LESSONS,
    FETCH_UNIVERSITY_GROUPS_FOR_SELECT, FETCH_WEEKS_BY_SEMESTER,
    FETCH_WEEKS_FOR_SELECT
} from "./types";

const initialState = Map({
    "weeks": List(),
    "weeksBySemester": List(),
    "teachersStatus": List(),
    "audiences": List(),

    "specialitiesWithStudyLevel": List(),
    "groups": List(),
    "groupsWithFlows": List(),
    "departments": List(),
    "studyYears4Select": List(),

    "groups4Lessons": List(),
    "teachers4Lessons": List(),
    "disciplines4Lessons": List(),
    "freeAudience": List(),
    "disciplinesCycles": List(),
    "specialities": List(),
    "teachersRanks": List(),
    "facultiesWithDefault": List(),

});
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_FACULTIES_FOR_SELECT_WITH_DEFAULT:
            return state.set("facultiesWithDefault", List(action.payload));
        case FETCH_TEACHER_STATUS:
            return state.set("teachersStatus", List(action.payload));
        case FETCH_WEEKS_FOR_SELECT:
            return state.set("weeks", List(action.payload));
        case FETCH_FREE_AUDIENCES:
            return state.set("freeAudience", List(action.payload));
        case FETCH_AUDIENCE_FOR_SELECT:
            return state.set("audiences", List(action.payload));
        case FETCH_DEPARTMENTS_FOR_SELECT:
            return state.set("departments", List(action.payload));

        case FETCH_SPECIALITIES_WITH_STUDY_LEVEL_FOR_SELECT:
            return state.set("specialitiesWithStudyLevel", List(action.payload));
        case FETCH_UNIVERSITY_GROUPS_FOR_SELECT:
            return state.set("groups", List(action.payload));
        case FETCH_UNIVERSITY_GROUPS_FOR_LESSONS:
            return state.set("groups4Lessons", List(action.payload));
        case FETCH_TEACHERS_FOR_LESSONS:
            return state.set("teachers4Lessons", List(action.payload));
        case FETCH_DISCIPLINES_FOR_LESSONS:
            return state.set("disciplines4Lessons", List(action.payload));
        case FETCH_DISCIPLINES_CYCLES_FOR_SELECT:
            return state.set("disciplinesCycles", List(action.payload));
        case FETCH_SPECIALITIES_FOR_SELECT:
            return state.set("specialities", List(action.payload));
        case FETCH_STUDY_YEARS_FOR_SELECT:
            return state.set("studyYears4Select", List(action.payload));
        case FETCH_WEEKS_BY_SEMESTER:
            return state.set("weeksBySemester", List(action.payload));
        default:
            return state;
    }
}
