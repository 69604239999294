import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import DatePicker from 'react-date-picker';
import {createUser, fetchDuplicatesUsers} from "../actions"
import {NoOptionsMessage} from "../../../utils";
import Select from "react-select";

class CreateUserModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            middleName: "",
            lastName: "",
            ipn: "",
            emails:[],
            gender: {label: "Чоловіча", value: 1},
            dateBirth: new Date(1991, 0, 1),
        }
    }

    handleSave = () => {
        const object = {
            name: this.state.name,
            lastName: this.state.lastName,
            middleName: this.state.middleName,
            dateBirth: this.state.dateBirth,
            ipn: this.state.ipn,
            gender: this.state.gender ? this.state.gender.value : 1,
        };
        this.props.createUser(object);


        this.props.hide("createUserModal")
    };

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Створення користувача"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("createUserModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                onClose={() => {
                    this.props.hide("createUserModal");
                }}
                isOpen={show}
            >
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Прізвище
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({lastName: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.lastName}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Ім'я
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => {
                                       const value = e.target.value;
                                       const users = fetchDuplicatesUsers(value,this.state.lastName,this.state.middleName)
                                        this.setState({name: value,users})
                                    }}
                                    className={"form-control"}
                                    value={this.state.name}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                По батькові
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={(e) => this.setState({middleName: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.middleName}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Індивідуальний номер платника податків
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={(e) => this.setState({ipn: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.ipn}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Дата народження
                            </Table.Cell>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) => this.setState({dateBirth: value})}
                                    locale={"uk-UA"}
                                    value={this.state.dateBirth}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Стать
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={[{label: "Чоловіча", value: 1}, {label: "Жіноча", value: 2}]}
                                    onChange={(selected) => {
                                        this.setState({gender: selected})
                                    }}
                                    defaultValue={this.state.gender}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Стать користувача"}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}


export default compose(
    connectModal({
        name: 'createUserModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(null, {
        hide,
        createUser
    }))(CreateUserModal);

