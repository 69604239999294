export const FETCH_WEEKS_FOR_SELECT = "FETCH_WEEKS_FOR_SELECT";
export const FETCH_SPECIALITIES_WITH_STUDY_LEVEL_FOR_SELECT = "FETCH_SPECIALITIES_WITH_STUDY_LEVEL_FOR_SELECT";
export const FETCH_UNIVERSITY_GROUPS_FOR_SELECT = "FETCH_UNIVERSITY_GROUPS_FOR_SELECT";


export const FETCH_UNIVERSITY_GROUPS_FOR_LESSONS = "FETCH_UNIVERSITY_GROUPS_FOR_LESSONS";
export const FETCH_TEACHERS_FOR_LESSONS = "FETCH_TEACHERS_FOR_LESSONS";
export const FETCH_DISCIPLINES_FOR_LESSONS = "FETCH_DISCIPLINES_FOR_LESSONS";


export const FETCH_DEPARTMENTS_FOR_SELECT = "FETCH_DEPARTMENTS_FOR_SELECT";
export const FETCH_DISCIPLINES_CYCLES_FOR_SELECT = "FETCH_DISCIPLINES_CYCLES_FOR_SELECT";
export const FETCH_FACULTIES_FOR_SELECT_WITH_DEFAULT = "FETCH_FACULTIES_FOR_SELECT_WITH_DEFAULT";
export const FETCH_SPECIALITIES_FOR_SELECT = "FETCH_SPECIALITIES_FOR_SELECT";
export const FETCH_AUDIENCE_FOR_SELECT = "FETCH_AUDIENCE_FOR_SELECT";

export const FETCH_FREE_AUDIENCES = "FETCH_FREE_AUDIENCE";
export const FETCH_TEACHER_STATUS = "FETCH_TEACHER_STATUS";

export const FETCH_STUDY_YEARS_FOR_SELECT = "FETCH_STUDY_YEARS_FOR_SELECT";

export const FETCH_WEEKS_BY_SEMESTER = "FETCH_WEEKS_BY_SEMESTER";




