import axios from "axios";
import {
    DELETE_TEACHER_RANK,
    EDIT_TEACHER_RANK,
    FETCH_TEACHERS_RANKS,
    INSERT_TEACHER_RANK,
} from "./types";
import {ROOT_URL} from "../../../utils";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";

export function fetchTeachersRanks() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/teachers/ranks`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({
                type: FETCH_TEACHERS_RANKS,
                payload: data
            })
        }).catch((error) => {
            writeErrors(error);
        });
    }

}



export function editTeachersRanks(teacherRank) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/teachers/ranks/edit`, teacherRank, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_TEACHER_RANK, payload: teacherRank})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertTeacherRank(teacherRank) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/teachers/rank/create`, teacherRank, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_TEACHER_RANK, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteTeacherRank( id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/teachers/rank/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_TEACHER_RANK, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}