import {List} from "immutable";
import {
    DELETE_UNIVERSITY_GROUP,
    FETCH_UNIVERSITY_GROUPS,
    EDIT_UNIVERSITY_GROUP_INFO,
    GENERATE_UNIVERSITY_GROUP,
} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_UNIVERSITY_GROUPS:
            return List(action.payload);
        case EDIT_UNIVERSITY_GROUP_INFO:
            return List(action.payload);
        case GENERATE_UNIVERSITY_GROUP:
            return List(action.payload);
        case DELETE_UNIVERSITY_GROUP:
            return List(action.payload);
        default:
            return state;
    }
}