import {List} from "immutable"
import {CREATE_USER, EDIT_UNIVERSITY_USER_INFO, EDIT_USER_SPECIALTY_INFO, FETCH_UNIVERSITY_USERS} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_UNIVERSITY_USERS:
            return List(action.payload);
        case CREATE_USER:
            return state.insert(0, action.payload);
        case EDIT_UNIVERSITY_USER_INFO:
        case EDIT_USER_SPECIALTY_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        default:
            return state;
    }
}
