import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editTeacherLoadInfo, insertTeacherLoadItem} from "../../actions";
import {getSemesters, loadingMessage, NoOptionsMessage} from "../../../../../utils";
import Select from "react-select";
import {fetchTeachers4Select, fetchUniversityGroupsWithFlows} from "../../../../../components/Data4Select/actions";
import AsyncSelect from 'react-select/lib/Async';

class TeacherLoadsModal extends PureComponent {
    handleSave = () => {

        const object = {
            id: this.state.id,
            numberHours: this.state.numberHours,
            disciplineTypeId: this.state.disciplineType ? this.state.disciplineType.value : '',
            semester: this.state.semester.value,
            groupId: this.state.groups ? this.state.groups.value : '',
            teacherId: this.state.teacher ? this.state.teacher.value : '',
            teacherReplacementId: this.state.teacherReplacement ? this.state.teacherReplacement.value : '',
            departmentId: this.state.department ? this.state.department.value : '',
        };

        if (this.state.isCreate) {
            this.props.insertTeacherLoadItem(object);
        } else {
            this.props.editTeacherLoadInfo(object);
        }
        this.props.hide("teacherLoadsModal")
    };

    loadOptions = inputValue => fetchTeachers4Select(inputValue);
    loadUniversityGroupsOptions = inputValue => fetchUniversityGroupsWithFlows(inputValue);

    constructor(props) {
        super(props);
        const row = props.row || {};

        const id = props.row.id;
        this.state = {
            id: id,
            isCreate: props.isCreate,
            numberHours: row.numberHours || 0,
            disciplineType: row.disciplineTypeId ? {
                value: row.disciplineTypeId,
                label: row.disciplineType
            } : null,
            semester: row.semester ? {
                value: row.semester,
                label: row.semester
            } : getSemesters()[0],
            teacher: row.teacherId ? {
                value: row.teacherId,
                label: row.teacher
            } : null,

            teacherReplacement: row.teaherReplacementId ? {
                value: row.teacherReplacementId,
                label: row.teacherReplacement
            } : null,
            groups: row.groupId ? {
                value: row.groupId,
                label: row.groups
            } : null,
            department:
                row.departmentId ? {
                    value: row.departmentId ,
                    label: row.department
                } : {
                    value: localStorage.getItem("OA.UM.Dekanat_userDepartmentId") || '',
                    label: localStorage.getItem("OA.UM.Dekanat_userDepartment") || ''
                },
        }
    }

    render() {
        const {show} = this.props;
        const lessonTypes = this.props.lessonTypes.toArray();
        const departments = this.props.departments.toArray();
        return (
            <PureModal
                header={"Створення та редагування інформації по запису навантаження"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("teacherLoadsModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                onClose={() => this.props.hide("teacherLoadsModal")}
                isOpen={show}
            >
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Кількість годин
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({numberHours: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.numberHours}
                                    min={0}
                                    type="number"
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Тип дисципліни
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={lessonTypes}
                                    onChange={(selectedValue) => {
                                        this.setState({
                                            disciplineType: selectedValue,
                                        })
                                    }}
                                    defaultValue={this.state.disciplineType}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Вкажіть тип заняття"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Наскрізний семестер
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={getSemesters()}
                                    onChange={(selectedValue) => {
                                        this.setState({
                                            semester: selectedValue,
                                        })
                                    }}
                                    defaultValue={this.state.semester}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Вкажіть наскрізний семестр"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Викладач
                            </Table.Cell>
                            <Table.Cell>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={this.loadOptions}
                                    noOptionsMessage={NoOptionsMessage}
                                    loadingMessage={loadingMessage}
                                    isClearable={false}
                                    defaultValue={this.state.teacher}
                                    onChange={(selectedValue) => this.setState({teacher: selectedValue})}
                                    placeholder={"Введіть ПІП викладача"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Група/Потік
                            </Table.Cell>
                            <Table.Cell>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={this.loadUniversityGroupsOptions}
                                    noOptionsMessage={NoOptionsMessage}
                                    loadingMessage={loadingMessage}
                                    isClearable={false}
                                    defaultValue={this.state.groups}
                                    onChange={(selectedValue) => this.setState({groups: selectedValue})}
                                    placeholder={"Введіть назву групи/потіку"}

                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Заміна
                            </Table.Cell>
                            <Table.Cell>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={this.loadOptions}
                                    noOptionsMessage={NoOptionsMessage}
                                    loadingMessage={loadingMessage}
                                    isClearable={false}
                                    defaultValue={this.state.teacherReplacement}
                                    onChange={(selectedValue) => this.setState({teacherReplacement: selectedValue})}
                                    placeholder={"Введіть ПІП заміни викладача"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Кафедра на яку надсилаєтсья заявка
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={departments}
                                    onChange={(selectedValue) => {
                                        this.setState({
                                            department: selectedValue,
                                        })
                                    }}
                                    defaultValue={this.state.department}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Виберіть кафедру"}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        lessonTypes: state.get("lessonTypes"),
        departments: state.get("data4Select").get("departments"),
    }
}

export default compose(
    connectModal({
        name: 'teacherLoadsModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editTeacherLoadInfo,
        insertTeacherLoadItem
    }))(TeacherLoadsModal);

