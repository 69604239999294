import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import {compose} from "redux";
import {connect} from "react-redux";
import {insertLessonAudience} from "../../../actions";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {writeErrors} from "../../../../../utils/logs";

class TeacherProposalsModal extends PureComponent {
    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={<div><h4>Пропозиції викладача</h4></div>}
                width={"90vw"}
                isOpen={show}
                onClose={() => {
                    this.props.hide("lesson_teacherProposalsModal");
                }}
            >
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Викладач</Table.HeaderCell>
                            <Table.HeaderCell>Подія</Table.HeaderCell>
                            <Table.HeaderCell>№ пари</Table.HeaderCell>
                            <Table.HeaderCell>Початок</Table.HeaderCell>
                            <Table.HeaderCell>Кінець</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>

                        {this.props.teacherProposals.map(teacherProposal => {
                            return (<Table.Row key={teacherProposal.id}>
                                <Table.Cell>
                                    {teacherProposal.user}
                                </Table.Cell>
                                <Table.Cell>
                                    {teacherProposal.title}
                                </Table.Cell>
                                <Table.Cell>
                                    {teacherProposal.lessonNumbers}
                                </Table.Cell>
                                <Table.Cell>
                                    {teacherProposal.start}
                                </Table.Cell>
                                <Table.Cell>
                                    {teacherProposal.end}
                                </Table.Cell>
                            </Table.Row>)
                        })}
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        teacherProposals: state.get("lessons").get("teacherProposals"),

    }
}

export default compose(
    connectModal({
        name: 'lesson_teacherProposalsModal',
        getModalState: (state) => state.get("modal")
    }), connect(mapStateToProps, {
        insertLessonAudience,
        hide
    })
)(TeacherProposalsModal);