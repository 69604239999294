import React, {PureComponent} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Settings from "./components/Setting";
import Backups from "./components/Backups";
import Logging from "./components/Logging";
import Users from "./components/Users";
import {fetchBackups, fetchLogs, fetchOptions} from "./actions";
import {connect} from "react-redux";

class Options extends PureComponent {


    componentDidMount() {
        this.props.fetchLogs(new Date());
        this.props.fetchOptions();
        this.props.fetchBackups();
    }


    render() {
        return (
            <Tabs>
                <TabList>
                    <Tab>Журнал дій користувачів</Tab>
                    <Tab>Користувачі</Tab>
                    <Tab>Бекапи</Tab>
                    <Tab>Налаштування</Tab>
                </TabList>

                <TabPanel>
                    <Logging/>
                </TabPanel>
                <TabPanel>
                    <Users/>
                </TabPanel>
                <TabPanel>
                    <Backups/>
                </TabPanel>
                <TabPanel>
                    <Settings/>
                </TabPanel>
            </Tabs>
        );
    }

}

export default connect(null, {
    fetchBackups,
    fetchOptions,
    fetchLogs
})(Options)