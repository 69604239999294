import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editStudyWorkPlanInfo, insertStudyWorkPlanItem} from "../../actions";
import {loadingMessage, NoOptionsMessage} from "../../../../../utils";
import Select from "react-select";
import AsyncSelect from 'react-select/lib/Async';
import {fetchDisciplines4Select} from "../../../../../components/Data4Select/actions";

class WorkPlanModal extends PureComponent {
    handleSave = () => {
        const object = {
            disciplineId: this.state.discipline?this.state.discipline.value:'',
            hoursLectures: this.state.hoursLectures,
            consultation: this.state.consultation,
            disciplineCycleId: this.state.disciplineCycle?this.state.disciplineCycle.value:'',
            hoursPractical: this.state.hoursPractical,
            hoursIndividualWork: this.state.hoursIndividualWork,
            numberCredits: this.state.numberCredits,
            id: this.state.id,
        };
        if (this.state.isCreate) {
            this.props.insertStudyWorkPlanItem(object);
        } else {
            this.props.editStudyWorkPlanInfo(object);
        }

        this.props.hide("workPlanModal")
    };
    loadOptions = inputValue => fetchDisciplines4Select(inputValue);

    constructor(props) {
        super(props);
        const row = props.row._original || {};
        this.state = {
            id: row.id || null,
            isCreate: props.row._original === undefined,
            discipline: row.disciplineId ? {
                value: row.disciplineId,
                label: row.discipline
            } : null,
            consultation: row.consultation || 0,
            hoursLectures: row.hoursLectures || 0,
            disciplineCycle: row.disciplineCycleId ? {
                value: row.disciplineCycleId,
                label: row.disciplineCycle
            } : null,
            hoursPractical: row.hoursPractical || 0,
            hoursIndividualWork: row.hoursIndividualWork || 0,
            numberCredits: row.numberCredits || 0,
        }
    }

    render() {
        const {show} = this.props;
        const cycles = this.props.disciplinesCycles.toArray();

        return (
            <PureModal
                header={"Створення та редагування інформації по запису робочого навчального плану"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("workPlanModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                onClose={() => this.props.hide("teacherLoadsModal")}
                isOpen={show}
            >
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Дисципліна
                            </Table.Cell>
                            <Table.Cell>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={this.loadOptions}
                                    noOptionsMessage={NoOptionsMessage}
                                    loadingMessage={loadingMessage}
                                    isClearable={false}
                                    defaultValue={this.state.discipline}
                                    onChange={(selectedValue) => this.setState({discipline: selectedValue})}
                                    placeholder={"Виберіть предмет"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Годин лекцій
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({hoursLectures: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.hoursLectures}
                                    min={0}
                                    type="number"
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Годин практичних занять
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    className={"form-control"}
                                    value={this.state.hoursPractical}
                                    onChange={e => this.setState({hoursPractical: e.target.value})}
                                    min={0}
                                    type="number"
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Годин самостійної роботи
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({hoursIndividualWork: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.hoursIndividualWork}
                                    min={0}
                                    type="number"
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Годин консультацій
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    className={"form-control"}
                                    onChange={e => this.setState({consultation: e.target.value})}
                                    value={this.state.consultation}
                                    min={0}
                                    type="number"
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Цикл дисципліни
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={cycles}
                                    onChange={(selectedValue) => {
                                        this.setState({
                                            disciplineCycle: selectedValue,
                                        })
                                    }}
                                    defaultValue={this.state.disciplineCycle}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Вкажіть цикл"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Кількість кредитів
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    className={"form-control"}
                                    min={0}
                                    value={this.state.numberCredits}
                                    type="number"
                                    onChange={e => this.setState({numberCredits: e.target.value})}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        disciplinesCycles: state.get("data4Select").get("disciplinesCycles"),
    }
}

export default compose(
    connectModal({
        name: 'workPlanModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editStudyWorkPlanInfo,
        insertStudyWorkPlanItem
    }))(WorkPlanModal);

