import React, {Component} from "react";
import {deleteUniversityGroupsFlow, fetchUniversityGroupsFlow,} from "./actions";
import {connect} from "react-redux";
import Loader from "semantic-ui-react/dist/es/elements/Loader/Loader";
import {fetchUniversityGroups4Select} from "../../../components/Data4Select/actions";
import {writeErrors} from "../../../utils/logs";
import {loadingText, nextText, noDataText, ofText, pageText, previousText, rowsText} from "../../../utils/locale";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import FlowsModal from "./components/FlowsModal";
import {show} from "redux-modal";

class UniversityFlows extends Component {

    componentDidMount() {
        this.props.fetchUniversityGroups4Select();
        this.props.fetchUniversityGroupsFlow();
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    handleAddUniversityFlow() {
        this.props.show("flowsModal", {isCreate: true});
    }

    handleEditUniversityFlow(row) {
        this.props.show("flowsModal", {row, isCreate: false});
    }

    handleDeleteUniversityFlow(id) {
        this.props.deleteUniversityGroupsFlow(id);
    }

    render() {
        if (this.props.groups.size < 1) {
            return (<Loader active size={"massive"} content='Завантаження університетських потоків'/>)
        }
        return (<div>
            <FlowsModal/>
            <ReactTable
                data={this.props.flows.toArray()}
                filterable
                columns={[
                    {
                        Header: "Назва",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["name"]}),
                        filterAll: true,
                        accessor: "name",
                    }, {
                        Header: "Групи",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["labels"]}),
                        filterAll: true,
                        accessor: "labels",
                        className:"text-center"
                    },
                    {

                        Header: <Popup
                            trigger={
                                <Icon name='add' size={"big"}
                                      color={"green"}
                                      onClick={
                                          this.handleAddUniversityFlow.bind(this)}/>
                            }
                            content='Додати запис'
                            on={'hover'}
                            style={{zIndex: 3001}}
                        />,
                        accessor: "id",
                        width: 100,
                        Cell: ({row}) => {
                            return (<div style={{marginTop: "15px"}}>

                                <Popup
                                    trigger={<Icon name={"edit"}
                                                   color={"blue"}
                                                   onClick={this.handleEditUniversityFlow.bind(this, row)}
                                    />}
                                    content="Редагувати інформацію"
                                    hideOnScroll
                                    on='hover'
                                />
                                <Popup
                                    trigger={<Icon name={"trash"}
                                                   color={"red"}
                                                   onClick={this.handleDeleteUniversityFlow.bind(this, row._original.id)}
                                    />}
                                    content="Видалити запис"
                                    hideOnScroll
                                    on='hover'
                                />
                            </div>)
                        },
                        sortable: false,
                        filterable: false,
                        style: {
                            cursor: "pointer",
                            fontSize: 25,
                            padding: "0",
                            textAlign: "center",
                            userSelect: "none"
                        }
                    },
                ]}
                defaultPageSize={10}
                style={{
                    height: "80vh",
                }}
                className="table-bordered table-striped white-bg"
                noDataText={noDataText}
                previousText={previousText}
                nextText={nextText}
                loadingText={loadingText}
                pageText={pageText}
                ofText={ofText}
                rowsText={rowsText}
            />
        </div>);

    }
}

function mapStateToProps(state) {
    return {
        groups: state.get("data4Select").get("groups"),
        flows: state.get("flows"),
    }
}

export default connect(mapStateToProps, {
    fetchUniversityGroups4Select,
    fetchUniversityGroupsFlow,
    show,
    deleteUniversityGroupsFlow
})(UniversityFlows)

