import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {DragSource} from 'react-dnd'
import {ContextMenuTrigger} from "react-contextmenu";
import {compose} from "redux";
import {connect} from "react-redux";
import {show} from "redux-modal";
import {
    audienceCoincidences,
    compCoincidences,
    fetchOnlyLessons,
    projectorCoincidences,
    validateAudiences
} from "../../../actions";

class Lesson extends PureComponent {
    static propTypes = {
        connectDragSource: PropTypes.func.isRequired,
        isDragging: PropTypes.bool.isRequired,
        lesson: PropTypes.object.isRequired
    };


    render() {
        const {lesson, connectDragSource, canDrop} = this.props;
        const style = {};
        if (canDrop) {
            if (lesson.isExtraLesson) {
                style.backgroundColor = '#FFFF7F';
            } else if (lesson.audience.length === 0) {
                style.backgroundColor = "#B0B0B0";
            } else {
                style.backgroundColor = lesson.color;
            }
        }

        return connectDragSource(
            <div
                onDoubleClick={() => {
                    this.props.audienceCoincidences({
                        id: lesson.id,
                        facultyId: "test"
                    });
                    this.props.compCoincidences({id: lesson.id});
                    this.props.projectorCoincidences({id: lesson.id});
                    this.props.validateAudiences(lesson.id);
                    this.props.show("audiencesModal", {id: lesson.id, lesson})
                }}
                className={'text-center lesson-border'}
                key={lesson.id}
                style={style}>
                <ContextMenuTrigger holdToDisplay={-1} id="lesson_contextMenu" {...this.props}
                                    collect={(props) => props}>
                    <span>{lesson.disciplineShortName}</span>
                    <span> {lesson.lessonType}</span>
                    <span> {lesson.groups}</span>
                    <span> {lesson.number}</span>
                    <span>{lesson.maxNumber}</span>
                    <span> {lesson.teacher}</span>
                    <span>{lesson.audience}</span>
                </ContextMenuTrigger>
            </div>
        );
    }
}

export default compose(
    DragSource('lesson', {
        beginDrag(props) {
            props.validateLessons({id: props.lesson.id});
            return props.lesson;
        },
        endDrag(props, monitor) {
            // edit Lesson
            const week = localStorage.getItem('OA.UM-Week');
            const lesson = monitor.getItem();
            const dropResult = monitor.getDropResult();
            if (dropResult && typeof dropResult.editLesson === "function" && dropResult.editLesson) {
                const {rowIdx, colIdx} = dropResult;
                dropResult.editLesson({rowIdx, colIdx, id: lesson.id, week});
            } else {
                props.fetchOnlyLessons();
            }


        },
        canDrag(props) {
            return props.lesson.canDrop;
        }
    }, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    })),
    connect(null, {
        validateAudiences,
        show,
        audienceCoincidences,
        compCoincidences,
        projectorCoincidences,
        fetchOnlyLessons,
    })
)(Lesson);
