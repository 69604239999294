import axios from "axios";
import {DELETE_DISCIPLINE, EDIT_DISCIPLINE_INFO, FETCH_DISCIPLINES, INSERT_DISCIPLINE} from "./types";
import {ROOT_URL} from "../../../utils";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchDisciplines() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/disciplines`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_DISCIPLINES, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }
}



export function editDisciplinesInfo(discipline) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/university/disciplines/edit`, discipline, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_DISCIPLINE_INFO, payload: discipline})
        }).catch((error) => {
            writeErrors(error);
        });
    }
}


export function insertDiscipline(discipline) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/university/discipline/create`,discipline, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_DISCIPLINE, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteDiscipline( id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/university/discipline/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_DISCIPLINE, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}



