import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {
    COPY_STUDY_WORK_PLAN_ITEM,
    COPY_TEACHER_LOAD_ITEM,
    DELETE_TEACHER_LOAD_ITEM,
    DELETE_WORK_PLAN_ITEM,
    EDIT_STUDY_WORK_PLAN_INFO,
    EDIT_TEACHER_LOAD_INFO,
    FETCH_STUDY_WORK_PLANS_WITH_TEACHER_LOADS,
    FETCH_STUDY_WORK_PLANS_WITH_TEACHER_LOADS_BY_SEMESTER,
    INSERT_STUDY_WORK_PLAN_ITEM,
    INSERT_TEACHER_LOAD_ITEM,
    TOGGLE_REQUEST_STATUS,
} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchStudyWorkPlansWithTeacherLoads() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/documents/studyWorkPlanWithLoads`, {

        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_STUDY_WORK_PLANS_WITH_TEACHER_LOADS, payload: data})
        });
    }
}

export function fetchStudyWorkPlansWithTeacherLoadsBySemester(semester) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/documents/studyWorkPlanWithLoadsBySemester`, {
        params: {semester},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_STUDY_WORK_PLANS_WITH_TEACHER_LOADS_BY_SEMESTER, payload: data})
        });
    }
}


export function insertStudyWorkPlanItem(plan) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/documents/createPlanItem`, plan, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_STUDY_WORK_PLAN_ITEM, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function insertTeacherLoadItem(teacherLoad) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/documents/createLoadItem`, teacherLoad, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_TEACHER_LOAD_ITEM, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteStudyWorkPlanItem(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/documents/deletePlanItem`, {
        params: {id},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_WORK_PLAN_ITEM, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteTeacherLoadItem(id, planId) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/documents/deleteLoadItem`, {
        params: {id},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: DELETE_TEACHER_LOAD_ITEM, payload: data, id: planId})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function editStudyWorkPlanInfo(studyWorkPlan) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/documents/edit-studyWorkPlan`, studyWorkPlan, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_STUDY_WORK_PLAN_INFO, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function editTeacherLoadInfo(teacherLoad) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/documents/edit-teacherLoad`, teacherLoad, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_TEACHER_LOAD_INFO, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function copyStudyWorkPlanRow(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/documents/copyStudyWorkPlanItem`, null, {
        params: {id},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: COPY_STUDY_WORK_PLAN_ITEM, payload: data, id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function copyTeacherLoadRow(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/documents/copyTeacherLoadItem`, null, {
        params: {id}, headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: COPY_TEACHER_LOAD_ITEM, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function toggleRequestStatus(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/documents/toggleRequestStatus`, null, {
        params: {id}, headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: TOGGLE_REQUEST_STATUS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}



