import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {getDefaultOption, getDefaultValue, getOptions, NoOptionsMessage} from "../../../../../utils";
import Select from 'react-select'
import {connect} from "react-redux";
import {editAudiencesInfo, insertAudience} from "../../actions";
import {compose} from "redux";

class FacultiesModal extends PureComponent {


    handleSave = () => {
        const object = {
            id: this.state.id,
            name: this.state.name,
            facultyId: this.state.facultyId.id,
            facultyValue: this.state.facultyValue,
            countSeats: this.state.countSeats,
            isArchived: this.state.isArchived.value,
            hasProjector: this.state.hasProjector.value,
            isCompAudience: this.state.isCompAudience.value,
        };
        if (this.state.isCreate) {
            this.props.insertAudience(object);
        } else {
            this.props.editAudiencesInfo(object);
        }

        this.props.hide("audienceModal")
    };

    constructor(props) {
        super(props);
        let row = {};
        if (props.isCreate) {
            row = {};
        } else {
            row = props.row._original
        }
        const defaultValue = getDefaultValue();
        this.state = {
            id: row.id || "",
            isCreate: props.isCreate,
            name: row.name || "",
            facultyId: row.facultyId ? {
                id: row.facultyId,
                shortName: row.facultyValue
            } : props.faculties.get(props.faculties.size - 1),
            facultyValue: row.facultyValue || "",
            countSeats: row.countSeats || 0,
            isCompAudience: row.isCompAudience ? {value: row.isCompAudience, label: row.isCompAudience} : defaultValue,
            hasProjector: row.hasProjector ? {value: row.hasProjector, label: row.hasProjector} : defaultValue,
            isArchived: row.isArchived ? {value: row.isArchived, label: row.isArchived} : defaultValue,

        }
    }

    render() {
        const {show} = this.props;
        const options = getOptions();
        const faculties = this.props.faculties.toArray();
        return (
            <PureModal
                header={"Створення та редагування інформації про аудиторію"}
                width={"80vw"}
                footer={
                    <div className="float-right">
                        <Button color={"red"} icon onClick={e =>
                            this.props.hide("audienceModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
                onClose={() => {
                    this.props.hide("audienceModal");
                }}
            >
                <div>
                    <Table celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell className={"w-30"}>
                                    Назва аудиторії
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({name: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.name}
                                        placeholder={"Введіть назву аудиторії"}
                                        required/>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Кількість місць в аудиторії
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => {
                                            this.setState({countSeats: e.target.value})
                                        }}
                                        className={"form-control"}
                                        type={"number"}
                                        min={0}
                                        value={this.state.countSeats}
                                        placeholder={"Введіть кількість місць в аудиторії"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Факультет
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={faculties}
                                        onChange={(selectedValue) => {
                                            this.setState({
                                                facultyId: selectedValue || getDefaultOption(),
                                                facultyValue: selectedValue.shortName || null
                                            })
                                        }}
                                        getOptionLabel={(option) => option.shortName}
                                        getOptionValue={(option) => option.id}
                                        defaultValue={this.state.facultyId}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Вкажіть факультет"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Аудиторія є комп'ютерним класом
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={options}
                                        onChange={(selected) => {
                                            this.setState({
                                                isCompAudience: selected || getDefaultValue()
                                            })
                                        }}
                                        defaultValue={this.state.isCompAudience}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Зазначте чи є аудиторія комп'ютерним класом"}

                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Аудиторія має проектор
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={options}
                                        onChange={(selected) => {
                                            this.setState({
                                                hasProjector: selected || getDefaultValue()
                                            })
                                        }}
                                        defaultValue={this.state.hasProjector}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Зазначте наявність в аудиторії проектору"}
                                    />

                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    В архів
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={options}
                                        onChange={(selected) => {
                                            this.setState({
                                                isArchived: selected || getDefaultValue()
                                            })
                                        }}
                                        defaultValue={this.state.isArchived}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Перемістити запис в архів"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>

            </PureModal>
        )
            ;
    }
}

function mapStateToProps(state) {
    return {
        faculties: state.get("data4Select").get("facultiesWithDefault"),

    }
}

export default compose(
    connectModal({
        name: 'audienceModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        insertAudience,
        editAudiencesInfo,
    }))(FacultiesModal);

