import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {editLesson, fetchOnlyLessons, validateLessons} from "../../actions";
import Cell from "../Target/Cell";
import PropTypes from "prop-types";
import {DAYS} from "../../../../utils";

class LessonsTable extends PureComponent {
    static propTypes = {
        showZeroRow: PropTypes.bool.isRequired,
        showMore5Rows: PropTypes.bool.isRequired,
    };
    state = {
        days: DAYS,
    };


    renderHeader = (numberCols) => {
        let days = [];
        for (let i = 0; i < numberCols; i++) {
            days.push(<th key={`day_${i}`}>
                {this.state.days[i]}
            </th>)
        }
        return days;
    };


    renderRows = (numberColumns, numberRows, startNumber) => {
        let rows = [];
        for (let i = startNumber; i < numberRows + startNumber; i++) {
            if (i === startNumber) {
                rows.push(
                    <tr key={`row+${i}`}>
                        <td className={"mark"}>{i}</td>
                        {this.renderCells(numberColumns, i)}
                    </tr>
                );
            } else {
                rows.push(
                    <tr key={`row+${i}`}>
                        <td className={"mark"}>{i}</td>
                        {this.renderCells(numberColumns, i)}
                    </tr>
                )
            }

        }
        return rows;
    };

    renderCells = (numberColumns, i) => {
        let rows = [];
        for (let j = 0; j < numberColumns - 1; j++) {

            rows.push(
                <Cell
                    lessons={this.props.lessons.filter(lesson => {
                        return lesson.rowIdx === i && (lesson.colIdx - 1) === j;
                    })}
                    rowIdx={i}
                    colIdx={j}
                    validateLessons={this.props.validateLessons}
                    editLesson={this.props.editLesson}
                    fetchOnlyLessons={this.props.fetchOnlyLessons}
                    key={`cell_${j}`}/>
            )

        }
        return rows;
    };

    render() {
        const {showSunday, showSaturday, showMore5Rows, showZeroRow} = this.props;
        const numberCols = showSunday ? 8 : showSaturday ? 7 : 6;
        return (
            <div style={{overflow: "auto", maxHeight: "60vh"}}>
                <table className={"w-100 mt-1 lessons table-bordered table-striped text-center"}>
                    <thead>
                    <tr className={"mark"}>
                        {this.renderHeader(numberCols)}
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderRows(numberCols,
                        showMore5Rows ? showZeroRow ? 9 : 8 : showZeroRow ? 5 : 4,
                        showZeroRow ? 0 : 1)}
                    </tbody>
                </table>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        lessons: state.get("lessons").get("lessons"),
        countCols: state.get("lessons").get("countCols"),
        countRows: state.get("lessons").get("countRows"),
        showMore5Rows: state.get("lessons").get("showMore5Rows"),
        showSaturday: state.get("lessons").get("showSaturday"),
        showSunday: state.get("lessons").get("showSunday"),
        showZeroRow: state.get("lessons").get("showZeroRow"),
    }
}

export default connect(mapStateToProps, {
    validateLessons,
    editLesson,
    fetchOnlyLessons,
})(LessonsTable);
