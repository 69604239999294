import {List} from "immutable"
import {CLEAR_BACKUPS, FETCH_BACKUPS} from "../../types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_BACKUPS:
            return List(action.payload);
        case CLEAR_BACKUPS:
            return List(action.payload);
        default:
            return state;
    }
}