import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {DELETE_FACULTY, EDIT_FACULTY_INFO, FETCH_FACULTIES, INSERT_FACULTY} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchFaculties() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/faculties`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request
            .then(({data}) => {
                dispatch({type: FETCH_FACULTIES, payload: data})

            }).catch((error) => {
            writeErrors(error);
        });
    }

}



export function editFacultiesInfo(faculty) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/university/faculties/edit`, faculty, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_FACULTY_INFO, payload: faculty})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertFaculty(faculty) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/university/faculty/create`, faculty, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_FACULTY, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteFaculty(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/university/faculty/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_FACULTY, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


