import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {DropTarget} from 'react-dnd'
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";

class Trash extends PureComponent {
    static propTypes = {
        connectDropTarget: PropTypes.func.isRequired,
        isOver: PropTypes.bool.isRequired,
        canDrop: PropTypes.bool.isRequired
    };
    static defaultProps = {
        isOver: false,
        canDrop: false
    };


    render() {
        const {isOver, canDrop = false, connectDropTarget} = this.props;
        let isActive = canDrop;
        if (canDrop) {
            isActive = canDrop && isOver;
        }
        const hover = isActive ? "hover" : "";
        return connectDropTarget(
            <div className={`mt-1 w-100 text-center c-trash ${hover}`}>
                <Icon name={"trash"} size={"large"}
                      color={"red"}>Кошик</Icon>
            </div>
        )
    }
}

export default DropTarget('lesson', {
    drop(props, monitor) {
        const lesson = monitor.getItem();
        if (!lesson.isTeacherLoad) {
            props.deleteLesson({id: lesson.id}, lesson.teacherLoadId);
        }
        return props;
    },
    canDrop(props, monitor) {
        const lesson = monitor.getItem();
        if (lesson) {
            return lesson.teacherLoadId && lesson.teacherLoadId.length > 0;
        }
        return false;
    }

}, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
}))(Trash);