import React, {Component} from "react";
import {connect} from "react-redux";
import {deleteStudyYear, editStudyYearsInfo, fetchStudyYears, insertStudyYear} from "./actions";
import {deleteSemester, editSemesterInfo, insertSemester} from "../Semesters/actions";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import DatePicker from "react-date-picker";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {writeErrors} from "../../../utils/logs";
import "./styles.css";

class StudyYears extends Component {
    componentDidMount() {
        this.props.fetchStudyYears();
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    handleAddStudyYear() {
        this.props.insertStudyYear();
    }

    handleAddSemester() {
        this.props.insertSemester();
    }

    handleStudyYearDateChange(id, name, date) {
        this.props.editStudyYearsInfo({
            key: `${name}#${id}`,
            value: date
        })
    }

    handleSemesterDateChange(id, name, date) {
        this.props.editSemesterInfo({
            key: `${name}#${id}`,
            value: date
        })
    }

    handleDeleteSemester(e, {id}) {
        this.props.deleteSemester(id)
    }

    renderStudyYears() {
        let rows = [];
        this.props.studyYears.forEach((studyYear, i) => {
            rows.push(
                <Table.Row key={studyYear.id}>
                    <Table.Cell className={"w-3"}>{i + 1}</Table.Cell>
                    <Table.Cell className="text-center">
                        <DatePicker
                            onChange={this.handleStudyYearDateChange.bind(this, studyYear.id, "startStudyYear")}
                            locale={"uk-UA"}
                            value={new Date(studyYear.startStudyYear)}
                        />
                    </Table.Cell>
                    <Table.Cell className="text-center">
                        <DatePicker
                            onChange={this.handleStudyYearDateChange.bind(this, studyYear.id, "endStudyYear")}
                            locale={"uk-UA"}
                            value={new Date(studyYear.endStudyYear)}
                        />

                    </Table.Cell>
                    <Table.Cell>
                        <Popup
                            trigger={<Button
                                onClick={this.handleAddSemester.bind(this)}
                                icon='add'
                                size={"small"}
                                color="olive"/>}
                            content="Додати семестр"
                            hideOnScroll
                            on='hover'
                        />
                    </Table.Cell>
                </Table.Row>
            );
            rows.push(this.renderSemesters(studyYear.semesters));
        });
        return rows;
    }

    renderSemesters(semesters) {
        return semesters.map(semester => {
            return (
                <Table.Row key={semester.id}>
                    <Table.Cell>
                        <Popup trigger={<div>
                            <Icon name={"arrow right"} size={"big"}/>
                        </div>} content='Семестр'/>
                    </Table.Cell>
                    <Table.Cell className="text-center">
                        <DatePicker
                            onChange={this.handleSemesterDateChange.bind(this, semester.id, "startSemester")}
                            locale={"uk-UA"}
                            value={new Date(semester.startSemester)}
                        />
                    </Table.Cell>
                    <Table.Cell className="text-center">
                        <div>
                            <DatePicker
                                onChange={this.handleSemesterDateChange.bind(this, semester.id, "endSemester")}
                                locale={"uk-UA"}
                                value={new Date(semester.endSemester)}
                            />
                        </div>
                    </Table.Cell>
                    <Table.Cell>
                        <Popup
                            trigger={<Button
                                onClick={this.handleDeleteSemester.bind(this)}
                                icon='trash'
                                id={semester.id}
                                size={"small"}
                                color="red"/>}
                            content="Видалити семестр"
                            hideOnScroll
                            on='hover'
                        />
                    </Table.Cell>
                </Table.Row>)
        })
    }

    render() {
        return (
            <div style={{"overflow": "auto", height: "80vh"}}>
                <Table celled textAlign={"center"} compact={"very"} verticalAlign={"middle"}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>№</Table.HeaderCell>
                            <Table.HeaderCell>Початок навчального року/семетру</Table.HeaderCell>
                            <Table.HeaderCell>Кінець навчального року/семетру</Table.HeaderCell>
                            <Table.HeaderCell className={"w-5"}>
                                <Popup
                                    trigger={<Button
                                        onClick={this.handleAddStudyYear.bind(this)}
                                        icon='add'
                                        size={"small"}
                                        color="green"/>}
                                    content="Додати новий навчальний рік"
                                    hideOnScroll
                                    on='hover'
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.renderStudyYears()}
                    </Table.Body>
                </Table>
            </div>);
    }


}

function mapStateToProps(state) {
    return {
        studyYears: state.get("studyYears"),
    }
}

export default connect(mapStateToProps, {
    fetchStudyYears,
    insertStudyYear,
    editStudyYearsInfo,
    deleteStudyYear,
    insertSemester,
    editSemesterInfo,
    deleteSemester
})(StudyYears)
