import {List} from "immutable"
import {
    DELETE_CALENDAR_PLAN_ITEM,
    EDIT_CALENDAR_PLAN_INFO,
    FETCH_CALENDAR_PLAN,
    INSERT_CALENDAR_PLAN_ITEM,
} from "./types"

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_CALENDAR_PLAN:
            return List(action.payload);
        case EDIT_CALENDAR_PLAN_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case INSERT_CALENDAR_PLAN_ITEM:
            return state.insert(0, action.payload);
        case DELETE_CALENDAR_PLAN_ITEM:
            return state.delete(state.findIndex(i => i.id === action.payload));
        default:
            return state;
    }
}