import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editTeacherLoadColumnInfo, insertTeacherLoadColumn} from "../../actions";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import {getDefaultValue, getOptions, NoOptionsMessage} from "../../../../../utils";
import Select from "react-select";

class TableColumnModal extends PureComponent {

    getColumnType = () => {
        return [
            {value: 'Навантаження', label: 'Навантаження',},
            {value: 'Навчальний план', label: 'Навчальний план',},
        ];
    };
    handleSave = () => {
        const object = {
            id: this.state.id,
            name: this.state.name,
            key: this.state.key,
            shortName: this.state.shortName,
            departmentValue: this.state.departmentValue,
            columnType: this.state.columnType.value,
            columnNumber: this.state.columnNumber,
            isArchived: this.state.isArchived.value,
            isCalculated: this.state.isCalculated.value,
        };
        if (this.state.isCreate) {
            this.props.insertTeacherLoadColumn(object);
        } else {
            this.props.editTeacherLoadColumnInfo(object);
        }

        this.props.hide("tableColumnModal")
    };

    constructor(props) {
        super(props);
        let row = {};
        if (props.isCreate) {
            row = {};
        } else {
            row = props.row._original;
        }
        const defaultValue = getDefaultValue();
        this.state = {
            id: row.id || "",
            isCreate: props.isCreate,
            key: row.key || "",
            isCalculated: row.isCalculated ? {value: row.isCalculated, label: row.isCalculated} : defaultValue,
            columnNumber: row.columnNumber || 0,
            shortName: row.shortName || "",
            name: row.name || "",
            columnType: row.columnTypeId || this.getColumnType()[0],
            isArchived: row.isArchived ? {value: row.isArchived, label: row.isArchived} : defaultValue,

        }
    }

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Створення та редагування інформації про колонку"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("tableColumnModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave.bind(this)}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
                onClose={() => {
                    this.props.hide("tableColumnModal");
                }}
            >
                <div>
                    <Table celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <Popup
                                        trigger={<span>Ключ</span>}
                                        content="Унікальна назва колонки. На англійській та без пробілів!!!"
                                        hideOnScroll
                                        on={['hover']}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({key: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.key}
                                        placeholder={"Введіть назву ключ"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Назва
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({name: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.name}
                                        placeholder={"Введіть назву  назву колонки"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Скорочена назва
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({shortName: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.shortName}
                                        placeholder={"Введіть назву колонки"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    № колонки
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({columnNumber: e.target.value})}
                                        className={"form-control"}
                                        type={"number"}
                                        min={0}
                                        value={this.state.columnNumber}
                                        placeholder={"Введіть № колонки"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Сумується
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={getOptions()}
                                        onChange={(selected) => {
                                            this.setState({
                                                isCalculated: selected || getDefaultValue()
                                            })
                                        }}
                                        defaultValue={this.state.isCalculated}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Колонка враховується в навантаженні/навчальному плані "}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Тип колонки
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={this.getColumnType()}
                                        onChange={(selected) => {
                                            this.setState({
                                                columnType: selected || getDefaultValue()
                                            })
                                        }}
                                        defaultValue={this.state.columnType}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Вкажіть тип колонки"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    В архів
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={getOptions()}
                                        onChange={(selected) => {
                                            this.setState({
                                                isArchived: selected || getDefaultValue()
                                            })
                                        }}
                                        defaultValue={this.state.isArchived}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Перемістити запис в архів"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>

            </PureModal>
        )
            ;
    }
}

function mapStateToProps(state) {
    return {
        departments: state.get("departments"),
    }
}

export default compose(
    connectModal({
        name: 'tableColumnModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editTeacherLoadColumnInfo,
        insertTeacherLoadColumn,
    }))(TableColumnModal);

