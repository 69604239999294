import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {
    DELETE_PREFERENTIAL_CATEGORY,
    EDIT_PREFERENTIAL_CATEGORY_INFO,
    FETCH_PREFERENTIAL_CATEGORIES,
    INSERT_PREFERENTIAL_CATEGORY
} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchPreferentialCategories() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/student/preferentialCategories`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request
            .then(({data}) => {
                dispatch({type: FETCH_PREFERENTIAL_CATEGORIES, payload: data})

            }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function editPreferentialCategoryInfo(preferentialCategory) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/student/preferentialCategories/edit`, preferentialCategory, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_PREFERENTIAL_CATEGORY_INFO, payload: preferentialCategory})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertPreferentialCategory(preferentialCategory) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/student/preferentialCategory/create`, preferentialCategory, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_PREFERENTIAL_CATEGORY, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deletePreferentialCategory(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/student/preferentialCategory/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_PREFERENTIAL_CATEGORY, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


