import axios from "axios/index";
import {ROOT_URL} from "../../utils";
import Cookie from "js-cookie";
import {FETCH_ROLES} from "./types";
import {writeErrors} from "../../utils/logs";

export function fetchRoles() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/roles`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_ROLES, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}