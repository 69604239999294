import axios from "axios";
import {DELETE_STUDY_YEAR, EDIT_STUDY_YEAR_INFO, FETCH_STUDY_YEARS, INSERT_STUDY_YEAR} from "./types";
import {ROOT_URL} from "../../../utils";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchStudyYears() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/studyYears`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_STUDY_YEARS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}
export function fetchStudyYears4Select() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/studyYears4Select`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_STUDY_YEARS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function editStudyYearsInfo(studyYear) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/university/studyYears/edit`, studyYear, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_STUDY_YEAR_INFO, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertStudyYear() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/university/studyYear/create`,null, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_STUDY_YEAR, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteStudyYear(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/university/studyYear/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: DELETE_STUDY_YEAR, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }
}
