import axios from "axios/index";
import {ROOT_URL} from "../../utils";
import Cookie from "js-cookie";
import {
    FETCH_AUDIENCE_FOR_SELECT,
    FETCH_DEPARTMENTS_FOR_SELECT,
    FETCH_DISCIPLINES_CYCLES_FOR_SELECT,
    FETCH_DISCIPLINES_FOR_LESSONS,
    FETCH_FACULTIES_FOR_SELECT_WITH_DEFAULT,
    FETCH_FREE_AUDIENCES,
    FETCH_SPECIALITIES_FOR_SELECT,
    FETCH_SPECIALITIES_WITH_STUDY_LEVEL_FOR_SELECT,
    FETCH_STUDY_YEARS_FOR_SELECT,
    FETCH_TEACHER_STATUS,
    FETCH_TEACHERS_FOR_LESSONS,
    FETCH_UNIVERSITY_GROUPS_FOR_LESSONS,
    FETCH_UNIVERSITY_GROUPS_FOR_SELECT,
    FETCH_WEEKS_BY_SEMESTER,
    FETCH_WEEKS_FOR_SELECT
} from "./types";
import {writeErrors} from "../../utils/logs";


export function fetchDisciplines4Select(inputValue) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/disciplines4Select`, {
        params: {inputValue},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return request.then(({data}) => {
        return data;
    })

}

export function fetchTeachers4Select(inputValue) {
    let token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/teachers4Select`, {
        params: {inputValue},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return request.then(({data}) => {
        return data;
    })
}


export function fetchWeeks4Select() {
    let token = Cookie.get('OA.UM-Auth');
    if (token) {
        const request = axios.get(`${ROOT_URL}/v1/university/weeks4Select`, {
            headers: {"Authorization": `Bearer ${token}`}
        });
        return (dispatch) => {
            request.then(({data}) => {
                dispatch({type: FETCH_WEEKS_FOR_SELECT, payload: data});
                dispatch({type: FETCH_WEEKS_BY_SEMESTER, payload: data})
            }).catch((error) => {
                writeErrors(error);
            });
        }
    }
    return (dispatch) => {
        dispatch({type: FETCH_WEEKS_FOR_SELECT, payload: []});
        dispatch({type: FETCH_WEEKS_BY_SEMESTER, payload: []})

    }

}


export function fetchTeachers4Lessons() {
    const semesters = localStorage.getItem('OA.UM-semesters') !== null ? JSON.parse(localStorage.getItem('OA.UM-semesters')).map(i => i.value).join(",") : "";
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/teachers4Lessons`, {
        params: {semesters},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHERS_FOR_LESSONS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchSpecialities4SelectWithStudyLevel() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/specialities4SelectWithStudyLevel`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_SPECIALITIES_WITH_STUDY_LEVEL_FOR_SELECT, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchUniversityGroupsWithFlows(inputValue) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/groupsWithFlows`, {
        params: {inputValue},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return request.then(({data}) => {
        return data;
    });
}

export function fetchUniversityGroups4Select() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/groups4Select`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_UNIVERSITY_GROUPS_FOR_SELECT, payload: data})

        }).catch((error) => {
            writeErrors(error);
        });
    }
}

export function fetchUniversityGroups4Lessons() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/groups4Lessons`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_UNIVERSITY_GROUPS_FOR_LESSONS, payload: data})

        }).catch((error) => {
            writeErrors(error);
        });
    }
}


export function fetchDisciplines4Lessons() {
    const semesters = localStorage.getItem('OA.UM-semesters') !== null ? JSON.parse(localStorage.getItem('OA.UM-semesters')).map(i => i.value).join(",") : "";
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/disciplines4Lessons`, {
        params: {semesters},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_DISCIPLINES_FOR_LESSONS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }
}

export function fetchDepartments4Select() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/departments4Select`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_DEPARTMENTS_FOR_SELECT, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function fetchDisciplinesCycles4Select() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/disciplineCycles4Select`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_DISCIPLINES_CYCLES_FOR_SELECT, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function fetchFaculties4SelectWithDefault() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/facultiesWithDefault`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_FACULTIES_FOR_SELECT_WITH_DEFAULT, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchSpecialities4Select() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/specialities4Select`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_SPECIALITIES_FOR_SELECT, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function fetchAudiences4Select() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/audiences4Select`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_AUDIENCE_FOR_SELECT, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchFreeAudiences(model) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/freeAudiences`, {
        params: model,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_FREE_AUDIENCES, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }
}

export function fetchTeacherStatus() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/teachers/statuses4Select`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHER_STATUS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }
}

export function fetchStudyYears4Select() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/studyYears4Select`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_STUDY_YEARS_FOR_SELECT, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }
}

export function fetchWeeksBySemester(studyYearId) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/weeksBySemester`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {studyYear: studyYearId}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_WEEKS_BY_SEMESTER, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }
}

