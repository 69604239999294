import axios from "axios";
import {ROOT_URL} from "../../utils";
import Cookie from "js-cookie";
import {writeErrors} from "../../utils/logs";

const BOOKING_AUDIENCE = "BOOKING_AUDIENCE";
const REMOVE_BOOKING_AUDIENCE = "REMOVE_BOOKING_AUDIENCE";

export function bookingAudience(data) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/university/audience/booking`,data, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: BOOKING_AUDIENCE});
        }).catch((error) => {
            writeErrors(error);
        });
    }
}

export function removeBookingAudience(id) {

    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/university/audience/remove-booking`, {
        params:{id},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: REMOVE_BOOKING_AUDIENCE});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}