import React, {Component} from "react";
import {editStudentInfo} from "./actions";
import {connect} from "react-redux";
import ReactDataSheet from 'react-datasheet';
import PropTypes from "prop-types";
import 'react-datasheet/lib/react-datasheet.css';
import {writeErrors} from "../../../utils/logs";
import "./styles.css";

class Students extends Component {
    static  propTypes = {
        selectedGroup: PropTypes.string.isRequired
    };
    static defaultProps = {
        selectedGroup: ''
    };

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    render() {
        return (
            <ReactDataSheet
                data={this.props.students.toArray()}
                valueRenderer={(cell) => cell.value}
                className={"white-bg w-100"}
                onCellsChanged={
                    changes => {
                        const students = this.props.students.toArray();
                        const grid = students.map(row => [...row]);
                        changes.forEach(({cell, row, col, value}) => {
                            grid[row][col] = {...grid[row][col], value}
                        });
                        this.props.editStudentInfo(grid,
                            this.props.selectedGroup
                        );
                    }

                }
            />
        );
    }

}

function mapStateToProps(state) {
    return {
        students: state.get("students"),
    }
}

export default connect(mapStateToProps, {
    editStudentInfo,
})(Students)
