import React, {Component} from "react";
import {ContextMenu, MenuItem} from "react-contextmenu";
import {connect} from "react-redux";
import {show} from 'redux-modal';
import {fetchLessonNumbers, fetchLessonsTeacherProposals, fetchTeacherJournal} from "../../../actions";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {writeErrors} from "../../../../../utils/logs";

class TeachersLoadContextMenu extends Component {
    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }
    onShowLessonDetails(e, data) {
        this.props.fetchLessonNumbers({id: data.lesson.id});
        this.props.show("lessonDetailModal", {lesson: data.lesson});
    }

    onShowTeacherJournal(e, data) {
        const discipline = data.lesson.discipline;
        const id = data.lesson.id;
        this.props.fetchTeacherJournal(id);
        this.props.show("teacherJournalModal", {discipline});
    }
    onShowTeacherProposals(e, data) {
        const id = data.lesson.id;
        this.props.fetchLessonsTeacherProposals(id);
        this.props.show("lesson_teacherProposalsModal");
    }


    render() {
        return (
            <div>
                <ContextMenu id="teacherLoad_contextMenu">
                    <MenuItem onClick={this.onShowLessonDetails.bind(this)}>
                       <Icon name={"info"}/> Деталі
                    </MenuItem>
                    <MenuItem onClick={this.onShowTeacherJournal.bind(this)}>
                        <Icon name={"list"}/>  Журнал пар
                    </MenuItem>
                    <MenuItem onClick={this.onShowTeacherProposals.bind(this)}>
                        <Icon name={"user"} />   Пропозиції викладача
                    </MenuItem>
                </ContextMenu>
            </div>

        );
    }
}


export default connect(null, {
    show,
    fetchTeacherJournal,
    fetchLessonNumbers,
    fetchLessonsTeacherProposals,

})(TeachersLoadContextMenu);