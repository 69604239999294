import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteWeek, fetchWeeks } from "./actions";
import { show } from "redux-modal";
import WeekModal from "./components/WeekModal";
import EditWeekModal from "./components/EditWeekModal";
import { writeErrors } from "../../../utils/logs";
import { loadingText, nextText, noDataText, ofText, pageText, previousText, rowsText } from "../../../utils/locale";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";

class Weeks extends Component {
    componentDidMount() {
        this.props.fetchWeeks();
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({ error, errorInfo });
    }


    handleGenerateWeeks() {
        this.props.show("weekModal");
    }

    handleEditWeekInfo(row) {
        this.props.show("editWeekModal", { row });
    }

    handleDeleteWeek(id) {
        this.props.deleteWeek(id);
    }


    render() {
        return (
            <div>
                <WeekModal />
                <EditWeekModal />
                <ReactTable
                    data={this.props.rows.toArray()}
                    filterable
                    columns={[

                        {
                            Header: "№",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["lineNumber"] }),
                            filterAll: true,
                            accessor: "lineNumber",
                        }, {
                            Header: "Семестр",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["semester"] }),
                            filterAll: true,
                            accessor: "semester",
                        }, {
                            Header: "Початок тижня",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["startValue"] }),
                            filterAll: true,
                            sortMethod: (a, b) => new Date(b) - new Date(a),
                            accessor: "startValue",
                        },
                        {
                            Header: "Кінець тижня",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["endValue"] }),
                            filterAll: true,
                            sortMethod: (a, b) => new Date(b) - new Date(a),
                            accessor: "endValue",
                        },
                        {
                            Header: "Розклад затвержений",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, { keys: ["isApproved"] }),
                            filterAll: true,
                            accessor: "isApproved",
                        },
                        {

                            Header: <Popup
                                trigger={
                                    <Icon name='add' size={"big"}
                                        color={"green"}
                                        onClick={
                                            this.handleGenerateWeeks.bind(this)} />
                                }
                                content='Додати запис'
                                on={'hover'}
                                style={{ zIndex: 3001 }}
                            />,
                            accessor: "id",
                            width: 100,
                            Cell: ({ row }) => {
                                return (<div style={{ marginTop: "15px" }}>

                                    <Popup
                                        trigger={<Icon name={"edit"}
                                            color={"blue"}
                                            onClick={this.handleEditWeekInfo.bind(this, row)}
                                        />}
                                        content="Редагувати інформацію"
                                        hideOnScroll
                                        on='hover'
                                    />
                                    <Popup
                                        trigger={<Icon name={"trash"}
                                            color={"red"}
                                            onClick={this.handleDeleteWeek.bind(this, row._original.id)}
                                        />}
                                        content="Видалити запис"
                                        hideOnScroll
                                        on='hover'
                                    />
                                </div>)
                            },
                            sortable: false,
                            filterable: false,
                            style: {
                                cursor: "pointer",
                                fontSize: 25,
                                padding: "0",
                                textAlign: "center",
                                userSelect: "none"
                            }
                        },
                    ]}
                    defaultPageSize={10}
                    style={{
                        height: "80vh",
                    }}
                    className="table-bordered table-striped white-bg text-center"
                    noDataText={noDataText}
                    previousText={previousText}
                    nextText={nextText}
                    loadingText={loadingText}
                    pageText={pageText}
                    ofText={ofText}
                    rowsText={rowsText}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        rows: state.get("weeks"),
    }
}

export default connect(mapStateToProps, {
    deleteWeek,
    fetchWeeks,
    show,
})(Weeks)
