import React, {Component} from 'react';
import PropTypes from "prop-types";
import NotificationItem from './Item';
import Header from './header';
import Content from './content';
import Footer from './footer';
import {cutString} from './utils';
import {List} from "immutable"
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";

export default class ReactNotificationCenter extends Component {
    static propTypes = {
        notifications: PropTypes.oneOfType([
            PropTypes.instanceOf(Array),
            PropTypes.instanceOf(List)
        ]).isRequired,
        mapToItem: PropTypes.object,
        onItemClick: PropTypes.func,
        customItemComponent: PropTypes.func,
        onNotificationOpen: PropTypes.func,
        onNotificationClose: PropTypes.func,
        onScrollBottom: PropTypes.func,
        position: PropTypes.string,
        wordsInItem: PropTypes.number,
        noNotificationText: PropTypes.string
    };

    static defaultProps = {
        notificationTitle: 'React notification center',
        position: 'left',
        wordsInItem: 50,
        noNotificationText: 'No data available, enjoy your day',
        mapToItem: {},
        notifications: List
    };

    constructor(props) {
        super(props);
        this.state = {
            notifications: this.props.notifications,
            showNotification: false,
            showNotificationMessage: false,
            current: null
        };

        this.isChildOf = this.isChildOf.bind(this);
        this.mapOptions = this.mapOptions.bind(this);
        this.getUnreadLength = this.getUnreadLength.bind(this);
        this.toggleNotification = this.toggleNotification.bind(this);
        this.timeout = null;
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.notifications.size !== this.state.notifications.size) {
            if (this.refs.notificationIcon) {
                let classNames = this.refs.notificationIcon.className;
                this.refs.notificationIcon.className = classNames + ' pulse';
                this.timeout = setTimeout(() => {
                    let name = this.refs.notificationIcon.className;
                    this.refs.notificationIcon.className = name.substring(0, name.length - 6);
                }, 1200);
                this.setState({notifications: nextProps.notifications});
            }
        }
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    getUnreadLength() {
        if (List.isList(this.state.notifications)) {
            return this.state.notifications.filter(item => item[this.mapOptions().new]).size;
        }
        return this.state.notifications.filter(item => item[this.mapOptions().new]).length;

    }

    toggleNotification(e) {
        if (!this.state.showNotification) {
            this.setState({
                showNotification: true
            });
            if (this.props.onNotificationOpen) {
                this.props.onNotificationOpen(this.state.notifications);
            }
        } else if (this.state.showNotification && !this.isChildOf(e.target, this.refs.notificationHolder)) {
            this.setState({
                showNotification: false,
                showNotificationMessage: false,
                current: null
            });
            if (this.props.onNotificationClose) {
                this.props.onNotificationClose(this.state.notifications);
            }
        }
    }

    isChildOf(child, parent) {
        if (child.parentNode === parent) {
            return true;
        } else if (child.parentNode === null) {
            return false;
        } else {
            return this.isChildOf(child.parentNode, parent);
        }
    }

    mapOptions() {
        return {
            id: this.props.mapToItem.id || 'id',
            title: this.props.mapToItem.title || 'title',
            message: this.props.mapToItem.message || 'message',
            date: this.props.mapToItem.date || 'date',
            new: this.props.mapToItem.new || 'new'
        };
    }

    onItemClick(item) {
        this.setState({
            notifications: this.state.notifications.map(notification => {
                if (!notification[this.mapOptions().id]) {
                    return notification;
                }

                if (notification[this.mapOptions().id] === item[this.mapOptions().id]) {
                    notification[this.mapOptions().new] = false;
                }
                return notification;
            }),
            showNotificationMessage: true,
            current: item
        });
        if (this.props.onItemClick) {
            this.props.onItemClick(item, this.state.notifications);
        }
    }

    back() {
        this.setState({
            showNotificationMessage: false
        });
    }

    render() {
        const unreadLength = this.getUnreadLength();
        const opts = {};
        if (unreadLength) {
            opts['data-notifications'] = unreadLength;
        }
        return (
            <div className={`react-notification-center light-theme  ${this.props.visible ? "" : "hide" }`}>
                {/* <div onClick={this.toggleNotification}
                     className={`r-notifications-icon notification-badge  ${this.getUnreadLength() ? "active" : "" }`}
                     ref="notificationIcon" data-badge="6">
                        <span className={"notification-count"}>
                            {this.getUnreadLength() > 0 ? this.getUnreadLength() : ""}
                        </span>
                    <BellIcon width={40} height={30} active={true} animate={true}/>
                    <Icon className={" notification-count_size"} name={"alarm"} size={"big"}
                          color={this.getUnreadLength() > 0 ? "red" : "grey"}/>
                </div>*/}
                <div onClick={this.toggleNotification} className={unreadLength > 0 ? "bell-animation" : ""} {...opts}>
                    <Icon name={"alarm"} size={"big"}
                          color={unreadLength > 0 ? "red" : "grey"}/>
                </div>

                {this.state.showNotification &&
                <div className={`rr-wrapper ${this.props.position}`} ref="notificationHolder">
                    <div className="notification-holder">
                        <div className="r-notifications">
                            <Header>{cutString(this.props.notificationTitle, 30)}</Header>
                            <Content {...this.props}>
                                {this.state.notifications.size === 0 &&
                                <div className="no-rn">{this.props.noNotificationText}</div>
                                }
                                <ul className="rn-ul">
                                    {this.state.notifications.map((item, i) => {
                                        return (
                                            <NotificationItem
                                                key={i} onClick={this.onItemClick.bind(this, item)}
                                                options={this.mapOptions()}
                                                {...item}/>
                                        );
                                    })}
                                </ul>
                            </Content>
                            <Footer/>
                        </div>

                        <div className={`r-notification ${this.state.showNotificationMessage ? "active" : ""}`}>
                            <Header>{this.state.current && this.state.current[this.mapOptions().title]}</Header>
                            <Content {...this.props}>
                                <div className="desc">
                                    {this.state.current && this.state.current[this.mapOptions().message]}
                                </div>
                            </Content>
                            <Footer>
                                <button type="button" onClick={this.back.bind(this)}>
                                    <div className="back"/>
                                </button>
                            </Footer>
                        </div>
                    </div>
                </div>}
            </div>);
    }
}
