import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {deleteTeacherRates, fetchTeachersRates} from "./actions";
import {fetchTeacherStatus} from "../../../components/Data4Select/actions";
import {writeErrors} from "../../../utils/logs";
import {loadingText, nextText, noDataText, ofText, pageText, previousText, rowsText} from "../../../utils/locale";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import RateModal from "./components/RateModal"
import {show} from 'redux-modal'

class Rates extends PureComponent {

    componentDidMount() {
        this.props.fetchTeachersRates();
        this.props.fetchTeacherStatus();
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    handleAddTeacherRate() {
        this.props.show("rateModal", {isCreate: true});
    }

    handleEditTeacherRate(row) {
        this.props.show("rateModal", {row, isCreate: false});
    }

    handleDeleteTeacherRate(id) {
        this.props.deleteTeacherRates(id);
    }

    render() {
        return (
            <div>
                <RateModal/>
                <ReactTable
                    data={this.props.rows.toArray()}
                    filterable
                    columns={[

                        {
                            Header: "Викладач",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["teacher"]}),
                            filterAll: true,
                            accessor: "teacher",
                        },
                        {
                            Header: "Ставка",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["rateValue"]}),
                            filterAll: true,
                            accessor: "rateValue",
                            className: "text-center",
                        },
                        {
                            Header: "Погодинка",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["rateHourly"]}),
                            filterAll: true,
                            accessor: "rateHourly",
                            className: "text-center",
                        },
                        {
                            Header: "Норма годин",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["normHours"]}),
                            filterAll: true,
                            accessor: "normHours",
                            className: "text-center",
                        },
                        {
                            Header: "Статус викладача",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["teacherStatus"]}),
                            filterAll: true,
                            accessor: "teacherStatus",
                        },
                        {
                            Header: "Початок",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["start"]}),
                            filterAll: true,
                            accessor: "start",
                            className: "text-center",
                        },
                        {
                            Header: "Кінець",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["end"]}),
                            filterAll: true,
                            accessor: "end",
                            className: "text-center",
                        },
                        {
                            Header: "Кафедра",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["department"]}),
                            filterAll: true,
                            accessor: "department",
                            className: "text-center",
                        },
                        {

                            Header: <Popup
                                trigger={
                                    <Icon name='add' size={"big"}
                                          color={"green"}
                                          onClick={
                                              this.handleAddTeacherRate.bind(this)}/>
                                }
                                content='Додати запис'
                                on={'hover'}
                                style={{zIndex: 3001}}
                            />,
                            accessor: "id",
                            width: 100,
                            Cell: ({row}) => {
                                return (<div style={{marginTop: "15px"}}>

                                    <Popup
                                        trigger={<Icon name={"edit"}
                                                       color={"blue"}
                                                       onClick={this.handleEditTeacherRate.bind(this, row)}
                                        />}
                                        content="Редагувати інформацію"
                                        hideOnScroll
                                        on='hover'
                                    />
                                    <Popup
                                        trigger={<Icon name={"trash"}
                                                       color={"red"}
                                                       onClick={this.handleDeleteTeacherRate.bind(this, row._original.id)}
                                        />}
                                        content="Видалити запис"
                                        hideOnScroll
                                        on='hover'
                                    />
                                </div>)
                            },
                            sortable: false,
                            filterable: false,
                            style: {
                                cursor: "pointer",
                                fontSize: 25,
                                padding: "0",
                                textAlign: "center",
                                userSelect: "none"
                            }
                        },
                    ]}
                    defaultPageSize={10}
                    style={{
                        height: "80vh",
                    }}
                    className="table-bordered table-striped white-bg"
                    noDataText={noDataText}
                    previousText={previousText}
                    nextText={nextText}
                    loadingText={loadingText}
                    pageText={pageText}
                    ofText={ofText}
                    rowsText={rowsText}
                />
            </div>
        );


    }
}


function mapStateToProps(state) {
    return {
        rows: state.get("teachersRates"),
        teachersStatus: state.get("data4Select").get("teachersStatus")
    }
}

export default connect(mapStateToProps, {
    deleteTeacherRates,
    show,
    fetchTeachersRates,
    fetchTeacherStatus
})(Rates)