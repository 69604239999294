import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import {compose} from "redux";
import {connect} from "react-redux";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editTeacherLoadInfo} from "../../actions";


class TeacherLoadHourlyModal extends PureComponent {

    handleSave = () => {
        this.props.editTeacherLoadInfo(this.state);
        this.props.hide("teacherLoadHourlyModal")
    };

    handleInputChange = (e, accessor) => {
        const object = {};
        object[accessor] = e.target.value;
        this.setState(object);
    };

    constructor(props) {
        super(props);
        const row = this.props.row ? this.props.row._original : {};
        let newObject = {};
        newObject.hoursPractical = row.hoursPractical;
        newObject.hoursLectures = row.hoursLectures;
        newObject.id = row.id;
        props.header.map(column => {
            return newObject[column.accessor] = row[column.accessor];
        });
        this.state = newObject;
    }

    render() {
        const {show} = this.props;
        const rowItem = this.props.row ? this.props.row._original : {};
        return (
            <PureModal
                header={"Редагування інформації в погодинці викладача"}
                width={"80vw"}
                onClose={() => {
                    this.props.hide("teacherLoadHourlyModal");
                }}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("teacherLoadHourlyModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
            >
                <div style={{overflow: 'auto'}}>
                    <Table celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    Предмет
                                </Table.Cell>
                                <Table.Cell>
                                    {rowItem.discipline}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Семестр
                                </Table.Cell>
                                <Table.Cell>
                                    {rowItem.semester}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Викладач
                                </Table.Cell>
                                <Table.Cell>
                                    {rowItem.teacher}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Кількість годин лекцій
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={(e) => this.setState({hoursLectures: e.target.value})}
                                        className={"form-control"}
                                        type={"number"}
                                        step="0.01"
                                        min={0}
                                        placeholder={"Кількість годин лекцій"}
                                        value={this.state.hoursLectures}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Кількість годин практичних
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={(e) => this.setState({hoursPractical: e.target.value})}
                                        className={"form-control"}
                                        type={"number"}
                                        step="0.01"
                                        min={0}
                                        placeholder={"Кількість годин практичних"}
                                        value={this.state.hoursPractical}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            {
                                this.props.header.map(row => {
                                    const accessor = row.accessor;
                                    return (<Table.Row key={`Table.Row${accessor}`}>
                                        <Table.Cell>
                                            {row.name}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <input
                                                onChange={e => this.handleInputChange(e, accessor)}
                                                placeholder={row.name}
                                                // type={"number"}
                                                // step="any"
                                                // min={0}
                                                className={"form-control"}
                                                value={this.state[accessor] ? this.state[accessor].toLocaleString() : ''}

                                            />
                                        </Table.Cell>
                                    </Table.Row>)

                                })
                            }
                        </Table.Body>
                    </Table>
                </div>
            </PureModal>
        );
    }


}

function mapStateToProps(state) {
    return {
        header: state.get("teacherLoad").get("tableHeader"),
    }
}

export default compose(
    connectModal({
        name: 'teacherLoadHourlyModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editTeacherLoadInfo,
    }))(TeacherLoadHourlyModal);

