import {List} from "immutable"
import {
    DELETE_PREFERENTIAL_CATEGORY,
    EDIT_PREFERENTIAL_CATEGORY_INFO,
    FETCH_PREFERENTIAL_CATEGORIES,
    INSERT_PREFERENTIAL_CATEGORY
} from "./types";
const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_PREFERENTIAL_CATEGORIES:
            return List(action.payload);
        case EDIT_PREFERENTIAL_CATEGORY_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case INSERT_PREFERENTIAL_CATEGORY:
            return state.insert(0, action.payload);
        case DELETE_PREFERENTIAL_CATEGORY:
            return state.delete(state.findIndex(i => i.id === action.payload));
        default:
            return state;
    }
}
