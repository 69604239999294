import axios from "axios";
import {
    DELETE_TEACHER_POSITION,
    EDIT_TEACHER_POSITION,
    FETCH_TEACHERS_POSITIONS,
    INSERT_TEACHER_POSITION
} from "./types";
import {ROOT_URL} from "../../../utils";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchTeachersPositions() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/teachers/positions`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHERS_POSITIONS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function editTeachersPositions(teacherPosition) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/teachers/positions/edit`, teacherPosition, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_TEACHER_POSITION, payload: teacherPosition})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertTeacherPosition(teacherPosition) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/teachers/position/create`, teacherPosition, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_TEACHER_POSITION, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteTeacherPosition( id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/teachers/position/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_TEACHER_POSITION, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}