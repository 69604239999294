import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {
    DELETE_STUDENT_STATUS,
    EDIT_STUDENT_STATUS_INFO,
    FETCH_STUDENT_STATUSES,
    INSERT_STUDENT_STATUS
} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchStudentStatuses() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/student/statuses`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request
            .then(({data}) => {
                dispatch({type: FETCH_STUDENT_STATUSES, payload: data})

            }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function editStudentStatusInfo(studentStatus) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/student/statuses/edit`, studentStatus, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_STUDENT_STATUS_INFO, payload: studentStatus})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertStudentStatus(status) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/student/status/create`, status, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_STUDENT_STATUS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteStudentStatus(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/student/status/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_STUDENT_STATUS, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


