import axios from "axios";
import {
    DELETE_CUSTOM_LESSONS_INFO,
    EDIT_CUSTOM_LESSONS_INFO,
    FETCH_CUSTOM_LESSONS,
    INSERT_CUSTOM_LESSONS_INFO,
} from "./types"
import Cookie from "js-cookie";
import {ROOT_URL} from "../../utils";
import {writeErrors} from "../../utils/logs";


export function fetchCustomLessons() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/custom-lessons`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_CUSTOM_LESSONS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function editCustomLessonsInfo(customLesson) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/custom-lessons/edit`, customLesson, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_CUSTOM_LESSONS_INFO, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertCustomLesson(customLesson) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/custom-lesson/create`, customLesson, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_CUSTOM_LESSONS_INFO, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteCustomLesson(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/custom-lesson/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_CUSTOM_LESSONS_INFO, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


