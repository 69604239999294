import React, {Component} from "react";
import {connect} from "react-redux";
import {deleteUserDocumentType, fetchUserDocumentTypes} from "./actions";
import {writeErrors} from "../../../utils/logs";
import matchSorter from "match-sorter";
import {loadingText, nextText, noDataText, ofText, pageText, previousText, rowsText} from "../../../utils/locale";
import ReactTable from "react-table";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import UserDocumentTypeModal from "./components/UserDocumentTypeModal";
import {show} from "redux-modal";

class UserDocumentType extends Component {
    componentDidMount() {
        this.props.fetchUserDocumentTypes();
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    handleAddUserDocument() {
        this.props.show("userDocumentTypeModal", {isCreate: true});
    }

    handleEditUserDocumentInfo(row) {
        this.props.show("userDocumentTypeModal", {row, isCreate: false});
    }

    handleDeleteUserDocument(id) {
        this.props.deleteUserDocumentType(id);
    }


    render() {
        return (
            <div>
                <UserDocumentTypeModal/>
                <ReactTable
                    data={this.props.rows.toArray()}
                    filterable
                    columns={[

                        {
                            Header: "Назва типу документу",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["name"]}),
                            filterAll: true,
                            accessor: "name",
                        }, {
                            Header: "Скорочена назва типу документу",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["shortName"]}),
                            filterAll: true,
                            accessor: "shortName",
                        }, {
                            Header: "В архів",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["isArchived"]}),
                            filterAll: true,
                            accessor: "isArchived",
                            className: "text-center"
                        },
                        {

                            Header: <Popup
                                trigger={
                                    <Icon name='add' size={"big"}
                                          color={"green"}
                                          onClick={
                                              this.handleAddUserDocument.bind(this)}/>
                                }
                                content='Додати запис'
                                on={'hover'}
                                style={{zIndex: 3001}}
                            />,
                            accessor: "id",
                            width: 100,
                            Cell: ({row}) => {
                                return (<div style={{marginTop: "15px"}}>

                                    <Popup
                                        trigger={<Icon name={"edit"}
                                                       color={"blue"}
                                                       onClick={this.handleEditUserDocumentInfo.bind(this, row)}
                                        />}
                                        content="Редагувати інформацію"
                                        hideOnScroll
                                        on='hover'
                                    />
                                    <Popup
                                        trigger={<Icon name={"trash"}
                                                       color={"red"}
                                                       onClick={this.handleDeleteUserDocument.bind(this, row._original.id)}
                                        />}
                                        content="Видалити запис"
                                        hideOnScroll
                                        on='hover'
                                    />
                                </div>)
                            },
                            sortable: false,
                            filterable: false,
                            style: {
                                cursor: "pointer",
                                fontSize: 25,
                                padding: "0",
                                textAlign: "center",
                                userSelect: "none"
                            }
                        },
                    ]}
                    defaultPageSize={10}
                    style={{
                        height: "80vh",
                    }}
                    className="table-bordered table-striped white-bg"
                    noDataText={noDataText}
                    previousText={previousText}
                    nextText={nextText}
                    loadingText={loadingText}
                    pageText={pageText}
                    ofText={ofText}
                    rowsText={rowsText}
                />
            </div>);
    }
}

function mapStateToProps(state) {
    return {
        rows: state.get("userDocumentTypes")
    }
}

export default connect(mapStateToProps, {
    show,
    fetchUserDocumentTypes,
    deleteUserDocumentType
})(UserDocumentType)
