import {List} from "immutable"
import {EDIT_STUDENT_INFO, FETCH_STUDENTS} from "./types"

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_STUDENTS:
            return List(action.payload);
        case EDIT_STUDENT_INFO:
            return List(action.payload);
        default:
            return state;
    }
}