import React, {PureComponent} from 'react'
import "./styles.css";
import {connect} from "react-redux";
import {compose} from "redux";
import HTML5Backend from "react-dnd-html5-backend";
import {DragDropContext} from 'react-dnd';
import {deleteLesson, fetchLessons} from "./actions";
import TeacherLoadsTable from "./components/TeacherLoadsTable";
import Trash from "./components/Target/Trash";
import {writeErrors} from "../../utils/logs";
import AudiencesModal from "./components/Modals/AudiencesModal";
import LessonInfoModal from "./components/Modals/LessonInfoModal";
import LessonContextMenu from "./components/ContextMenu/LessonContextMenu";
import TeachersLoadContextMenu from "./components/ContextMenu/TeachersLoadContextMenu";
import TimetableFilters from "./components/TimetableFilters";
import LessonsTable from "./components/LessonsTable";
import CreateLessonTemplateModal from "./components/Modals/CreateLessonTemplateModal";
import TeacherProposalsModal from "./components/Modals/TeacherProposalsModal";
import EditLessonInfoModal from "./components/Modals/EditLessonInfoModal";
import TeacherJournalModal from "./components/Modals/TeacherJournalModal";
import TeacherTimetableModal from "./components/Modals/TeacherTimetableModal";

class Lessons extends PureComponent {
    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    render() {
        return (
            <div>
                <TeacherTimetableModal/>
                <AudiencesModal/>
                <LessonInfoModal/>
                <CreateLessonTemplateModal/>
                <TeacherProposalsModal/>
                <EditLessonInfoModal/>
                <TeacherJournalModal/>
                <TeachersLoadContextMenu/>
                <LessonContextMenu/>
                <TeacherLoadsTable/>
                <div className={"left w-75"}>
                    <TimetableFilters
                    />
                    <Trash deleteLesson={this.props.deleteLesson}/>
                    <LessonsTable
                    />
                </div>
            </div>
        )
    }
}


export default compose(
    DragDropContext(HTML5Backend),
    connect(null, {
        fetchLessons,
        deleteLesson,
    })
)(Lessons);

