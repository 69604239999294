import axios from "axios";
import {FETCH_DEPARTMENT_TIMETABLE, FETCH_FACULTY_DEAN, FETCH_FACULTY_TIMETABLE, FETCH_USER_TIMETABLE} from "./types";
import Cookie from "js-cookie";
import {ROOT_URL} from "../../utils";
import {writeErrors} from "../../utils/logs";
import {toast} from "react-toastify";


export function fetchDepartmentTimetable(filers) {
    const token = Cookie.get('OA.UM-Auth');
    logout(token);

    const request = axios.get(`${ROOT_URL}/v1/timetable/department`, {
        params: filers,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_DEPARTMENT_TIMETABLE, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchDepartmentDean() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/timetable/dean`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_FACULTY_DEAN, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchUserTimetable(filers) {
    const token = Cookie.get('OA.UM-Auth');
    logout(token);
    const request = axios.get(`${ROOT_URL}/v1/timetable/user`, {
        params: filers,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_USER_TIMETABLE, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchDefaultUserTimetable() {
    let token = Cookie.get('OA.UM-Auth');
    logout(token);
    const request = axios.get(`${ROOT_URL}/v1/timetable/defaultUserTimetable`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_USER_TIMETABLE, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchFacultyTimetable(filers) {
    const token = Cookie.get('OA.UM-Auth');
    logout(token);
    const request = axios.get(`${ROOT_URL}/v1/timetable/faculty`, {
        params: filers,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_FACULTY_TIMETABLE, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

function logout(token) {
    if (!token) {
        toast.warn('Користувач не авторизований в системі', {
            position: "top-right",
            autoClose: 5000,
        });
        window.location.href = "/";
    }
}