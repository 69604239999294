import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {DELETE_AUDIENCE, EDIT_AUDIENCE_INFO, FETCH_AUDIENCES, INSERT_AUDIENCE} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchAudiences() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/audiences`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_AUDIENCES, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function editAudiencesInfo(audience) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/university/audiences/edit`, audience, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_AUDIENCE_INFO, payload: audience})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertAudience(audience) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/university/audience/create`, audience, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_AUDIENCE, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteAudience(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/university/audience/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: DELETE_AUDIENCE, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}




