import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import {connect} from "react-redux";
import {compose} from "redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {fetchDepartments4Select} from "../../../../../Data4Select/actions";
import {NoOptionsMessage} from "../../../../../../utils";
import Select from "react-select";
import {changeUserDepartment} from "./actions";

class UserDepartmentModal extends PureComponent {
    state = {
        departmentId: {
            value: localStorage
                .getItem("OA.UM.Dekanat_userDepartmentId") ,
            label: localStorage
                .getItem("OA.UM.Dekanat_userDepartment")
        }
    };

    componentDidMount() {
        this.props.fetchDepartments4Select();
    }


    render() {
        const {show, hide} = this.props;
        const departments = this.props.departments.toArray();
        return (
            <PureModal
                header={"Кафедра користувача"}
                footer={<div className="float-right">
                    <Button color={"red"} icon onClick={() => hide("userDepartmentModal")}>
                        <Icon name='remove'/> Закрити
                    </Button>
                </div>}
                width={"40vw"}
                isOpen={show}
                onClose={() => {
                    this.props.hide("userDepartmentModal");
                }}
            >
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Кафедра
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={departments}
                                    onChange={(selectedValue) => {
                                        if(selectedValue){
                                            changeUserDepartment(
                                                selectedValue.value
                                            );

                                            localStorage.setItem("OA.UM.Dekanat_userDepartmentId",selectedValue.value)
                                            localStorage.setItem("OA.UM.Dekanat_userDepartment",selectedValue.label)
                                        }
                                        hide("userDepartmentModal")
                                    }}
                                    defaultValue={this.state.departmentId}
                                    noOptionsMessage={NoOptionsMessage}
                                    placeholder={"Вкажіть кафедру"}
                                    isClearable={false}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        departments: state.get("data4Select").get("departments"),
    }
}

export default compose(
    connectModal({
        name: 'userDepartmentModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        fetchDepartments4Select
    })
)(UserDepartmentModal);