import {List, merge} from "immutable"
import {EDIT_OPTIONS_INFO, FETCH_OPTIONS} from "../../types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_OPTIONS:
            return List(action.payload);
        case EDIT_OPTIONS_INFO:
            const {value, key} = action.payload;
            const index = state.findIndex(i=>i.key === key);
            return state.update(index, r => merge(r, {value:value.toString()}));
        default:
            return state;
    }
}