import React, {PureComponent} from "react";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {connect} from "react-redux";

class Timetable extends PureComponent {
    renderLessons = (lessons) => {
        return lessons.map(lesson => {
            return (
                <div key={lesson.id} className={"text-center"}>
                    <span>{lesson.discipline}</span>
                    <span> {lesson.lessonType}</span>
                    <span> {lesson.number}</span>
                    <span>{lesson.maxNumber}</span>
                    <span> {lesson.teacher}</span>
                    <em>{lesson.audience}</em>
                </div>
            );
        })
    };
    renderCells = (cells) => {
        return cells.map((lessonCell, i) => {
            const {lessons} = lessonCell;
            if (lessons.length > 0) {
                return (<Table.Cell key={`cell_${i}`}
                                    verticalAlign={"middle"}
                                    textAlign={"center"}
                                    colSpan={lessonCell.colSpan}
                                    rowSpan={lessonCell.rowSpan}>
                    {this.renderLessons(lessons)}
                </Table.Cell>);
            } else {
                const {value} = lessonCell;
                return (
                    <Table.Cell key={`cell_${i}`}
                                verticalAlign={"middle"}
                                textAlign={"center"}
                                 className={"font-weight-bold"}
                                colSpan={lessonCell.colSpan} rowSpan={lessonCell.rowSpan}>
                        {value}
                    </Table.Cell>
                );
            }

        })
    };
    renderTableHeader = (tableHeader) => {
        return tableHeader.map((cells, rowIdx) => {
            return (<Table.Row key={`row_${rowIdx}`} className={"text-center"}>
                {this.renderCells(cells)}
            </Table.Row>)
        })

    };
    renderTableBody = (tableBody) => {
        return tableBody.map((cells, rowIdx) => {
            return (<Table.Row key={`row_${rowIdx}`} className={"text-center"}>
                {this.renderCells(cells)}
            </Table.Row>)
        })

    };


    render() {
        const countRows = this.props.lessons.get(0)[0].colSpan || 0;
        const tableHeader = this.props.lessons.slice(0, countRows);
        const tableBody = this.props.lessons.skip(countRows);
        return (
            <div>
                <div className="d-none d-print-block">
                    <span  className={"font-weight-bold"}>"Затверджую"</span><br/>
                    <span  className={"font-weight-bold"}>
                        Проректор з науково-педагогічної роботи
                    </span>
                    <br/>
                    <span  className={"font-weight-bold"}>
                        ________________________ проф. Шевчук Д.М.
                    </span>
                </div>
                <br/>
                <div className="d-none d-print-block text-center ">
                    <span  className={"font-weight-bold"}>Розклад
                        за {this.props.week ? this.props.week.label.split('|')[1] : ''} за {this.props.year ? this.props.year.label : ''} курс</span>
                </div>
                <Table celled compact={"very"} className={"timetable"}>
                    <Table.Body>
                        {this.renderTableHeader(tableHeader)}
                        {this.renderTableBody(tableBody)}
                    </Table.Body>
                </Table>
                <div className="d-none d-print-block ">
                    <div>
                    <span  className={"font-weight-bold"}>"Погоджено"</span>
                    </div>
                    <div>
                    <span  className={"font-weight-bold"}>
                        {this.props.dean.get("text")}
                    </span>
                    <span className="float-right font-weight-bold">
                      {this.props.dean.get("dean")}
                    </span>
                    </div>
                    <div>
                    <span  className={"font-weight-bold"}>
                    Методист першої категорії НМВ
                    </span>
                    <span className="float-right font-weight-bold">
                    Голоюх Л.В.
                    </span>
                </div>
                </div>
            </div>
        );
    }

}


function mapStateToProps(state) {
    return {
        lessons: state.get("timetable").get("faculty"),
        dean: state.get("timetable").get("dean"),
    }
}

export default connect(mapStateToProps)(Timetable);
