import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {editUniversityGroupsInfo} from "../../actions";
import {
    getDefaultOption,
    getDefaultUniversityGroupType,
    getUniversityGroupTypes,
    NoOptionsMessage
} from "../../../../../utils";
import Select from "react-select";

class EditUniversityGroupModal extends PureComponent {

    handleSave = () => {
        this.props.editUniversityGroupsInfo({
            id: this.props.data.id,
            year: this.state.year,
            specialityId: this.state.specialityId.value,
            name: this.state.name,
            alias: this.state.alias,
            groupLevelId: this.state.groupLevelId ? this.state.groupLevelId.value : "",
            universityGroupType: this.state.universityGroupType.value,
        });
        this.props.hide("editUniversityGroupModal");
    };

    constructor(props) {
        super(props);
        const defaultOption = getDefaultOption();
        const row = props.data;
        this.state = {
            year: row.year,
            specialityId: row.specialityId ? {value: row.specialityId, label: row.speciality} : defaultOption,
            groupLevelId: row.groupLevelId ? {value: row.groupLevelId, label: row.groupLevel} : defaultOption,
            name: row.name,
            alias: row.alias || '',
            universityGroupType: row.universityGroupType ? {
                value: row.universityGroupTypeId,
                label: row.universityGroupType
            } : getDefaultUniversityGroupType(),
        }
    }

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={`Редагування інформації про групу - ${this.props.data.name}`}
                width={"95vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("editUniversityGroupModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave.bind(this)}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
                onClose={() => this.props.hide("editUniversityGroupModal")}
            >
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Спеціальність</Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={this.props.specialities.toArray()}
                                    onChange={(selected) => {
                                        this.setState({specialityId: selected})
                                    }}
                                    defaultValue={this.state.specialityId}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Вкажіть спеціальность..."}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Батьківстький елемент групи</Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={this.props.groups.toArray()}
                                    onChange={(selected) => {
                                        this.setState({groupLevelId: selected || null})
                                    }}
                                    defaultValue={this.state.groupLevelId}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable
                                    placeholder={"Виберіть батьківську групу..."}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Назва</Table.Cell>
                            <Table.Cell>
                                <input type={"text"} className={"form-control"} value={this.state.name}
                                       onChange={e => this.setState({name: e.target.value})}/>
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>Курс</Table.Cell>


                            <Table.Cell>
                                <input type={"number"} className={"form-control"} value={this.state.year}
                                       onChange={e => this.setState({year: e.target.value})}/>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Скорочена назва предмету</Table.Cell>
                            <Table.Cell>
                                <input type={"text"} className={"form-control"} value={this.state.alias}
                                       onChange={e => this.setState({alias: e.target.value})}/>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Тип групи</Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={getUniversityGroupTypes()}
                                    onChange={(selected) => {
                                        this.setState({universityGroupType: selected})
                                    }}
                                    defaultValue={this.state.universityGroupType}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Вкажіть тип групи..."}
                                />
                            </Table.Cell>
                        </Table.Row>

                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        specialities: state.get("data4Select").get("specialitiesWithStudyLevel"),
        groups: state.get("data4Select").get("groups4Lessons"),
    }
}

export default compose(
    connectModal({
        name: 'editUniversityGroupModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editUniversityGroupsInfo
    }))(EditUniversityGroupModal);

