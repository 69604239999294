import React, {PureComponent} from "react";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import {connect} from "react-redux";

class UserTimetable extends PureComponent {
    renderLessons = (lessons) => {
        return lessons.map(lesson => {
            return (
                <div key={lesson.id} className={"text-center"} style={{backgroundColor: lesson.color}}>
                    <Popup
                        trigger={<span> {lesson.disciplineShortName}</span>}
                        content={lesson.discipline}
                        hideOnScroll
                        on='hover'
                    />
                    <strong> {lesson.groups}</strong>
                    <span> {lesson.number}</span>
                    <span>{lesson.maxNumber}</span>
                    <Popup
                        trigger={<span> {lesson.teacher}</span>}
                        content={lesson.teacherFullName}
                        hideOnScroll
                        on='hover'
                    />
                    <em>{lesson.audience}</em>
                </div>
            );
        })
    }

    renderTable = () => {
        return this.props.lessons.map((lessonCell, i) => {
            const {lessons} = lessonCell;
            if (lessonCell.isHeader) {
                return (
                    <Table.Row key={`header_${i}`} className={"text-center"}>
                        <Table.Cell/>
                        <Table.Cell className={"text-center mark font-weight-bold"}>
                            <span>{lessonCell.day}</span>
                            <em> ({lessonCell.date})</em>
                        </Table.Cell>
                    </Table.Row>
                )
            } else {
                if (lessons.length > 0) {
                    return (
                        <Table.Row key={`header_${i}`}>
                            <Table.Cell className={"w-15"}>
                                <span>{`${lessonCell.lessonNumber}`}</span>
                                <em>{` (${lessonCell.lessonTime})`}</em>
                            </Table.Cell>
                            <Table.Cell>
                                {this.renderLessons(lessons)}
                            </Table.Cell>
                        </Table.Row>);

                } else {
                    return (<Table.Row key={`header_${i}`}>
                        <Table.Cell className={"w-15"}>
                            <span>{`${lessonCell.lessonNumber}`}</span>
                            <em>{` (${lessonCell.lessonTime})`}</em>
                        </Table.Cell>
                        <Table.Cell/>
                    </Table.Row>);
                }
            }

        })
    };

    render() {
        if (this.props.lessons.size < 1) {
            return (<div><br/><h3 className={"text-center"}>Розклад на цей тиждень ще не затверджений</h3></div>)
        }
        return (
            <div>
                <Table celled compact={"very"}>
                    <Table.Body>
                        {
                            this.renderTable()
                        }
                    </Table.Body>
                </Table>
            </div>
        );
    }

}


function mapStateToProps(state) {
    return {
        lessons: state.get("timetable").get("user"),
    }
}

export default connect(mapStateToProps)(UserTimetable);