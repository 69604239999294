import axios from "axios";
import {ROOT_URL} from "../../utils";
import {CREATE_USER, EDIT_UNIVERSITY_USER_INFO, EDIT_USER_SPECIALTY_INFO, FETCH_UNIVERSITY_USERS} from "./types";
import Cookie from "js-cookie";

export function fetchUsers() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/users`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_UNIVERSITY_USERS, payload: data})
        });
    }
}

export function fetchUsersByFilter(filters) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/filtered-users`, {
        params: filters,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_UNIVERSITY_USERS, payload: data})
        });
    }
}

export function createUser(user) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/user/create`, user,
        {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: CREATE_USER, payload: data})
        });
    }
}

export function editUserInfo(user) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/user/edit`, user, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_UNIVERSITY_USER_INFO, payload: data})
        });
    }
}

export function editUserSpecialtyInfo(user) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/user/edit-specialty`, user, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_USER_SPECIALTY_INFO, payload: data})
        });
    }
}

export function fetchSelectedUsers(inputValue) {
    if (!inputValue) {
        return;
    }
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/user/find-user`, {
        params: {query: inputValue},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return request.then(({data}) => {
        return data;
    })

}

export function fetchDuplicatesUsers(name, lastName, middleName) {
    if (name.length < 2) {
        return;
    }
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/user/duplicates`, {
        params: {query: {name, lastName, middleName}},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return request.then(({data}) => {
        return data;
    })
}
