import React, {PureComponent} from "react";
import Link from "react-router-dom/es/Link";
import {containsItemInArray, rolesMatched} from "../../../../utils/roleMatcher";
import Cookie from 'js-cookie'
import {toast} from "react-toastify";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {ADMIN, DEPARTMENT, EDUCATIONAL_DEPARTMENT, FACULTY,} from "../../../../utils/roles";
import {writeErrors} from "../../../../utils/logs";


class Sidebar extends PureComponent {

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }


    componentDidMount() {
        let oa = Cookie.get('OA.UM-Auth');
        const item = sessionStorage.getItem('login');
        if (item === "true" && oa === undefined) {
            toast.warn('Користувач не авторизований в системі', {
                position: "top-right",
                autoClose: 5000,
            });
            this.props.history.push("/");
        } else if (rolesMatched(this.props.allowed, Cookie.get('OA.UM-Auth_data') ? JSON.parse(Cookie.get('OA.UM-Auth_data')) : [])) {
            if (item === "true") {
                toast.warn('Користувач немає доступу до сторінки', {
                    position: "top-right",
                    autoClose: 5000,
                });
            }
            this.props.history.push("/");
        }
    }

    render() {
        const userRoles = Cookie.get('OA.UM-Auth_data') ? JSON.parse(Cookie.get('OA.UM-Auth_data')) : [];
        const {location} = this.props;
        const path = location.pathname;
        const collapseUser = containsItemInArray(["/user/types", "/user/document-types", "/students/statuses", "/students/preferential-categories", "/students/scholarship", "/users"], [path]);
        const collapseTimetable = containsItemInArray(["/university/free-audiences", "/user/lessons", "/lessons", "/custom-lessons", "/department-timetable", "/faculty-timetable"], [path]);
        const collapseArchiveTimetable = containsItemInArray(["/archive/user/lessons",  "/archive/department-timetable", "/archive/faculty-timetable"], [path]);
        const collapseDocuments = containsItemInArray(["/documents/work-plan-load", "/documents/calendar-plan", "/documents/department-request", "/documents/teacher-load/columns", "/documents/teacher-load"], [path]);
        const collapseGroups = containsItemInArray(["/university/groups", "/university/flows",], [path]);
        return (
            <ul className="navigation"
                style={{height: "100vh", overflow: "auto"}}>
                <li className={`${containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "" : "d-none"} ${path === "/settings" ? "active" : ""}`}>
                    <Link to={`/settings`}>
                        <Icon name={"home"} size={"large"}/> Панель управління</Link>
                </li>

                <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT, FACULTY], userRoles) ? "d-none" : ""} ${collapseUser ? "active" : ""}`}>
                    <Link to={`/users`}>
                                <span className="nav-label">
                                    <Icon name={"users"} size={"large"}/> Користувачі
                                    <Icon
                                        name={`chevron ${!collapseUser ? "left" : "down"}`}
                                        style={{float: "right"}}/></span>
                    </Link>
                    <ul className={`${!collapseUser ? "collapse" : ""} nav nav-second-level`}>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT, FACULTY], userRoles) ? "d-none" : ""} ${path === "/users" ? "active" : ""}`}>
                            <Link to={`/users`}>Користувачі</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/students/scholarship" ? "active" : ""}`}>
                            <Link to={`/students/scholarship`}>Види стипендій</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/students/preferential-categories" ? "active" : ""}`}>
                            <Link to={`/students/preferential-categories`}>Види пільг</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/students/statuses" ? "active" : ""}`}>
                            <Link to={`/students/statuses`}>Види статусів студентів</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/user/document-types" ? "active" : ""}`}>
                            <Link to={`/user/document-types`}>Види документів користувача</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/user/types" ? "active" : ""}`}>
                            <Link to={`/user/types`}>Типи користувачів</Link>
                        </li>

                    </ul>
                </li>
                <li className={`${(!(collapseDocuments || collapseUser || collapseGroups||collapseArchiveTimetable || collapseTimetable || (path === "/settings" || path === "/users"))) ? "active" : ""}`}>
                    <Link to={`/schedule`}>
                                <span className="nav-label"> <Icon name={"list"} size={"large"}/> Довідники <Icon
                                    name={`chevron ${(collapseDocuments || collapseGroups || collapseTimetable || collapseArchiveTimetable || (path === "/settings" || path === "/users")) ? "left" : "down"}`}
                                    style={{float: "right"}}/></span>
                    </Link>
                    <ul className={`${(collapseDocuments || collapseGroups || collapseUser || collapseTimetable|| collapseArchiveTimetable || (path === "/settings" || path === "/users")) ? "collapse" : ''} nav nav-second-level`}>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/university/faculties" ? "active" : ""}`}>
                            <Link to={`/university/faculties`}>Факультети</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""}  ${path === "/university/audiences" ? "active" : ""}`}>
                            <Link to={`/university/audiences`}>Аудиторії</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/university/departments" ? "active" : ""}`}>
                            <Link to={`/university/departments`}>Кафедри</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT, DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/university/specialities" ? "active" : ""}`}>
                            <Link to={`/university/specialities`}>Спеціальності</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT, DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/university/disciplines" ? "active" : ""}`}>
                            <Link to={`/university/disciplines`}>Предмети</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/university/disciplines-controls" ? "active" : ""}`}>
                            <Link to={`/university/disciplines-controls`}>
                                Види контролю
                            </Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/university/disciplines-cycles" ? "active" : ""}`}>
                            <Link to={`/university/disciplines-cycles`}>Цикли</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/university/study-years" ? "active" : ""}`}>
                            <Link to={`/university/study-years`}>Роки навчання</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/university/weeks" ? "active" : ""}`}>
                            <Link to={`/university/weeks`}>Тижні</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/university/weekends" ? "active" : ""}`}>
                            <Link to={`/university/weekends`}>Вихідні дні</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT, DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/university/teachers" ? "active" : ""}`}>
                            <Link to={`/university/teachers`}>Викладачі</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT, DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/teachers/rates" ? "active" : ""}`}>
                            <Link to={`/teachers/rates`}>Ставки викладачів</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/teachers/ranks" ? "active" : ""}`}>
                            <Link to={`/teachers/ranks`}>Звання викладачів</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/teachers/positions" ? "active" : ""}`}>
                            <Link to={`/teachers/positions`}>Посади викладачів</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/teachers/statuses" ? "active" : ""}`}>
                            <Link to={`/teachers/statuses`}>Статуси викладачів</Link>
                        </li>
                        <li className={`${path === "/schedule" ? "active" : ""}`}>
                            <Link to={`/schedule`}> Розклад дзвінків </Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT, DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/teachers/consultations" ? "active" : ""}`}>
                            <Link to={`/teachers/consultations`}> Консультації викладачів</Link>
                        </li>


                    </ul>
                </li>

                <li className={`${collapseTimetable ? "active" : ""}`}>
                    <Link to={`/user/lessons`}>
                                <span className="nav-label"><Icon name={"time"} size={"large"}/> Розклад <Icon
                                    name={`chevron ${!collapseTimetable ? "left" : "down"}`}
                                    style={{float: "right"}}/></span>
                    </Link>
                    <ul className={`${!collapseTimetable ? "collapse" : ""} nav nav-second-level`}>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT, FACULTY], userRoles) ? "d-none" : ""} ${path === "/lessons" ? "active" : ""}`}>
                            <Link to={`/lessons`}>Створити</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT, FACULTY], userRoles) ? "d-none" : ""} ${path === "/custom-lessons" ? "active" : ""}`}>
                            <Link to={`/custom-lessons`}>Спеціальні дисципліни</Link>
                        </li>
                        <li className={`${path === "/user/lessons" ? "active" : ""}`}>
                            <Link to={`/user/lessons`}>Переглянути розклад</Link>
                        </li>
                        <li className={`${path === "/department-timetable" ? "active" : ""}`}>
                            <Link to={`/department-timetable`}>Розклад кафедри</Link>
                        </li>
                        <li className={`${path === "/faculty-timetable" ? "active" : ""}`}>
                            <Link to={`/faculty-timetable`}>Розклад факультету</Link>
                        </li>
                        <li className={`${path === "/university/free-audiences" ? "active" : ""}`}>
                            <Link to={`/university/free-audiences`}>Вільні аудиторії</Link>
                        </li>
                    </ul>
                </li>
                <li className={`${collapseArchiveTimetable ? "active" : ""}`}>
                    <Link to={`/archive/user/lessons`}>
                                <span className="nav-label"><Icon name={"time"} size={"large"}/>Архів розкладів <Icon
                                    name={`chevron ${!collapseArchiveTimetable ? "left" : "down"}`}
                                    style={{float: "right"}}/></span>
                    </Link>
                    <ul className={`${!collapseArchiveTimetable ? "collapse" : ""} nav nav-second-level`}>
                        <li className={`${path === "/archive/user/lessons" ? "active" : ""}`}>
                            <Link to={`/archive/user/lessons`}>Переглянути розклад</Link>
                        </li>
                        <li className={`${path === "/archive/department-timetable" ? "active" : ""}`}>
                            <Link to={`/archive/department-timetable`}>Розклад кафедри</Link>
                        </li>
                        <li className={`${path === "/archive/faculty-timetable" ? "active" : ""}`}>
                            <Link to={`/archive/faculty-timetable`}>Розклад факультету</Link>
                        </li>
                    </ul>
                </li>

                <li className={` ${collapseGroups ? "active" : ""}`}>
                    <Link to={`/university/flows`}>
                                <span className="nav-label"> <Icon name={"group"} size={"large"}/> Групи/Потоки <Icon
                                    name={`chevron ${!collapseGroups ? "left" : "down"}`}
                                    style={{float: "right"}}/></span>
                    </Link>
                    <ul className={`${!collapseGroups ? "collapse" : ""} nav nav-second-level`}>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT, FACULTY], userRoles) ? "d-none" : ""} ${path === "/university/groups" ? "active" : ""}`}>
                            <Link to={`/university/groups`}>Групи</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT, DEPARTMENT, FACULTY], userRoles) ? "d-none" : ""} ${path === "/university/flows" ? "active" : ""}`}>
                            <Link to={`/university/flows`}>Потоки</Link>
                        </li>
                    </ul>
                </li>
                <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT, DEPARTMENT], userRoles) ? "d-none" : ""} ${collapseDocuments ? "active" : ""}`}>
                    <Link to={`/documents/work-plan-load`}>
                                <span className="nav-label"> <Icon name={"sticky note"} size={"large"}/>Документи <Icon
                                    name={`chevron ${!collapseDocuments ? "left" : "down"}`}
                                    style={{float: "right"}}/></span>
                    </Link>
                    <ul className={`${!collapseDocuments ? "collapse" : ""} nav nav-second-level`}>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/documents/calendar-plan" ? "active" : ""}`}>
                            <Link to={`/documents/calendar-plan`}>Календарний план</Link>
                        </li>
                        <li className={`${path === "/documents/work-plan-load" ? "active" : ""}`}>
                            <Link to={`/documents/work-plan-load`}>
                                Робочий навчальний план + Навантаження
                            </Link>
                        </li>
                        <li className={`${path === "/documents/department-request" ? "active" : ""}`}>
                            <Link to={`/documents/department-request`}>Заявки</Link>
                        </li>
                        <li className={`${!containsItemInArray([ADMIN, EDUCATIONAL_DEPARTMENT], userRoles) ? "d-none" : ""} ${path === "/documents/teacher-load/columns" ? "active" : ""}`}>
                            <Link to={`/documents/teacher-load/columns`}>Конструктор</Link>
                        </li>
                        <li className={`${path === "/documents/teacher-load" ? "active" : ""}`}>
                            <Link to={`/documents/teacher-load`}>Навантаження</Link>
                        </li>
                    </ul>
                </li>
            </ul>
        );
    }

}

export default Sidebar;

