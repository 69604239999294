import React, {Component} from "react";
import {ContextMenu, MenuItem} from "react-contextmenu";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {show} from "redux-modal";
import {removeBookingAudience} from "../../actions";

class BookingContextMenu extends Component {


    handleBookingAudience = (e, data) => {
        this.props.show("bookingAudienceModal", {data});
    };
    handleRemoveBookingAudience = (e, data) => {
        this.props.removeBookingAudience(data.audienceId);
    };


    render() {
        return (
            <ContextMenu id="bookingAudience_contextMenu">
                <MenuItem onClick={this.handleBookingAudience}>
                    <Icon name={"bookmark"} color={"blue"}/>
                    Забронювати аудиторію
                </MenuItem>
                <MenuItem onClick={this.handleRemoveBookingAudience}>
                    <Icon name={"bookmark"} color={"blue"}/>
                    Зняти бронювання з аудиторії
                </MenuItem>

            </ContextMenu>
        );
    }

}


export default connect(null, {
    removeBookingAudience,
    show
})(BookingContextMenu);