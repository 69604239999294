import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {EDIT_DEPARTMENT_REQUEST_INFO, FETCH_DEPARTMENT_REQUEST, MARGE_DEPARTMENT_REQUESTS} from "./types"
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchDepartmentRequests() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/documents/departmentsRequests`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_DEPARTMENT_REQUEST, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function editDepartmentRequests(departmentRequest) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/documents/departmentsRequests/edit`, departmentRequest, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_DEPARTMENT_REQUEST_INFO, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function margeDepartmentsRequests(ids) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/documents/departmentsRequests/merge`, ids, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: MARGE_DEPARTMENT_REQUESTS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}





