import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editUserTypeInfo, insertUserType} from "../../actions";
import {getDefaultValue, getOptions, NoOptionsMessage,} from "../../../../../utils";
import Select from "react-select";

class UserTypeModal extends PureComponent {
    handleSave = () => {
        const object = {
            id: this.state.id,
            name: this.state.name,
            shortName: this.state.shortName,
            isArchived: this.state.isArchived.value,
        };
        if (this.state.isCreate) {
            this.props.insertUserType(object);
        } else {
            this.props.editUserTypeInfo(object);
        }
        this.props.hide("userTypeModal")
    };

    constructor(props) {
        super(props);
        let row = {};
        if (props.isCreate) {
            row = {};
        } else {
            row = props.row._original
        }
        const defaultValue = getDefaultValue();
        this.state = {
            id: row.id || "",
            isCreate: props.isCreate,
            name: row.name || "",
            shortName: row.shortName || "",
            isArchived: row.isArchived ? {value: row.isArchived, label: row.isArchived} : defaultValue,
        }
    }

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Стоворення та редагування інформації про тип користувача"}
                width={"80vw"}
                footer={
                    <div className="float-right">
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("userTypeModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
                onClose={() => {
                    this.props.hide("userTypeModal");
                }}
            >
                <div>
                    <Table celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    Назва типу користувача
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({name: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.name}
                                        placeholder={"Введіть назву типу користувача"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Скорочена типу користувача
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({shortName: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.shortName}
                                        placeholder={"Введіть скорочену назву типу користувача"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    В архів
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={getOptions()}
                                        onChange={(selected) => {
                                            this.setState({
                                                isArchived: selected || getDefaultValue()
                                            })
                                        }}
                                        defaultValue={this.state.isArchived}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Перемістити запис в архів"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>

            </PureModal>
        );
    }
}


export default compose(
    connectModal({
        name: 'userTypeModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(null, {
        hide,
        editUserTypeInfo,
        insertUserType
    }))(UserTypeModal);

