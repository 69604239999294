import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import {connect} from "react-redux";
import {compose} from "redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";

class LessonInfoModal extends PureComponent {


    render() {
        const {lesson, show, hide} = this.props;
        return (
            <PureModal
                header={"Детальна інформація про предмет"}
                footer={<div className="float-right">
                    <Button color={"red"} icon onClick={() => hide("lessonDetailModal")}>
                        <Icon name='remove'/> Закрити
                    </Button>
                </div>}
                width={"40vw"}
                isOpen={show}
                onClose={() => {
                    this.props.hide("lessonDetailModal");
                }}
            >
                <Table celled textAlign={"center"} verticalAlign={"middle"}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Предмет
                            </Table.Cell>
                            <Table.Cell>
                                {lesson.discipline}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Викладач
                            </Table.Cell>
                            <Table.Cell>
                                {lesson.teacherFullName}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Пари
                            </Table.Cell>
                            <Table.Cell>
                                {lesson.number + lesson.maxNumber}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Рекомендована к-сть пар на тиждень
                            </Table.Cell>
                            <Table.Cell>
                                {this.props.recommendNumberLessons}
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                               Створила кафедра
                            </Table.Cell>
                            <Table.Cell>
                                {lesson.department}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        recommendNumberLessons: state.get("lessons").get("recommendNumberLessons")
    }
}

export default compose(
    connectModal({
        name: 'lessonDetailModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide
    })
)(LessonInfoModal);