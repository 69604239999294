import React, {Component} from "react";
import {connect} from "react-redux";
import {editDepartmentRequests, fetchDepartmentRequests, margeDepartmentsRequests} from "./actions";
import {writeErrors} from "../../../utils/logs";
import {loadingText, nextText, noDataText, ofText, pageText, previousText, rowsText} from "../../../utils/locale";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import {show} from 'redux-modal'
import DepartmentRequestModal from "./components/DepartmentRequestModal"
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import {toast} from "react-toastify";

class DepartmentsRequests extends Component {
    state = {
        selected: [],
    };
    isSelected = key => {
        return this.state.selected.includes(key);
    };
    toggleSelection = (key) => {
        let selection = [...this.state.selected];
        const keyIndex = selection.indexOf(key);
        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            // it does not exist so add it
            selection.push(key);
        }
        // update the state
        this.setState({selected: selection});
    };

    margeGroups() {
        if (this.state.selected.length === 0) {
            toast.info("Вибіть рядки, що необхідно об'єднати", {
                position: "top-center",
                autoClose: 5000,
            });
        } else {
            this.props.margeDepartmentsRequests(this.state.selected);
        }
        this.setState({selected:[]})
    };

    componentDidMount() {
        this.props.fetchDepartmentRequests();
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    handleEditDepartmentRequest(row) {
        this.props.show("departmentRequestModal", {row});
    }

    render() {
        return (<div>
            <DepartmentRequestModal/>
            <ReactTable
                data={this.props.rows.toArray()}
                filterable
                getTrProps={(s, r) => {
                    const selected = r ? this.isSelected(r.original.id) : false;
                    return {
                        style: {
                            backgroundColor: selected ? "lightgreen" : "inherit"
                        }
                    };
                }}

                columns={[
                    {
                        id: "checkbox",
                        accessor: "",
                        sortable: false,
                        filterable: false,
                        Cell: ({original}) => {
                            return (
                                <div className="pretty p-default  text-center  p-curve p-fill"
                                     style={{fontSize: "20px"}}>
                                    <input
                                        checked={this.isSelected(original.id)}
                                        onChange={() => this.toggleSelection(original.id)}
                                        type="checkbox"/>
                                    <div className="state p-info">
                                        <label/>
                                    </div>
                                </div>
                            );
                        },
                        width: 35
                    },
                    {
                        Header: "Предмет",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["discipline"]}),
                        filterAll: true,
                        accessor: "discipline",
                    },
                    {
                        Header: "Години",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["numberHours"]}),
                        filterAll: true,
                        accessor: "numberHours",
                    },
                    {
                        Header: "Семестр",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["semester"]}),
                        filterAll: true,
                        accessor: "semester",
                    },
                    {
                        Header: "Тип",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["disciplineType"]}),
                        filterAll: true,
                        accessor: "disciplineType",
                    },
                    {
                        Header: "Викладач",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["teacher"]}),
                        filterAll: true,
                        accessor: "teacher",
                    },
                    {
                        Header: "Група/Потік",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["groups"]}),
                        filterAll: true,
                        accessor: "groups",
                    },
                    {
                        Header: "Заміна",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["teacherReplacement"]}),
                        filterAll: true,
                        accessor: "teacherReplacement",
                    },
                    {
                        Header: "Заявка кафедри",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["department"]}),
                        filterAll: true,
                        accessor: "department",
                    },
                    {
                        Header: "Статус заявки",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["action"]}),
                        filterAll: true,
                        accessor: "action",
                    },
                    {

                        Header: () => {
                            return (<div>
                                <Popup
                                    trigger={<Icon name={"copy"}
                                                   color={"brown"}
                                                   size={'large'}
                                                   onClick={this.margeGroups.bind(this)}
                                    />}
                                    content={`Об'єднати вибрані групи в потік`}
                                    hideOnScroll
                                    on='hover'
                                />
                            </div>)
                        },
                        accessor: "id",
                        width: 50,
                        Cell: ({row}) => {
                            return (<div style={{marginTop: "15px"}}>

                                <Popup
                                    trigger={<Icon name={"edit"}
                                                   color={"blue"}
                                                   onClick={this.handleEditDepartmentRequest.bind(this, row)}
                                    />}
                                    content="Редагувати інформацію"
                                    hideOnScroll
                                    on='hover'
                                />
                            </div>)
                        },
                        sortable: false,
                        filterable: false,
                        style: {
                            cursor: "pointer",
                            fontSize: 25,
                            padding: "0",
                            textAlign: "center",
                            userSelect: "none"
                        }

                    },
                ]}
                defaultPageSize={10}
                style={{
                    height: "79vh",
                }}
                className="table-bordered table-striped white-bg text-center"
                noDataText={noDataText}
                previousText={previousText}
                nextText={nextText}
                loadingText={loadingText}
                pageText={pageText}
                ofText={ofText}
                rowsText={rowsText}
            />
        </div>);
    }
}

function mapStateToProps(state) {
    return {
        rows: state.get("departmentsRequests"),
    }
}

export default connect(mapStateToProps, {
    editDepartmentRequests,
    fetchDepartmentRequests,
    show,
    margeDepartmentsRequests
})(DepartmentsRequests)
