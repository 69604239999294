import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import {compose} from "redux";
import {connect} from "react-redux";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import {merge} from "immutable";
import {editTeacherLoadItems, editTeacherLoads} from "../../actions";


class TeacherLoadModal extends PureComponent {

    handleSave = () => {
        this.props.editTeacherLoads(this.props.rows);
        this.props.hide("teacherLoadModalWindow")
    };

    handleEdit = (updated, rowIdx) => {
        let rows = this.props.rows.slice();
        rows = rows.update(rowIdx, r => merge(r, updated));
        this.props.editTeacherLoadItems(rows);
    };


    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Редагування інформації в навантаженні викладчів кафедри"}
                width={"90vw"}
                onClose={() => {
                    this.props.hide("teacherLoadModalWindow");
                }}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("teacherLoadModalWindow")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave.bind(this)}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
            >
                <div style={{overflow: 'auto'}}>
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Предмет</Table.HeaderCell>
                                <Table.HeaderCell>Група/Потік</Table.HeaderCell>
                                <Table.HeaderCell>Тип</Table.HeaderCell>
                                <Table.HeaderCell>Семестр</Table.HeaderCell>
                                <Table.HeaderCell>Викладач</Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Popup
                                        trigger={<span>К-сть годин</span>}
                                        content="Кількість годин"
                                        hideOnScroll
                                        on={['hover']}
                                    />
                                </Table.HeaderCell>

                                {
                                    this.props.header.map(row => {
                                        const accessor = row.accessor;
                                        return (<Table.HeaderCell key={accessor}>
                                            <Popup
                                                trigger={<span>{row.shortName}</span>}
                                                content={row.name}
                                                hideOnScroll
                                                on={['hover']}
                                            />
                                        </Table.HeaderCell>);

                                    })
                                }
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.props.rows.map((row, i) => {
                                return (<Table.Row key={row.id}>
                                    <Table.Cell>
                                        {row.discipline}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {row.groups}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {row.disciplineType}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {row.semester}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {row.teacher}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {row.numberLessons}
                                    </Table.Cell>

                                    {
                                        this.props.header.map(header => {
                                            const accessor = header.accessor;
                                            return (
                                                <Table.Cell key={accessor}>
                                                    <input
                                                        onChange={(e) => {
                                                            const object = {};
                                                            object[accessor] = e.target.value;
                                                            this.handleEdit(object, i)
                                                        }}
                                                        // type={"number"}
                                                        // min={0}
                                                        // step="any"
                                                        placeholder={header.name}
                                                        className={"form-control vw-5"}
                                                        value={row[accessor] ? row[accessor].toLocaleString() : ''}
                                                    />
                                                </Table.Cell>
                                            )

                                        })
                                    }
                                </Table.Row>);
                            })}
                        </Table.Body>
                    </Table>
                </div>
            </PureModal>
        );
    }


}

function mapStateToProps(state) {
    return {
        header: state.get("teacherLoad").get("tableHeader"),
        rows: state.get("teacherLoad").get("rows"),
    }
}

export default compose(
    connectModal({
        name: 'teacherLoadModalWindow',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editTeacherLoads,
        editTeacherLoadItems
    }))(TeacherLoadModal);

