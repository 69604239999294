import React, {PureComponent} from "react";
import TeacherLoadItem from "../Source/TeacherLoadItem";
import {connect} from "react-redux";
import {fetchOnlyLessons, insertLesson, validateLessons} from "../../actions";
import {Accordion, AccordionItem} from "react-sanfona";

class TeacherLoadsTable extends PureComponent {
    renderTable = (lessons) => {
        return (<table className={"w-100"}>
            <tbody>
            {lessons.map((lesson, i) => {
                return (
                    <tr key={`tr_${i}`}>
                        <td>
                            <TeacherLoadItem key={lesson.id} lesson={lesson}
                                             validateLessons={this.props.validateLessons}
                                             fetchOnlyLessons={this.props.fetchOnlyLessons}
                                             insertLesson={this.props.insertLesson}
                            />
                        </td>
                    </tr>
                );
            })}
            </tbody>
        </table>);
    };


    render() {
        let lessons = [];
        let specialLessons = [];
        let completeLessons = [];
        this.props.teacherLoads.forEach(lesson => {
            switch (lesson.type) {
                case 1:
                    lessons.push(lesson);
                    break;
                case 2:
                    completeLessons.push(lesson);
                    break;
                case 3:
                    specialLessons.push(lesson);
                    break;
                default:
                    break;
            }
        });
        return (
            <div className="left w-25 table-load load-border">
                <Accordion allowMultiple>
                    <AccordionItem key={"lesson"}
                                   className={"text-transform"} title={"Заняття"} titleTag={"div"}
                                   expanded>
                        {this.renderTable(lessons)}
                    </AccordionItem>
                    <AccordionItem key={"completeLessons"}
                                   className={"text-transform"} title={"Завершені пари"}
                                   titleTag={"div"}>
                        {this.renderTable(completeLessons)}
                    </AccordionItem>
                    <AccordionItem key={"specialLessons"}
                                   className={"text-transform"} title={"Спец.предмети"}
                                   titleTag={"div"}>
                        {this.renderTable(specialLessons)}
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        teacherLoads: state.get("lessons").get("teachersLoad"),
    }
}

export default connect(mapStateToProps, {
    validateLessons,
    insertLesson,
    fetchOnlyLessons,
})(TeacherLoadsTable)