import {List, Map, merge} from "immutable"
import {
    AUDIENCES_COINCIDENCES,
    COMP_COINCIDENCES,
    COPY_LESSONS,
    DELETE_LESSON,
    DELETE_LESSON_AUDIENCE,
    EDIT_LESSON,
    EDIT_LESSONS_TEACHER_LOADS,
    FETCH_COPY_TEMPLATE,
    FETCH_LESSON_NUMBER,
    FETCH_LESSON_TEACHER_JOURNAL,
    FETCH_LESSONS,
    FETCH_LESSONS_TEACHER_PROPOSALS,
    FETCH_ONLY_LESSONS,
    FETCH_WEEKS_TEMPLATE,
    HASPROJECTOR_COINCIDENCES,
    INSERT_LESSON,
    INSERT_LESSON_AUDIENCE,
    INSERT_LESSON_TEACHER_LOADS,
    REMOVE_LESSON,
    TOGGLE_EXTRA_LESSON,
    TOGGLE_EXTRA_LESSON_TEACHER_LOADS,
    UPDATE_FILTER_VALUE,
    UPDATE_TEMPLATE_CELL,
    VALIDATE_AUDIENCES_COINCIDENCES,
    VALIDATE_LESSONS,
} from "./types"
import {toast} from "react-toastify";

const initialState = Map({
    "lessons": List(),
    "teachersLoad": List(),
    "audiences": Map({
        "audienceCoincidences": {},
        "teacherAudienceProposals": "",
        "audiences": [],
        "faculties": [],
        "hasProjector": [],
        "compAudience": [],
        "needCompAudience": false,
        "needProjector": false
    }),
    "teacherJournal": List(),
    "weeksTemplate": Map({
        "template": Map(),
        "weeks": List()
    }),

    "teacherProposals": List(),
    "recommendNumberLessons": 0,
    showMore5Rows: false,
    showSaturday: false,
    showSunday: false,
    showZeroRow: false,
    isVisibleGroups: localStorage.getItem('OA.UM-groups') !== null && localStorage.getItem('OA.UM-groups').indexOf("{") > -1,
    isVisibleTeacher: localStorage.getItem('OA.UM-teacher') !== null && localStorage.getItem('OA.UM-teacher').indexOf("{") > -1,
    isVisibleDiscipline: localStorage.getItem('OA.UM-discipline') && localStorage.getItem('OA.UM-discipline').indexOf("{") > -1,
    semesters: localStorage.getItem('OA.UM-semesters') !== null && localStorage.getItem('OA.UM-semesters').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-semesters')) : [],
    validations: localStorage.getItem('OA.UM-user_validations') === 'true',
    groups: localStorage.getItem('OA.UM-groups') !== null && localStorage.getItem('OA.UM-groups').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-groups')) : [],
    week: localStorage.getItem('OA.UM-week') !== null && localStorage.getItem('OA.UM-week').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-week')) : [],
    teacher: localStorage.getItem('OA.UM-teacher') !== null && localStorage.getItem('OA.UM-teacher').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-teacher')) : "",
    discipline: localStorage.getItem('OA.UM-discipline') && localStorage.getItem('OA.UM-discipline').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-discipline')) : [],
});
export default function (state = initialState, action) {
    let coincidences;
    let number;
    switch (action.type) {
        case REMOVE_LESSON:
            console.log(state.get("teacherJournal").filter(i => i.id !== action.payload));
            return state.set("teacherJournal",
              state.get("teacherJournal").filter(i => i.id !== action.payload));
        case FETCH_LESSONS:
        case COPY_LESSONS:
            const {lessons, teacherLoads, showMore5Rows, showSunday, showZeroRow, showSaturday} = action.payload;
            state = state.set("lessons", List(lessons));
            state = state.set("teachersLoad", List(teacherLoads));
            state = state.set("showMore5Rows", showMore5Rows);
            state = state.set("showSunday", showSunday);
            state = state.set("showZeroRow", showZeroRow);
            state = state.set("showSaturday", showSaturday);
            return state;
        case FETCH_LESSON_NUMBER:
            return state.set("recommendNumberLessons", action.payload);
        case FETCH_ONLY_LESSONS:
            return state.set("lessons", List(action.payload));
        case FETCH_LESSON_TEACHER_JOURNAL:
            return state.set("teacherJournal", List(action.payload));
        case INSERT_LESSON:
            return state.set("lessons", List(action.payload));
        case INSERT_LESSON_AUDIENCE:
            const {id, name} = action.payload;
            const newLessons = state.get("lessons").update(state.get("lessons").findIndex(i => i.id === id),
                r => merge(r, {"audience": `№ ${name}`}));
            return state.set("lessons", newLessons);
        case INSERT_LESSON_TEACHER_LOADS:
            const idx = state.get("teachersLoad").findIndex(i => i.id === action.payload);
            const teacherLoad = state.get("teachersLoad").get(idx);
            const teacherLoadNumber = parseInt(teacherLoad.number, 10) - 1;
            const newTeachersLoad = state.get("teachersLoad").update(idx, r => merge(r, {
                "number": teacherLoadNumber.toString(),
                "canDrop": teacherLoadNumber > 0
            }));
            return state.set("teachersLoad", newTeachersLoad);
        case EDIT_LESSON:
            return state.set("lessons", List(action.payload));
        case EDIT_LESSONS_TEACHER_LOADS:
            const loadIndex = state.get("teachersLoad").findIndex(i => i.id === action.payload);
            const loadRow = state.get("teachersLoad").get(loadIndex);
            number = parseInt(loadRow.number, 10) + 1;
            const canDrop = number > 0;
            const teacherItems = state.get("teachersLoad").update(loadIndex, r => merge(r, {
                "number": number,
                "canDrop": canDrop
            }));
            return state.set("teachersLoad", teacherItems);
        case DELETE_LESSON_AUDIENCE:
            const i = state.get("lessons").findIndex(i => i.id === action.payload);
            const newAudience = state.get("lessons").update(i, r =>
                merge(r, {"audience": ''}));
            return state.set("lessons", newAudience);
        case TOGGLE_EXTRA_LESSON:
            const index = state.get("lessons").findIndex(i => i.id === action.payload);
            const lesson = state.get("lessons").get(index);
            const loadNumber = parseInt(lesson.number, 10);
            number = loadNumber === 0 ? 1 : loadNumber;
            const newLessonItems = state.get("lessons").update(index, r => merge(r, {
                "isExtraLesson": !lesson.isExtraLesson,
                "number": number,
            }));
            return state.set("lessons", newLessonItems);
        case TOGGLE_EXTRA_LESSON_TEACHER_LOADS:
            const loadIdx = state.get("teachersLoad").findIndex(i => i.id === action.payload.id);
            const row = state.get("teachersLoad").get(loadIdx);
            let lessonNumber = action.payload.isExtraLesson
                ? parseInt(row.number, 10) - 1 : parseInt(row.number, 10) + 1;
            if (lessonNumber < 0) {
                lessonNumber = 0;
            }
            const toggleExtraLessons = state.get("teachersLoad").update(loadIdx, r => merge(r, {
                number: lessonNumber,
                "canDrop": lessonNumber <= parseInt(row.maxNumber.replace("/", ''), 10)
            }));
            return state.set("teachersLoad", toggleExtraLessons);
        case DELETE_LESSON:
            return state.set("lessons", List(action.payload));
        case VALIDATE_LESSONS:
            return state.set("lessons", List(action.payload));
        case VALIDATE_AUDIENCES_COINCIDENCES:
            const audienceCoincidences = state.get("audiences").get("audienceCoincidences");
            if (Object.keys(audienceCoincidences).length > 0) {
                action.payload.audienceCoincidences = audienceCoincidences;
            }
            return state.set("audiences", Map(action.payload));
        case FETCH_LESSONS_TEACHER_PROPOSALS:
            return state.set("teacherProposals", List(action.payload));
        case AUDIENCES_COINCIDENCES:
            coincidences = state.get("audiences").set("audienceCoincidences", action.payload);
            return state.set("audiences", Map(coincidences));
        case COMP_COINCIDENCES:
            coincidences = state.get("audiences").set("compAudience", action.payload);
            return state.set("audiences", Map(coincidences));
        case HASPROJECTOR_COINCIDENCES:
            coincidences = state.get("audiences").set("hasProjector", action.payload);
            return state.set("audiences", Map(coincidences));
        case FETCH_COPY_TEMPLATE:
            return state.set("weeksTemplate", state.get("weeksTemplate").set("template", Map(action.payload)));
        case UPDATE_TEMPLATE_CELL:
            let template = state.get("weeksTemplate").get("template");
            let weekId = template.get("id");
            coincidences = template.get("rows");
            switch (action.payload) {
                case 1:
                    const element = coincidences[action.row][action.column];
                    coincidences[action.row][action.column].isChecked = !element.isChecked;
                    return state.set("weeksTemplate", state.get("weeksTemplate").set("template", Map({
                        "id": weekId,
                        "rows": coincidences
                    })));
                case 2:
                    toast.error("Помилка  копіювання пари", {
                        position: "top-right",
                        autoClose: 5000,
                    });
                    break;
                case 3:
                    coincidences.forEach(rows => {
                        rows.forEach(cell => {
                            cell.isDisable = true;
                            cell.isChecked = true;
                        })
                    });
                    return state.set("weeksTemplate", state.get("weeksTemplate").set("template", Map({
                        "id": weekId,
                        "rows": coincidences
                    })));
                default:
                    break;
            }
            return state;
        case FETCH_WEEKS_TEMPLATE:
            return state.set("weeksTemplate", state.get("weeksTemplate").set("weeks", List(action.payload)));
        case UPDATE_FILTER_VALUE:
            return state.set(action.filterName, action.payload);
        default:
            return state;
    }
}