import axios from "axios";
import {DELETE_SEMESTER, EDIT_SEMESTER_INFO, INSERT_SEMESTER,} from "./types";
import {ROOT_URL} from "../../../utils";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function editSemesterInfo(semester) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/university/semesters/edit`, semester, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_SEMESTER_INFO, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertSemester() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/university/semester/create`, null, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_SEMESTER, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteSemester(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/university/semester/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: DELETE_SEMESTER, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


