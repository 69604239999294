import React, {Component} from "react";
import {connect} from "react-redux";
import {addBackup, clearBackups, deleteBackup, skip5Backups} from "../../actions";
import 'react-table/react-table.css'
import ReactTable from "react-table";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import matchSorter from 'match-sorter'
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {writeErrors} from "../../../../utils/logs";

class Backup extends Component {

    onClear() {
        this.props.clearBackups();
    };

    onSkip5Backups() {
        this.props.skip5Backups();
    };

    handleDelete(row) {
        this.props.deleteBackup(row._original.fullName);
    };

    handleAddBackup(name) {
        this.props.addBackup(name);
    };

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    render() {
        return (
            <div>
                <ReactTable
                    data={this.props.backups.toArray()}
                    filterable={false}
                    columns={[
                        {
                            Header: "Назва",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["hoursPractical"]}),
                            filterAll: true,
                            accessor: "name",
                        }, {
                            Header: "Розмір",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["hoursPractical"]}),
                            filterAll: true,
                            accessor: "size",
                        },
                        {

                            Header: () => <div><Popup
                                trigger={
                                    <Icon name='add' size={"big"}
                                          color={"green"}
                                          onClick={
                                              this.handleAddBackup.bind(this)}/>
                                }
                                content='Створити копію'
                                on={'hover'}
                                style={{zIndex: 3001}}
                            />
                                <Popup
                                    trigger={<Icon name={"sort content descending"}
                                                   size={"large"}
                                                   color={"olive"}
                                                   onClick={this.onSkip5Backups.bind(this)}
                                    />}
                                    content="Видалити копію"
                                    hideOnScroll
                                    on='hover'
                                />
                                <Popup
                                    trigger={<Icon name={"remove"}
                                                   size={"large"}
                                                   color={"red"}
                                                   onClick={this.onClear.bind(this)}
                                    />}
                                    content="Видалити всі копії"
                                    hideOnScroll
                                    on='hover'
                                /></div>,
                            accessor: "id",
                            width: 200,
                            sortable: false,
                            Cell: ({row}) => {
                                return (<Button color={"red"}
                                                style={{
                                                    marginTop: "3%",
                                                    marginBottom: "-3%"
                                                }}
                                                icon
                                                onClick={this.handleDelete.bind(this, row)}>
                                    <Icon name={"trash"}

                                    /> Видалити

                                </Button>)
                            }


                            ,
                            style: {
                                cursor: "pointer",
                                fontSize: 25,
                                padding: "0",
                                textAlign: "center",
                                userSelect: "none"
                            }

                        },
                    ]}
                    defaultPageSize={10}
                    style={{
                        height: "72vh",
                    }}
                    className="table-bordered table-striped white-bg"
                    noDataText="Немає даних"
                    previousText='Попередня сторінка'
                    nextText='Наступна сторінка'
                    loadingText='Завантаження...'
                    pageText='Сторінка'
                    ofText='з'
                    rowsText='рядків'
                />
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        backups: state.get("backups")
    }
}

export default connect(mapStateToProps, {
    clearBackups,
    skip5Backups,
    deleteBackup,
    addBackup
})(Backup)