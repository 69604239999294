import React, {Component} from "react";
import {ContextMenu, MenuItem} from "react-contextmenu";
import {connect} from "react-redux";
import {show} from 'redux-modal';

import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {deleteUniversityGroup} from "../../actions";
import {writeErrors} from "../../../../../utils/logs";

class UniversityGroupsContextMenu extends Component {


    onAddUniversityGroups = (e, data) => {
        this.props.show("groupsModal", {data});
    };

    onEditUniversityGroup = (e, data) => {
        this.props.show("editUniversityGroupModal", {data});
    };

    onDelete = (e, data) => {
        this.props.deleteUniversityGroup(data.id);

    };

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    render() {
        return (
            <ContextMenu id="universityGroups_contextMenu">
                <MenuItem onClick={this.onAddUniversityGroups}>
                    <Icon name={"add"}/> Додати
                </MenuItem>
                <MenuItem onClick={this.onEditUniversityGroup}>
                    <Icon name={"edit"} color={"green"}/> Редагувати
                </MenuItem>
                <MenuItem onClick={this.onDelete}>
                    <Icon name={"trash"} color={"red"}/> Видалити
                </MenuItem>
            </ContextMenu>
        );
    }

}


export default connect(null, {
    show,
    deleteUniversityGroup,
})(UniversityGroupsContextMenu);