import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {
    DELETE_DISCIPLINE_CYCLE,
    EDIT_DISCIPLINES_CYCLES,
    FETCH_DISCIPLINES_CYCLES,
    INSERT_DISCIPLINE_CYCLE
} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchDisciplinesCycles() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/disciplineCycles`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_DISCIPLINES_CYCLES, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function editDisciplinesCyclesInfo(disciplinesCycle) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/university/disciplineCycles/edit`, disciplinesCycle, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_DISCIPLINES_CYCLES, payload: disciplinesCycle})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertDisciplinesCycle(disciplinesCycle) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/university/disciplineCycle/create`, disciplinesCycle, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_DISCIPLINE_CYCLE, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteDisciplinesCycle(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/university/disciplineCycle/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_DISCIPLINE_CYCLE, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


