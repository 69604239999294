import {List} from "immutable"
import {DELETE_AUDIENCE, EDIT_AUDIENCE_INFO, FETCH_AUDIENCES, INSERT_AUDIENCE,} from "./types"

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_AUDIENCES:
            return List(action.payload);
        case EDIT_AUDIENCE_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case INSERT_AUDIENCE:
            return state.insert(0, action.payload);
        case DELETE_AUDIENCE:
            return state.delete(state.findIndex(i => i.id === action.payload));
        default:
            return state;
    }
}