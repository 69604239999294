import {List} from "immutable"
import {
    EDIT_TEACHER_LOAD_COLUMN_INFO,
    FETCH_TEACHER_LOAD_COLUMNS,
    INSERT_TEACHER_LOAD_COLUMN,
} from "./types"

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_TEACHER_LOAD_COLUMNS:
            return List(action.payload);
        case EDIT_TEACHER_LOAD_COLUMN_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case INSERT_TEACHER_LOAD_COLUMN:
            return state.insert(0, action.payload);
        default:
            return state;
    }
}