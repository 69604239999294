import React, {PureComponent} from 'react';
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Menu from "semantic-ui-react/dist/es/collections/Menu/Menu";
import NotificationCenter from "./components/NotificationCenter";
import Dropdown from "semantic-ui-react/dist/es/modules/Dropdown/Dropdown";
import {isUserAdmin} from "../../../../utils";
import {show} from 'redux-modal';
import {connect} from "react-redux";

class AppMenu extends PureComponent {

    handleButtonClick = () =>
        this.props.showSidebar();
    handleSettingsClick = () =>
        this.props.toggleSettings();


    render() {
        const isAdmin = isUserAdmin();
        return (

                <Menu secondary compact className="gray-bg">
                    <Menu.Item onClick={this.handleButtonClick}>
                        <Icon name='bars' size={"large"} bordered color={"blue"} inverted/>
                    </Menu.Item>
                    <Menu.Menu position={"right"}>
                        <Menu.Item className={"p-0"}>
                            <Icon title={"Настанова коритувача"} size="big" name={"question circle"} color={"orange"}/>
                        </Menu.Item>
                        <Menu.Item className={"p-0"}>
                            <NotificationCenter/>
                        </Menu.Item>
                        <Menu.Item className="pr-0 mr-0">
                            <img alt="user avatar" className="img-circle"
                                 src={localStorage.getItem("oa.um.dekanat-avatar")}/>
                            <Dropdown style={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                marginLeft: "5px",
                                minWidth: "120px",
                                textAlign: "center"
                            }} item text={localStorage.getItem("OA.UM.Dekanat_userNameWithInitials") || ' '}>
                                <Dropdown.Menu>
                                    {isAdmin && <Dropdown.Item onClick={() => this.props.show("userDepartmentModal")}>

                                        Змінити кафедру
                                    </Dropdown.Item>}
                                    <Dropdown.Item onClick={this.props.logOut}>
                                        <Icon name={"sign out"}
                                              color={"red"}/>
                                        Вихід
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                        <Menu.Item className={`p-0 ${this.props.showSettings ? "" : "d-none"}`}
                                   onClick={this.handleSettingsClick}>
                            <Icon title={"Настанова коритувача"} size="large" name={"settings"} bordered inverted
                                  color={"teal"}/>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
        );
    }
}
export default connect(null, {
    show,
})(AppMenu);
