import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {DropTarget} from 'react-dnd'
import Lesson from "../../Source/Lesson";

class Cell extends PureComponent {
    static propTypes = {
        connectDropTarget: PropTypes.func.isRequired,
        isOver: PropTypes.bool.isRequired,
        canDrop: PropTypes.bool.isRequired,
        allowedDropEffect: PropTypes.string,
    };
    static defaultProps = {
        isOver: false,
        canDrop: false,
        allowedDropEffect: ''
    };


    render() {
        const {isOver, className, connectDropTarget} = this.props;
        let canDrop = false;

        this.props.lessons.forEach(lesson => {
            if (!canDrop) {
                canDrop = lesson.canDrop === false;
            }
        });

        const hover = `${isOver && !canDrop ? "hover" : ""}`;

        return connectDropTarget(
            <td className={[hover, `${ canDrop ? "canDrop" : ""}`, className].filter(a => a).join(' ')}>
                {
                    this.props.lessons.map((lesson, i) => {
                        return <Lesson canDrop={!canDrop} key={`lesson_${i}`} lesson={lesson}
                                       validateLessons={this.props.validateLessons}
                                       insertLesson={this.props.insertLesson}
                                       fetchOnlyLessons={this.props.fetchOnlyLessons}


                        />
                    })
                }
            </td>
        )
    }
}

export default DropTarget('lesson', {
    drop(props) {
        return props;
    },
    canDrop(props) {
        return props.lessons.filter(i => !i.canDrop).size === 0;
    }

}, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
}))(Cell);