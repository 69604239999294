import {List} from "immutable"
import {DELETE_SPECIALITY, EDIT_SPECIALITY_INFO, FETCH_SPECIALITIES, INSERT_SPECIALITY,} from "./types"

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_SPECIALITIES:
            return List(action.payload);
        case EDIT_SPECIALITY_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case INSERT_SPECIALITY:
            return state.insert(0, action.payload);
        case DELETE_SPECIALITY:
            return state.delete(state.findIndex(i => i.id === action.payload));
        default:
            return state;
    }
}