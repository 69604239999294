import React, {PureComponent} from "react";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";

class AudienceInfo extends PureComponent {


    render() {
        const {needProjector, needCompAudience, teacherAudienceProposals} = this.props;
        const lesson = this.props.lesssonInfo;
        return (
            <div>
                <h4 className={"text-center"}>Інформація про пару</h4>
                <Table celled textAlign={"center"} verticalAlign={"middle"}>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Предмет
                            </Table.Cell>
                            <Table.Cell>
                                Викладач
                            </Table.Cell>
                            <Table.Cell>
                                Пари
                            </Table.Cell>
                            <Table.Cell>
                                Пропозиції
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                {lesson.discipline}
                            </Table.Cell>
                            <Table.Cell>
                                {lesson.teacherFullName}
                            </Table.Cell>
                            <Table.Cell>
                                {lesson.number + lesson.maxNumber}
                            </Table.Cell>
                            <Table.Cell>
                                <strong>{teacherAudienceProposals || "Немає"}</strong>
                                <span>
                                    <Icon className={`${needProjector ? "projector" : ""}`}/>
                                    <Icon className={`${needCompAudience ? "computer" : ""}`}/>
                                </span>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        needProjector: state.get("lessons").get("audiences").get("needProjector"),
        needCompAudience: state.get("lessons").get("audiences").get("needCompAudience"),
        teacherAudienceProposals: state.get("lessons").get("audiences").get("teacherAudienceProposals"),

    }
}

export default connect(mapStateToProps)(AudienceInfo);