export const FETCH_TEACHER_LOAD = "FETCH_TEACHER_LOAD";

export const FETCH_TEACHER_LOAD_RATES = "FETCH_TEACHER_LOAD_RATES";

export const FETCH_TEACHERS_TO_LOAD = "FETCH_TEACHERS_TO_LOAD";

export const FETCH_TEACHER_LOADS = "FETCH_TEACHER_LOADS";

export const FETCH_TEACHER_LOAD_STATISTICS = "FETCH_TEACHER_LOAD_STATISTICS";

export const INSERT_TEACHER_LOAD_ITEM = "INSERT_TEACHER_LOAD_ITEM";

export const DELETE_TEACHER_LOAD = "DELETE_TEACHER_LOAD";

export const EDIT_TEACHER_LOAD_INFO = "EDIT_TEACHER_LOAD_INFO";

export const EDIT_TEACHER_LOAD_ITEM = "EDIT_TEACHER_LOAD_ITEM";

export const EDIT_TEACHER_LOADS = "EDIT_TEACHER_LOADS";


