import Cookie from "js-cookie";
import {ROOT_URL} from "../../utils";
import axios from "axios";
import {CLEAR_BACKUPS, EDIT_OPTIONS_INFO, FETCH_BACKUPS, FETCH_LOGS, FETCH_OPTIONS} from "./types";
import {writeErrors} from "../../utils/logs";

export function fetchOptions() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/options`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_OPTIONS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}
export function fetchLogs(date) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/allLogs`, {
        params:{date},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_LOGS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}
export function fetchBackups() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/backups`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_BACKUPS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}
export function clearBackups() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/backup/clear`, null,{
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: CLEAR_BACKUPS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}
export function skip5Backups() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/backup/skip5backup`, null,{
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: CLEAR_BACKUPS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}
export function deleteBackup(name) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/backup/delete`,{
        params:{name:name},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: CLEAR_BACKUPS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}
export function addBackup() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/backup/add`,null,{
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: CLEAR_BACKUPS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function editOptionsInfo(model) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/options/edit`, model, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_OPTIONS_INFO, payload: model})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}