import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editTeachersStatus, insertTeacherStatus} from "../../actions";
import {getDefaultValue, getOptions, NoOptionsMessage} from "../../../../../utils";
import Select from "react-select";

class TeacherStatusModal extends PureComponent {
    handleSave = () => {
        const object = {
            id: this.state.id,
            name: this.state.name,
            shortName: this.state.shortName,
            isArchived: this.state.isArchived.value,
            isBasicDepartment: this.state.isBasicDepartment.value,
        };
        if (this.state.isCreate) {
            this.props.insertTeacherStatus(object);
        } else {
            this.props.editTeachersStatus(object);
        }

        this.props.hide("teacherStatusModal")
    };

    constructor(props) {
        super(props);
        let row = {};
        if (props.isCreate) {
            row = {};
        } else {
            row = props.row._original;
        }
        const defaultValue = getDefaultValue();
        this.state = {
            id: row.id || "",
            isCreate: props.isCreate,
            name: row.name || "",
            shortName: row.shortName || "",
            isArchived: row.isArchived ? {value: row.isArchived, label: row.isArchived} : defaultValue,
            isBasicDepartment: row.isBasicDepartment ? {
                value: row.isBasicDepartment,
                label: row.isBasicDepartment
            } : defaultValue,

        }
    }

    render() {
        const {show} = this.props;
        const options = getOptions();
        return <PureModal
            header={"Статус викладача на кафедрі"}
            width={"80vw"}
            footer={
                <div className={"float-right"}>
                    <Button color={"red"} icon onClick={() =>
                        this.props.hide("teacherStatusModal")
                    }>
                        <Icon name='remove'/> Закрити
                    </Button>
                    <Button primary onClick={this.handleSave}>
                        <Icon name='save'/> Зберегти
                    </Button>
                </div>
            }
            isOpen={show}
            onClose={() => {
                this.props.hide("teacherStatusModal");
            }}
        >
            <div>
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Назва
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({name: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.name}
                                    placeholder={"Введіть назву"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Скорочена назва
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({shortName: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.shortName}
                                    placeholder={"Введіть скорочену назву"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Основна кафедра
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={options}
                                    onChange={(selected) => {
                                        this.setState({
                                            isBasicDepartment: selected || getDefaultValue()
                                        })
                                    }}
                                    defaultValue={this.state.isBasicDepartment}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Вкажіть чи основна кафедра викладача"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                В архів
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={options}
                                    onChange={(selected) => {
                                        this.setState({
                                            isArchived: selected || getDefaultValue()
                                        })
                                    }}
                                    defaultValue={this.state.isArchived}
                                    noOptionsMessage={NoOptionsMessage}
                                    isClearable={false}
                                    placeholder={"Перемістити запис в архів"}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>

        </PureModal>;
    }
}


export default compose(
    connectModal({
        name: 'teacherStatusModal',
        getModalState: state => state.get("modal")
    }),
    connect(null, {
        hide,
        insertTeacherStatus,
        editTeachersStatus
    }))(TeacherStatusModal);

