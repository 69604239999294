import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editTeachersPositions, insertTeacherPosition} from "../../actions";

class TeacherPositionModal extends PureComponent {
    handleSave = () => {
        const object = {
            id: this.state.id,
            name: this.state.name,
            shortName: this.state.shortName,
        };
        if (this.state.isCreate) {
            this.props.insertTeacherPosition(object);
        } else {
            this.props.editTeachersPositions(object);
        }

        this.props.hide("teacherPositionModal")
    };

    constructor(props) {
        super(props);
        let row = {};
        if (props.isCreate) {
            row = {};
        } else {
            row = props.row._original;
        }

        this.state = {
            id: row.id || "",
            isCreate: props.isCreate,
            name: row.name || "",
            shortName: row.shortName || "",

        }
    }

    render() {
        const {show} = this.props;
        return <PureModal
            header={"Створення та редагування інформації про посаду викладача"}
            width={"80vw"}
            footer={
                <div className={"float-right"}>
                    <Button color={"red"} icon onClick={() =>
                        this.props.hide("teacherPositionModal")
                    }>
                        <Icon name='remove'/> Закрити
                    </Button>
                    <Button primary onClick={this.handleSave}>
                        <Icon name='save'/> Зберегти
                    </Button>
                </div>
            }
            isOpen={show}
            onClose={() => {
                this.props.hide("teacherPositionModal");
            }}
        >
            <div>
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Назва факульетету
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({name: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.name}
                                    placeholder={"Введіть назву факультету"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Скорочена назва факульетету
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({shortName: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.shortName}
                                    placeholder={"Введіть скорочену назву факультету"}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>

        </PureModal>;
    }
}


export default compose(
    connectModal({
        name: 'teacherPositionModal',
        getModalState: state => state.get("modal")
    }),
    connect(null, {
        hide,
        insertTeacherPosition,
        editTeachersPositions,
    }))(TeacherPositionModal);

