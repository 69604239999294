import axios from "axios";
import {EDIT_STUDENT_INFO, FETCH_STUDENTS} from "./types";
import {ROOT_URL} from "../../../utils";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchStudents(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/students`, {
        params: {id},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_STUDENTS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function editStudentInfo(students, groupId) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/university/students/edit`, {students, groupId},
        {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_STUDENT_INFO, payload: data})

        }).catch((error) => {
            writeErrors(error);
        });
    }

}


