import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editWeekendInfo, insertWeekend} from "../../actions";
import DatePicker from "react-date-picker";

class WeekendsModal extends PureComponent {
    handleSave = () => {
        const object = {
            id: this.state.id,
            name: this.state.name,
            dateValue: this.state.date,
        };
        if (this.state.isCreate) {
            this.props.insertWeekend(object);
        } else {
            this.props.editWeekendInfo(object);
        }
        this.props.hide("weekendsModal")
    };

    constructor(props) {
        super(props);
        let row = {};
        if (props.isCreate) {
            row = {};
        } else {
            row = props.row._original
        }

        this.state = {
            id: row.id || "",
            isCreate: props.isCreate,
            name: row.name || "",
            date: row.date ? new Date(row.date) : new Date(),

        }
    }

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Створення та редагування інформації про вихідний день"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("weekendsModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                onClose={() => {
                    this.props.hide("weekendsModal");
                }}
                isOpen={show}
            >
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Назва
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({name: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.name}
                                    placeholder={"Введіть назву вихідного"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Дата
                            </Table.Cell>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) =>
                                        this.setState({date: value})}
                                    locale={"uk-UA"}
                                    value={this.state.date}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}


export default compose(
    connectModal({
        name: 'weekendsModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(null, {
        hide,
        editWeekendInfo,
        insertWeekend
    }))(WeekendsModal);

