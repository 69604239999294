export const FETCH_LESSONS = "FETCH_LESSONS";
export const FETCH_ONLY_LESSONS = "FETCH_ONLY_LESSONS";
export const FETCH_LESSON_NUMBER = "FETCH_LESSON_NUMBER";
export const INSERT_LESSON = "INSERT_LESSON";
export const INSERT_LESSON_AUDIENCE = "INSERT_LESSON_AUDIENCE";
export const TOGGLE_EXTRA_LESSON = "TOGGLE_EXTRA_LESSON";
export const VALIDATE_LESSONS = "VALIDATE_LESSONS";
export const REMOVE_LESSON = "REMOVE_LESSON";
export const DELETE_LESSON = "DELETE_LESSON";
export const DELETE_LESSON_AUDIENCE = "DELETE_LESSON_AUDIENCE";
export const EDIT_LESSON = "EDIT_LESSON";

export const COPY_LESSONS = "COPY_LESSONS";

export const FETCH_LESSON_TEACHER_JOURNAL = "FETCH_LESSON_TEACHER_JOURNAL";
export const FETCH_WEEKS_TEMPLATE = "FETCH_WEEKS_TEMPLATE";
export const FETCH_COPY_TEMPLATE = "FETCH_COPY_TEMPLATE";
export const FETCH_LESSONS_TEACHER_PROPOSALS = "FETCH_LESSONS_TEACHER_PROPOSALS";

export const EDIT_LESSONS_TEACHER_LOADS = "EDIT_LESSONS_TEACHER_LOADS";
export const TOGGLE_EXTRA_LESSON_TEACHER_LOADS = "TOGGLE_EXTRA_LESSON_TEACHER_LOADS";
export const INSERT_LESSON_TEACHER_LOADS = "INSERT_LESSON_TEACHER_LOADS";
export const VALIDATE_AUDIENCES_COINCIDENCES = "VALIDATE_AUDIENCES_COINCIDENCES";

export const AUDIENCES_COINCIDENCES = "AUDIENCES_COINCIDENCES";
export const HASPROJECTOR_COINCIDENCES = "HASPROJECTOR_COINCIDENCES";
export const COMP_COINCIDENCES = "COMP_COINCIDENCES";


export const UPDATE_TEMPLATE_CELL = "UPDATE_TEMPLATE_CELL";
export const UPDATE_FILTER_VALUE= "UPDATE_FILTER_VALUE";



