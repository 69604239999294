import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {DragSource} from 'react-dnd';
import {ContextMenuTrigger} from "react-contextmenu";
import {compose} from "redux";
import {connect} from "react-redux";
import {writeErrors} from "../../../../../utils/logs";

class TeacherLoadItem extends PureComponent {
    static propTypes = {
        connectDragSource: PropTypes.func.isRequired,
        isDragging: PropTypes.bool.isRequired,
        lesson: PropTypes.object.isRequired
    };

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    render() {
        const {lesson, connectDragSource} = this.props;
        const style = {};
        const number = lesson.number;

        if (number > 0 || lesson.type === 3) {
            style.backgroundColor = lesson.color;
        }

        return connectDragSource(
            <div className={`text-center ${number < 1 && lesson.type !== 3 && "enough"}`}
                 key={lesson.id}
                 style={style}
            >
                <ContextMenuTrigger holdToDisplay={-1} id="teacherLoad_contextMenu" {...this.props}
                                    collect={(props) => props}>
                    <span>{lesson.disciplineShortName}</span>
                    <span> {lesson.lessonType}</span>
                    <span> {lesson.groups}</span>
                    <span> {number}</span>
                    <span>{lesson.maxNumber}</span>
                    <span> {lesson.teacher}</span>
                </ContextMenuTrigger>
            </div>);

    }
}


export default compose(
    DragSource('lesson', {
        beginDrag(props) {
            const week = localStorage.getItem('OA.UM-week');
            props.validateLessons({id: props.lesson.id, week});
            return props;
        },
        endDrag(props, monitor) {

            const dropResult = monitor.getDropResult();
            if (dropResult) {
                const week = localStorage.getItem('OA.UM-week');
                const {rowIdx, colIdx, isExtraLesson} = dropResult;
                if (rowIdx || rowIdx === 0) {
                    props.insertLesson({rowIdx, colIdx, id: props.lesson.id, week, isExtraLesson});
                }
            } else {
                props.fetchOnlyLessons();
            }
        },
        canDrag(props) {
            return props.lesson.canDrop;
        }

    }, (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    })),
    connect(null)
)(TeacherLoadItem);