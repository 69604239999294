import React, {Component} from "react";
import {connect} from "react-redux";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {fetchSpecialities4SelectWithStudyLevel} from "../../components/Data4Select/actions";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {loadingText, nextText, noDataText, ofText, pageText, previousText, rowsText} from "../../utils/locale";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import {writeErrors} from "../../utils/logs";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import {fetchSelectedUsers, fetchUsers, fetchUsersByFilter} from "./actions";
import Select from "react-select";
import {toast} from "react-toastify";
import UserInfoModal from "./components/UserInfoModal"
import CreateUserModal from "./components/CreateUserModal"
import UserSpecialtyInfo from "./components/UserSpecialtyInfo"
import {show} from 'redux-modal'
import {fetchScholarships} from "../Students/Scholarships/actions";
import {fetchPreferentialCategories} from "../Students/PreferentialCategories/actions";
import {fetchUserDocumentTypes} from "./UserDocumentType/actions";
import AsyncSelect from "react-select/lib/Async";
import {loadingMessage, NoOptionsMessage} from "../../utils";

class Users extends Component {
    state = {
        years: [],
        speciality: null,
        userId: null,
        expanded: {}
    };

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    componentDidMount() {
        this.props.fetchSpecialities4SelectWithStudyLevel();
        this.props.fetchUsers();
        this.props.fetchScholarships();
        this.props.fetchPreferentialCategories();
        this.props.fetchUserDocumentTypes();
    }

    handleRowExpanded(newExpanded, index) {
        this.setState({
            expanded: {
                [index[0]]: !this.state.expanded[index[0]],
            },
        });
    }

    loadUsers = inputValue => fetchSelectedUsers(inputValue);

    handleEditUserInfo(row) {
        this.props.show("userInfoModal", {row});
    }

    handleAddUser() {
        this.props.show("createUserModal");
    }

    handleEditUserSpecialityInfo(specialty) {
        this.props.show("editUserSpecialtyModal", {row: specialty});
    }

    render() {
        return (
            <div>
                <UserInfoModal/>
                <UserSpecialtyInfo/>
                <CreateUserModal/>
                <div>
                    <Table celled compact={"very"}>
                        <Table.Body>
                            <Table.Row textAlign={"center"}
                                       verticalAlign={"middle"}>
                                <Table.Cell>Користувач</Table.Cell>
                                <Table.Cell>Спеціальність</Table.Cell>
                                <Table.Cell className={"w-25"}>Курс</Table.Cell>
                                <Table.Cell rowSpan={2} className={"w-15"}
                                            textAlign={"center"}
                                            verticalAlign={"middle"}>
                                    <Button onClick={() => {
                                        if (this.state.userId === null && (this.state.speciality === null || this.state.years.length === 0)) {
                                            toast.error("Вкажіть курс або спеціальність", {
                                                position: "top-right",
                                                autoClose: 5000,
                                            });
                                        } else if (this.state.userId === null) {
                                            toast.error("Виберіть користувача", {
                                                position: "top-right",
                                                autoClose: 5000,
                                            });
                                        } else {
                                            this.props.fetchUsersByFilter({
                                                specialtyId: this.state.speciality ? this.state.speciality.value : '',
                                                id: this.state.userId ? this.state.userId.id : '',
                                                years: this.state.years.map(i => i.value).join(",")
                                            })
                                        }

                                    }}
                                            icon color={"orange"}> <Icon
                                        name={"filter"}/> Знайти</Button>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        loadOptions={this.loadUsers}
                                        noOptionsMessage={NoOptionsMessage}
                                        loadingMessage={loadingMessage}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        defaultValue={this.state.userId}
                                        isClearable
                                        onChange={(selectedValue) => this.setState({userId: selectedValue})}
                                        placeholder={"Введіть пошту або ПІП користувача"}
                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={this.props.specialities.toArray()}
                                        onChange={selectedValue => {
                                            this.setState({speciality: selectedValue})
                                        }}
                                        value={this.state.speciality}

                                        className={"w-100"}
                                        isClearable
                                        noResultsText={"Немає даних"}
                                        placeholder={"Виберіть спеціальність..."}

                                    />
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={[
                                            {label: 1, value: 1},
                                            {label: 2, value: 2},
                                            {label: 3, value: 3},
                                            {label: 4, value: 4},
                                            {label: 5, value: 5},
                                            {label: 6, value: 6},
                                        ]}
                                        onChange={selectedValue => {
                                            this.setState({years: selectedValue})
                                        }}
                                        isClearable
                                        value={this.state.years}
                                        isMulti
                                        className={"w-100"}
                                        noResultsText={"Немає даних"}
                                        placeholder={"Виберіть курс..."}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
                <div style={{overflow: "auto", height: "70vh"}}>
                    <ReactTable
                        data={this.props.users.toArray()}
                        filterable
                        expanded={this.state.expanded}
                        onExpandedChange={(newExpanded, index, event) => this.handleRowExpanded(newExpanded, index, event)}

                        columns={[
                            {

                                expander: true,
                                width: 50,
                                Expander: ({isExpanded}) => {
                                    return (
                                        <Popup
                                            trigger={<Icon name={`chevron ${isExpanded ? "down" : "up"}`}
                                                           size={"small"}
                                                           bordered circular
                                            />}
                                            content="Переглянути спеціальності студента"
                                            hideOnScroll
                                            on='hover'
                                        />

                                    )
                                },
                                sortable: false,
                                style: {
                                    cursor: "pointer",
                                    fontSize: 25,
                                    padding: "0",
                                    textAlign: "center",
                                    userSelect: "none"
                                }

                            },
                            {
                                Header: "Прізвище",
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["lastName"]}),
                                filterAll: true,
                                accessor: "lastName",
                            }, {
                                Header: "Ім'я",
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["name"]}),
                                filterAll: true,
                                accessor: "name",
                            }, {
                                Header: "По-батькові",
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["middleName"]}),
                                filterAll: true,
                                accessor: "middleName",
                            }, {
                                Header: "Пошта (Email)",
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["email"]}),
                                filterAll: true,
                                accessor: "email",
                            }, {
                                Header: "Стать",
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["studentGender"]}),
                                filterAll: true,
                                accessor: "gender",
                            }, {
                                Header: "Дата народження",
                                filterMethod: (filter, rows) =>
                                    matchSorter(rows, filter.value, {keys: ["dateBirth"]}),
                                filterAll: true,
                                accessor: "dateBirth",
                            },
                            {

                                Header: () => {
                                    return (<div>
                                        <Popup
                                            trigger={
                                                <Icon name='add' size={"big"}
                                                      color={"green"}
                                                      onClick={
                                                          this.handleAddUser.bind(this)}
                                                />
                                            }
                                            content='Додати запис'
                                            on={'hover'}
                                            style={{zIndex: 3001}}
                                        />

                                    </div>)
                                },
                                accessor: "id",
                                width: 150,
                                Cell: ({row}) => {
                                    return (<div style={{marginTop: "15px"}}>

                                        <Popup
                                            trigger={<Icon name={"edit"}
                                                           color={"blue"}
                                                           onClick={this.handleEditUserInfo.bind(this, row)}
                                            />}
                                            content="Редагувати інформацію про студента"
                                            hideOnScroll
                                            on='hover'
                                        />

                                    </div>)
                                },
                                sortable: false,
                                filterable: false,
                                style: {
                                    cursor: "pointer",
                                    fontSize: 25,
                                    padding: "0",
                                    textAlign: "center",
                                    userSelect: "none"
                                }

                            },
                        ]}
                        defaultPageSize={10}
                        SubComponent={({row}) => {
                            const student = row._original;
                            return this.getSubComponent(student);
                        }}
                        style={{
                            height: "69vh",
                        }}
                        className="table-bordered table-striped white-bg text-center"
                        noDataText={noDataText}
                        previousText={previousText}
                        nextText={nextText}
                        loadingText={loadingText}
                        pageText={pageText}
                        ofText={ofText}
                        rowsText={rowsText}
                    />
                </div>
            </div>
        );

    }

    getSubComponent(student) {
        return (
            <table className={"table-bordered table-striped table text-center"}>
                <thead>
                <tr>
                    <th className={"text-center"}>
                        Спеціальність
                    </th>
                    <th className={"text-center"}>
                        Дата вступу
                    </th>
                    <th className={"text-center"}>
                        Дата закінчення
                    </th>
                    <th className={"text-center"}>
                        Форма навчання
                    </th>
                    <th className={"text-center"}>

                    </th>

                </tr>
                </thead>
                <tbody>
                {this.getTableRows(student)}
                </tbody>
            </table>
        )
    }

    getTableRows(student) {
        return student.specialities.map(speciality => {
            return (<tr key={speciality.id}>
                <td>{speciality.name}</td>
                <td>{speciality.dateStart}</td>
                <td>{speciality.dateEnd}</td>
                <td>{speciality.studentPaymentForm}</td>
                <td>
                    <Popup
                        trigger={<Icon name={"edit"} size={"big"}
                                       color={"blue"}
                                       onClick={this.handleEditUserSpecialityInfo.bind(this, speciality)}
                        />}
                        content="Редагувати інформацію про спеціальності студента"
                        hideOnScroll
                        on='hover'
                    />
                </td>
            </tr>)
        })
    }
}

function mapStateToProps(state) {
    return {
        specialities: state.get("data4Select").get("specialitiesWithStudyLevel"),
        users: state.get("users"),
    }
}

export default connect(mapStateToProps, {
    fetchSpecialities4SelectWithStudyLevel,
    fetchUserDocumentTypes,
    fetchUsers,
    show,
    fetchUsersByFilter,
    fetchScholarships,
    fetchPreferentialCategories
})(Users)

