import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editUniversityGroupsFlowInfo, insertUniversityGroupsFlow} from "../../actions";
import {NoOptionsMessage, ROOT_URL} from "../../../../../utils";
import Select from "react-select";
import axios from "axios";
import Cookie from "js-cookie";

class FlowsModal extends PureComponent {
    handleSave = () => {
        const object = {
            id: this.state.id,
            name: this.state.name,
            labels: this.state.groups.map(i => i.value).filter(i => i).join(","),
        };
        if (this.state.isCreate) {
            this.props.insertUniversityGroupsFlow(object);
        } else {
            this.props.editUniversityGroupsFlowInfo(object, {
                ...object,
                groups: this.state.groups,
                labels: this.state.groups.map(i => i.label).filter(i => i).join(", ")
            });
        }

        this.props.hide("flowsModal")
    };

    constructor(props) {
        super(props);
        let row = {};
        if (props.isCreate) {
            row = {};
        } else {
            row = props.row._original
        }

        this.state = {
            id: row.id || "",
            isCreate: props.isCreate,
            name: row.name || "",
            groups: row.groups || [],
            flows: []
        }
    }

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Створення та редагування інформації про потік"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("flowsModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
                onClose={() => {
                    this.props.hide("flowsModal");
                }}
            >
                <div>
                    <Table celled compact>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    Групи,що входять в потік
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={this.props.groups.toArray()}
                                        onChange={(selected) => {
                                            const token = Cookie.get('OA.UM-Auth');
                                            const request = axios.get(`${ROOT_URL}/v1/university/universityGroupFlows`, {
                                                params: {flows: selected.map(i => i.value).filter(i => i).join(",")},
                                                headers: {"Authorization": `Bearer ${token}`}
                                            });
                                            request.then(({data}) => {
                                                this.setState({flows: data})
                                            });
                                            this.setState({groups: selected})
                                        }}
                                        noOptionsMessage={NoOptionsMessage}
                                        isMulti
                                        defaultValue={this.state.groups}
                                        placeholder={"Вкажіть групи, що входять в потік"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"w-25"}>
                                    Назва потоку
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({name: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.name}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
                {this.state.flows.length > 0 && <div>
                    <h4 className={"text-center"}>Ці групи вже є в таких потоках:</h4>
                    <div style={{
                        overflow: "auto",
                        maxHeight: "55vh"
                    }}>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell textAlign={"center"}
                                                      verticalAlign={"middle"}>Назва</Table.HeaderCell>
                                    <Table.HeaderCell textAlign={"center"}
                                                      verticalAlign={"middle"}>Групи</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.flows.map(flow => {
                                    return (<Table.Row key={flow.id}>
                                        <Table.Cell>
                                            {flow.name}
                                        </Table.Cell>
                                        <Table.Cell> {flow.labels}</Table.Cell>
                                    </Table.Row>)
                                })}
                            </Table.Body>
                        </Table>
                    </div>
                </div>}
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        groups: state.get("data4Select").get("groups"),
    }
}

export default compose(
    connectModal({
        name: 'flowsModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editUniversityGroupsFlowInfo,
        insertUniversityGroupsFlow,
    }))(FlowsModal);

