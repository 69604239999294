import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {connect} from "react-redux";
import {compose} from "redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {editLessonInfo} from "../../../actions";
import {loadingMessage, NoOptionsMessage} from "../../../../../utils";
import AsyncSelect from 'react-select/lib/Async';
import {fetchTeachers4Select} from "../../../../../components/Data4Select/actions";

class EditLessonInfoModal extends PureComponent {
    handleSave = () => {
        this.props.editLessonInfo({
            teacherId: this.state.teacher ? this.state.teacher.value : null,
            disciplineName: this.state.discipline,
            id: this.state.id
        });
        this.props.hide("editLessonInfo")
    };
    loadOptions = inputValue => fetchTeachers4Select(inputValue);

    constructor(props) {
        super(props);
        this.state = {
            discipline: props.lesson.discipline,
            teacher: {value: props.lesson.teacherId, label: props.lesson.teacherFullName},
            id: props.lesson.id
        }
    }

    render() {
        const {lesson, show, hide} = this.props;
        return (
            <PureModal
                header={"Детальна інформація про предмет"}
                footer={<div className={"float-right"}>
                    <Button color={"red"} icon onClick={() => hide("editLessonInfo")}>
                        <Icon name='remove'/> Закрити
                    </Button>
                    <Button primary onClick={this.handleSave}>
                        <Icon name='save'/> Зберегти
                    </Button>
                </div>}
                width={"50vw"}
                isOpen={show}
                onClose={() => {
                    this.props.hide("editLessonInfo");
                }}
            >
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell textAlign={"center"} verticalAlign={"middle"}>
                                Предмет
                            </Table.Cell>
                            <Table.Cell>
                                <input className={"form-control w-100"} value={this.state.discipline}
                                       onChange={e => this.setState({discipline: e.target.value})}/>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell textAlign={"center"} verticalAlign={"middle"}>
                                Викладач
                            </Table.Cell>
                            <Table.Cell>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={this.loadOptions}
                                    noOptionsMessage={NoOptionsMessage}
                                    loadingMessage={loadingMessage}
                                    isClearable={false}
                                    defaultValue={this.state.teacher}
                                    onChange={(selectedValue) => this.setState({teacher: selectedValue})}
                                    placeholder={"Введіть ПІП викладача"}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell textAlign={"center"} verticalAlign={"middle"}>
                                Пари
                            </Table.Cell>
                            <Table.Cell textAlign={"center"} verticalAlign={"middle"}>
                                {lesson.number + lesson.maxNumber}
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}


export default compose(
    connectModal({
        name: 'editLessonInfo',
        getModalState: (state) => state.get("modal")
    }),
    connect(null, {
        hide,
        editLessonInfo
    })
)(EditLessonInfoModal);
