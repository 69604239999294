import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {DELETE_TEACHER, EDIT_TEACHERS_INFO, FETCH_TEACHERS, INSERT_TEACHER} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchEmails(inputValue) {
    if (!inputValue) {
        return;
    }
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/teachers/emails`, {
        params: {inputValue},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return request.then(({data}) => {
        return data;
    })

}

export function fetchTeachers() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/teachers`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHERS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }
}


export function editTeachersInfo(teacher) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/university/teachers/edit`, teacher, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_TEACHERS_INFO, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertTeacher(teacher) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/university/teacher/create`, teacher, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_TEACHER, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteTeacher(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/university/teacher/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_TEACHER, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}




