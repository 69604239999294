import React, {Component} from "react";
import {clearUsers, editUserInfo, fetchUsersByValue, getUser} from "./actions";
import {connect} from "react-redux";
import Search from "semantic-ui-react/dist/es/modules/Search/Search";
import "./styles.css";
import debounce from "lodash.debounce";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {fetchDepartments} from "../../../University/Departments/actions";
import {fetchRoles} from "../../../Roles/actions";
import Select from "react-select";

class Users extends Component {
    state = {isLoading: false, value: ""};
    resetComponent = () => {
        this.props.clearUsers();
        this.setState({isLoading: false, value: ''});
    };
    handleResultSelect = (e, {result}) => {
        this.props.getUser(result.id);
        this.setState({value: result.title});
    };
    handleSearchChange = (e, {value}) => {
        this.setState({isLoading: true, value});

        setTimeout(() => {
            if (this.state.value.length < 1) {
                return this.resetComponent()
            }
            this.props.fetchUsersByValue(value);

            this.setState({
                isLoading: false,
            })
        }, 300)
    };

    componentDidMount() {
        this.props.fetchRoles();
        this.props.fetchDepartments();
    }

    componentWillMount() {
        this.resetComponent()
    }

    render() {
        const {user} = this.props;
        const id = user ? user.get("id") : "";
        return (
            <div>
                <div>
                    <Search
                        loading={false}
                        results={this.props.suggestions.toArray()}
                        onResultSelect={this.handleResultSelect}
                        onSearchChange={debounce(this.handleSearchChange, 700, {leading: true})}
                        noResultsMessage={"Немає даних"}
                        size={"huge"}
                        className={"input-width"}
                        value={this.state.value}
                        placeholder={"Введіть ПІП користувача чи його пошту @oa.edu.ua"}
                        minCharacters={3}
                    /></div>
                <br/>
                <div style={{overflow: "auto", height: "70vh"}}>
                    {user.size > 0 && <Table className={"table table-bordered"}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell className={"w-10"}>
                                    Прізвище
                                </Table.Cell>
                                <Table.Cell>{user.get("lastName")}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"w-10"}>
                                    Ім'я
                                </Table.Cell>
                                <Table.Cell>{user.get("name")}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"w-10"}>
                                    По-батькові
                                </Table.Cell>
                                <Table.Cell>{user.get("middleName")}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"w-10"}>
                                    Пошта <em>(@oa.edu.ua)</em>
                                </Table.Cell>
                                <Table.Cell>{user.get("email")}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"w-10"}>
                                    Ролі
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={this.props.roles.toArray()}
                                        onChange={(selectedValue) => {
                                            const roles = selectedValue.map(i => i.id);
                                            this.props.editUserInfo({
                                                id,
                                                "roles": roles.filter(i => i).join(",")
                                            }, "roles", selectedValue)
                                        }}
                                        defaultValue={user.get("roles")}
                                        noResultsText={"Немає даних"}
                                        isMulti
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        placeholder={"Виберіть ролі користувача..."}
                                    />

                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell className={"w-10"}>
                                    Кафедра
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={this.props.departments.toArray()}
                                        onChange={(selectedValue) => {
                                            this.props.editUserInfo({
                                                    id,
                                                    "departmentId": selectedValue.id,
                                                },
                                                "departmentId", selectedValue
                                            )
                                        }}
                                        value={user.get("departmentId")}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => option.id}
                                        noResultsText={"Немає даних"}
                                        placeholder={"Виберіть  кафедру..."}
                                    />

                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>}
                </div>


            </div>
        );

    }
}

function mapStateToProps(state) {
    return {
        suggestions: state.get("users").get("suggestions"),
        user: state.get("users").get("user"),
        roles: state.get("roles"),
        departments: state.get("departments"),
    }
}

export default connect(mapStateToProps, {
    fetchUsersByValue,
    clearUsers,
    getUser,
    fetchRoles,
    fetchDepartments,
    editUserInfo,
})(Users)

