import React, {Component} from "react";
import {connect} from "react-redux";
import {deleteDisciplinesCycle, fetchDisciplinesCycles} from "./actions";
import Loader from "semantic-ui-react/dist/es/elements/Loader/Loader";
import {writeErrors} from "../../../utils/logs";
import {loadingText, nextText, noDataText, ofText, pageText, previousText, rowsText} from "../../../utils/locale";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import DisciplinesCyclesModal from "./components/DisciplinesCyclesModal";
import {show} from "redux-modal";

class DisciplinesCycles extends Component {


    componentDidMount() {
        this.props.fetchDisciplinesCycles();
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    handleAddDisciplinesCycle() {
        this.props.show("disciplinesCyclesModal", {isCreate: true});
    }

    handleEditDisciplinesCycleInfo(row) {
        this.props.show("disciplinesCyclesModal", {row, isCreate: false});
    }

    handleDeleteDisciplinesCycle(id) {
        this.props.deleteDisciplinesCycle(id);
    }


    render() {
        if (this.props.rows.size < 1) {
            return (<Loader active size={"massive"} content='Завантаження циклів дисциплін'/>)
        }
        return (
            <div>
                <DisciplinesCyclesModal/>
                <ReactTable
                    data={this.props.rows.toArray()}
                    filterable
                    columns={[

                        {
                            Header: "Назва",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["name"]}),
                            filterAll: true,
                            accessor: "name",
                        }, {
                            Header: "Скорочена назва",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["shortName"]}),
                            filterAll: true,
                            accessor: "shortName",
                        }, {
                            Header: "В архів",
                            filterMethod: (filter, rows) =>
                                matchSorter(rows, filter.value, {keys: ["isArchived"]}),
                            filterAll: true,
                            accessor: "isArchived",
                            className: "text-center"
                        },
                        {

                            Header: <Popup
                                trigger={
                                    <Icon name='add' size={"big"}
                                          color={"green"}
                                          onClick={
                                              this.handleAddDisciplinesCycle.bind(this)}/>
                                }
                                content='Додати запис'
                                on={'hover'}
                                style={{zIndex: 3001}}
                            />,
                            accessor: "id",
                            width: 100,
                            Cell: ({row}) => {
                                return (<div style={{marginTop: "15px"}}>

                                    <Popup
                                        trigger={<Icon name={"edit"}
                                                       color={"blue"}
                                                       onClick={this.handleEditDisciplinesCycleInfo.bind(this, row)}
                                        />}
                                        content="Редагувати інформацію"
                                        hideOnScroll
                                        on='hover'
                                    />
                                    <Popup
                                        trigger={<Icon name={"trash"}
                                                       color={"red"}
                                                       onClick={this.handleDeleteDisciplinesCycle.bind(this, row._original.id)}
                                        />}
                                        content="Видалити запис"
                                        hideOnScroll
                                        on='hover'
                                    />
                                </div>)
                            },
                            sortable: false,
                            filterable: false,
                            style: {
                                cursor: "pointer",
                                fontSize: 25,
                                padding: "0",
                                textAlign: "center",
                                userSelect: "none"
                            }
                        },
                    ]}
                    defaultPageSize={10}
                    style={{
                        height: "80vh",
                    }}
                    className="table-bordered table-striped white-bg"
                    noDataText={noDataText}
                    previousText={previousText}
                    nextText={nextText}
                    loadingText={loadingText}
                    pageText={pageText}
                    ofText={ofText}
                    rowsText={rowsText}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        rows: state.get("disciplinesCycles")
    }
}

export default connect(mapStateToProps, {
    deleteDisciplinesCycle,
    fetchDisciplinesCycles,
    show
})(DisciplinesCycles)