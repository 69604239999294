import React, {Component} from "react";
import PureModal from 'react-pure-modal';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import DatePicker from 'react-date-picker';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {NoOptionsMessage} from "../../../utils";
import Select from "react-select";
import {compose} from "redux";
import {connectModal, hide} from "redux-modal";
import {connect} from "react-redux";
import {editUserInfo} from "../actions";

class UserInfoModal extends Component {
    constructor(props) {
        super(props);
        const row = props.row._original;


        this.state = {
            id: row.id,
            isCreate: row.isCreate,
            userInfo: row.userInfo,
            gender: row.genderValue,
            registrations: row.registrations,
            studentInfo: row.studentInfo,
            documents: row.documents,
            scholarships: row.scholarships,
            preferentialCategories: row.preferentialCategories,
            dateBirth: row.dateBirthValue ? new Date(row.dateBirthValue) : new Date(1991, 1, 1),
        }
    }

    handleSave = () => {
        const object = {
            id: this.state.id,
            userInfo: this.state.userInfo,
            studentInfo: this.state.studentInfo,
            registrations: this.state.registrations,
            dateBirth: this.state.dateBirth,
            genderValue: this.state.gender,
            documents: this.state.documents,
            scholarships: this.state.scholarships,
            preferentialCategories: this.state.preferentialCategories,
        };

        this.props.editUserInfo(object);
        this.props.hide("userInfoModal")
    };

    handleAddUserRegistration() {
        let {registrations} = this.state;
        registrations.push({
            postCode: "",
            region: "",
            district: "",
            settlement: "",
            street: "",
            house: "",
            apartment: "",
            comment: "",
        });
        this.setState({registrations})
    };

    handleAddDocument() {
        let {documents} = this.state;
        documents.push({
            postCode: 0,
            number: 0,
            dateIssue: new Date(1991, 0, 1)
        });
        this.setState({documents})
    };

    handleRemoveUser(index) {
        let {userInfo} = this.state;
        const user = userInfo[index];
        if (!user.isReadonly) {
            userInfo.splice(index, 1);
        }
        this.setState({userInfo})
    };

    handleAddUser() {
        let {userInfo} = this.state;
        const oldUserInfo = userInfo[userInfo.length - 1];
        const newUserInfo = [{
            name: oldUserInfo.name,
            middleName: oldUserInfo.middleName,
            lastName: oldUserInfo.lastName,
            isReadonly: false
        }, ...userInfo];
        this.setState({userInfo: newUserInfo})
    };

    onStudentInfoEdit(name, value) {
        let {studentInfo} = this.state;
        studentInfo[name] = value;
        this.setState({studentInfo});
    };

    onUserInfoEdit(index, name, value) {
        let {userInfo} = this.state;
        userInfo[index][name] = value;
        this.setState({userInfo});
    };

    onDocumentEdit(index, name, value) {
        let {documents} = this.state;
        documents[index][name] = value;
        this.setState({documents})
    };

    onRegistrationsEdit(index, name, value) {
        let {registrations} = this.state;
        registrations[index][name] = value;
        this.setState({registrations})
    };

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={`Редагування інформації про користувача ${this.props.row._original.email}`}
                width={"95vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("userInfoModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                onClose={() => {
                    this.props.hide("userInfoModal");
                }}
                isOpen={show}
            >
                <Tabs>
                    <TabList>
                        <Tab>Загальна</Tab>
                        <Tab>Реєстрація</Tab>
                        <Tab>Документи</Tab>
                        <Tab>Стипендії</Tab>
                        <Tab>Пільги</Tab>
                        <Tab>Датальна інформація</Tab>
                    </TabList>
                    <TabPanel>
                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Прізвище</Table.HeaderCell>
                                    <Table.HeaderCell>Ім'я</Table.HeaderCell>
                                    <Table.HeaderCell>По батькові</Table.HeaderCell>
                                    <Table.HeaderCell>Дата народження</Table.HeaderCell>
                                    <Table.HeaderCell>Стать</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <Icon name='add' size={"big"}
                                              color={"green"}
                                              onClick={
                                                  this.handleAddUser.bind(this)}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.userInfo.map((user, index) => {
                                    return (
                                        <Table.Row key={`row_${index}`}>
                                            <Table.Cell>
                                                <input
                                                    onChange={e => this.onUserInfoEdit(index, "lastName", e.target.value)}
                                                    className={"form-control"}
                                                    value={user.lastName}
                                                    readOnly={user.isReadonly}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <input
                                                    onChange={e => this.onUserInfoEdit(index, "name", e.target.value)}
                                                    className={"form-control"}
                                                    value={user.name}
                                                    readOnly={user.isReadonly}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <input
                                                    onChange={e => this.onUserInfoEdit(index, "middleName", e.target.value)}
                                                    value={user.middleName}
                                                    readOnly={user.isReadonly}
                                                    className={"form-control"}
                                                />
                                            </Table.Cell>
                                            {index === 0 && <Table.Cell rowSpan={this.state.userInfo.length}>
                                                <DatePicker
                                                    onChange={(value) => this.setState({dateBirth: value})}
                                                    locale={"uk-UA"}
                                                    value={this.state.dateBirth}
                                                />
                                            </Table.Cell>}
                                            {index === 0 && <Table.Cell rowSpan={this.state.userInfo.length}>
                                                <Select
                                                    options={[{label: "Чоловіча", value: 1}, {
                                                        label: "Жіноча",
                                                        value: 2
                                                    }]}
                                                    onChange={(selected) => {
                                                        this.setState({gender: selected})
                                                    }}
                                                    defaultValue={this.state.gender}
                                                    noOptionsMessage={NoOptionsMessage}
                                                    isClearable={false}
                                                    placeholder={"Стать користувача"}
                                                />
                                            </Table.Cell>}
                                            <Table.Cell>
                                                <Icon name='trash' size={"big"}
                                                      color={"red"}
                                                      onClick={
                                                          this.handleRemoveUser.bind(this, index)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}

                            </Table.Body>
                        </Table>
                    </TabPanel>
                    <TabPanel>
                        <Table celled textAlign={"center"}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Індекс</Table.HeaderCell>
                                    <Table.HeaderCell>Область</Table.HeaderCell>
                                    <Table.HeaderCell>Район</Table.HeaderCell>
                                    <Table.HeaderCell>Місто</Table.HeaderCell>
                                    <Table.HeaderCell>Вулиця</Table.HeaderCell>
                                    <Table.HeaderCell>Будинок</Table.HeaderCell>
                                    <Table.HeaderCell>Квартира</Table.HeaderCell>
                                    <Table.HeaderCell>Коментар</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <Icon name='add' size={"big"}
                                              color={"green"}
                                              onClick={
                                                  this.handleAddUserRegistration.bind(this)}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    this.state.registrations.map((registration, index) => {
                                        return (<Table.Row key={`row_${index}`}>
                                                <Table.Cell>
                                                    <input
                                                        onChange={e => this.onRegistrationsEdit(index, "postCode", e.target.value)}
                                                        className={"form-control"}
                                                        value={registration.postCode}
                                                    />
                                                </Table.Cell>


                                                <Table.Cell>
                                                    <input
                                                        onChange={e => this.onRegistrationsEdit(index, "region", e.target.value)}
                                                        className={"form-control"}
                                                        value={registration.region}
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <input
                                                        onChange={e => this.onRegistrationsEdit(index, "district", e.target.value)}
                                                        className={"form-control"}
                                                        value={registration.district}
                                                    />
                                                </Table.Cell>


                                                <Table.Cell>
                                                    <input
                                                        onChange={e => this.onRegistrationsEdit(index, "settlement", e.target.value)}
                                                        className={"form-control"}
                                                        value={registration.settlement}
                                                    />
                                                </Table.Cell>


                                                <Table.Cell>
                                                    <input
                                                        onChange={e => this.onRegistrationsEdit(index, "street", e.target.value)}
                                                        className={"form-control"}
                                                        value={registration.street}
                                                    />
                                                </Table.Cell>


                                                <Table.Cell>
                                                    <input
                                                        onChange={(e) => this.onRegistrationsEdit(index, "house", e.target.value)}
                                                        value={registration.house}
                                                        className={"form-control"}
                                                    />
                                                </Table.Cell>


                                                <Table.Cell>
                                                    <input
                                                        onChange={(e) => this.onRegistrationsEdit(index, "apartment", e.target.value)}
                                                        value={registration.apartment}
                                                        className={"form-control"}
                                                    />
                                                </Table.Cell>


                                                <Table.Cell colSpan={2}>
                                        <textarea
                                            onChange={e => this.onRegistrationsEdit(index, "comment", e.target.value)}
                                            value={registration.comment}
                                            className={"form-control"}
                                        />
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </Table>
                    </TabPanel>
                    <TabPanel>
                        <Table celled textAlign={"center"} compact={"very"}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Назва</Table.HeaderCell>
                                    <Table.HeaderCell>Серія</Table.HeaderCell>
                                    <Table.HeaderCell>Номер</Table.HeaderCell>
                                    <Table.HeaderCell>Дата видачі</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <Icon name='add' size={"big"}
                                              color={"green"}
                                              onClick={
                                                  this.handleAddDocument.bind(this)}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.documents.map((document, index) => {
                                    return (<Table.Row key={`row_${index}`}>
                                            <Table.Cell>
                                                <Select
                                                    options={this.props.userDocumentTypes.toArray()}
                                                    onChange={(selected) => {
                                                        this.onDocumentEdit(index, "type", selected)
                                                    }}
                                                    getOptionValue={(option) => option.id}
                                                    getOptionLabel={(option) => option.name}
                                                    defaultValue={document.type}
                                                    noOptionsMessage={NoOptionsMessage}
                                                    isClearable={false}
                                                    placeholder={"Вид документа"}
                                                />
                                            </Table.Cell>
                                            <Table.Cell className={"w-15"}>
                                                <input
                                                    onChange={e => this.onDocumentEdit(index, "series", e.target.value)}
                                                    className={"form-control"}
                                                    defaultValue={document.series}
                                                />
                                            </Table.Cell>


                                            <Table.Cell className={"w-15"}>
                                                <input
                                                    onChange={e => this.onDocumentEdit(index, "number", e.target.value)}
                                                    className={"form-control"}
                                                    defaultValue={document.number}
                                                />
                                            </Table.Cell>
                                            <Table.Cell className={"w-15"}>
                                                <DatePicker
                                                    onChange={(value) => this.onDocumentEdit(index, "dateIssue", value)}
                                                    locale={"uk-UA"}
                                                    value={document.dateIssue}
                                                />
                                            </Table.Cell>

                                        </Table.Row>
                                    )
                                })}
                            </Table.Body>
                        </Table>
                    </TabPanel>
                    <TabPanel>
                        <Table celled textAlign={"center"} compact={"very"}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Стипендії</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        <Select
                                            options={this.props.scholarships.toArray()}
                                            onChange={(selected) => {
                                                this.setState({scholarships: selected})
                                            }}
                                            isMulti
                                            getOptionValue={(option) => option.id}
                                            getOptionLabel={(option) => option.name}
                                            defaultValue={this.state.scholarships}
                                            noOptionsMessage={NoOptionsMessage}
                                            isClearable={false}
                                            placeholder={"Стипендії студента"}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </TabPanel>
                    <TabPanel>
                        <Table celled textAlign={"center"} compact={"very"}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Пільги</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        <Select
                                            options={this.props.preferentialCategories.toArray()}
                                            onChange={(selected) => {
                                                this.setState({preferentialCategories: selected})
                                            }}
                                            isMulti
                                            getOptionValue={(option) => option.id}
                                            getOptionLabel={(option) => option.name}
                                            defaultValue={this.state.preferentialCategories}
                                            noOptionsMessage={NoOptionsMessage}
                                            isClearable={false}
                                            placeholder={"Пільги студента"}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </TabPanel>
                    <TabPanel>
                        <Table celled>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        Номер телефону
                                    </Table.Cell>
                                    <Table.Cell>
                                        <input
                                            onChange={e => this.onStudentInfoEdit("studentPhoneNumber", e.target.value)}
                                            className={"form-control"}
                                            value={this.state.studentInfo.studentPhoneNumber}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        ПІП мами
                                    </Table.Cell>
                                    <Table.Cell>
                                        <input
                                            onChange={e => this.onStudentInfoEdit("momsFullName", e.target.value)}
                                            className={"form-control"}
                                            value={this.state.studentInfo.momsFullName}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Тел. мами
                                    </Table.Cell>
                                    <Table.Cell>
                                        <input
                                            onChange={e => this.onStudentInfoEdit("momsPhoneNumber", e.target.value)}
                                            className={"form-control"}
                                            value={this.state.studentInfo.momsPhoneNumber}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        ПІП батька
                                    </Table.Cell>
                                    <Table.Cell>
                                        <input
                                            onChange={e => this.onStudentInfoEdit("fathersFullName", e.target.value)}
                                            className={"form-control"}
                                            value={this.state.studentInfo.fathersFullName}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>
                                        Тел. батька
                                    </Table.Cell>
                                    <Table.Cell>
                                        <input
                                            onChange={e => this.onStudentInfoEdit("fathersPhoneNumber", e.target.value)}
                                            className={"form-control"}
                                            value={this.state.studentInfo.fathersPhoneNumber}
                                        />
                                    </Table.Cell>
                                </Table.Row>

                            </Table.Body>
                        </Table>
                    </TabPanel>
                </Tabs>

            </PureModal>
        )
    }
}

function mapStateToProps(state) {
    return {
        preferentialCategories: state.get("preferentialCategories"),
        scholarships: state.get("scholarships"),
        userDocumentTypes: state.get("userDocumentTypes"),
    }
}

export default compose(
    connectModal({
        name: 'userInfoModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editUserInfo
    }))(UserInfoModal);



