import {ROOT_URL,} from "../../../utils";
import {
    DELETE_TEACHER_LOAD,
    EDIT_TEACHER_LOAD_INFO,
    EDIT_TEACHER_LOAD_ITEM,
    EDIT_TEACHER_LOADS,
    FETCH_TEACHER_LOAD,
    FETCH_TEACHER_LOAD_RATES,
    FETCH_TEACHER_LOAD_STATISTICS,
    FETCH_TEACHER_LOADS,
    FETCH_TEACHERS_TO_LOAD,
    INSERT_TEACHER_LOAD_ITEM,
} from "./types";
import axios from "axios/index";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";

export function fetchTeacherLoad() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/documents/teacher-load`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHER_LOAD, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }
}

export function fetchTeacherLoads(ids) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/documents/teacher-loads`, {
        params: {ids},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHER_LOADS, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }
}


export function fetchTeacherLoadStatistics(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/documents/teacher-load/statistics`, {
        params: id,
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHER_LOAD_STATISTICS, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }
}

export function fetchTeachers2Load() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/documents/department-teachers`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHERS_TO_LOAD, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }
}

export function editTeacherLoadInfo(row) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/documents/teacher-load/edit`, row, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_TEACHER_LOAD_INFO, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function editTeacherLoads(rows) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/documents/teacher-loads/edit`, rows, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_TEACHER_LOAD_ITEM, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertHourlyTeacherLoad(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/documents/teacher-load/createHourly`, null, {
        params: {id},
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_TEACHER_LOAD_ITEM, payload: data, id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteTeacherLoadItem(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/documents/teacher-load/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_TEACHER_LOAD, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function fetchTeacherRates() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/documents/teacher-load/rates`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHER_LOAD_RATES, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function editTeacherLoadItems(rows) {
    return (dispatch) => {
        dispatch({type: EDIT_TEACHER_LOADS, payload: rows})
    }

}
