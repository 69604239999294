import {Map,List} from "immutable"
import {DELETE_STUDY_PLAN_ITEM, EDIT_STUDY_PLAN_INFO, FETCH_STUDY_PLAN, INSERT_STUDY_PLAN_ITEM} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_STUDY_PLAN:
            return List(action.payload);
        case EDIT_STUDY_PLAN_INFO:
            return Map(action.payload);
        case INSERT_STUDY_PLAN_ITEM:
            return Map(action.payload);
        case DELETE_STUDY_PLAN_ITEM:
            return Map(action.payload);
        default:
            return state;
    }
}
