import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {
    DELETE_USER_DOCUMENT_TYPE,
    EDIT_USER_DOCUMENT_TYPE_INFO,
    FETCH_USER_DOCUMENT_TYPES,
    INSERT_USER_DOCUMENT_TYPE
} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchUserDocumentTypes() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/user/document-types`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request
            .then(({data}) => {
                dispatch({type: FETCH_USER_DOCUMENT_TYPES, payload: data})

            }).catch((error) => {
            writeErrors(error);
        });
    }

}



export function editUserDocumentTypeInfo(userDocumentType) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/user/document-types/edit`, userDocumentType, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_USER_DOCUMENT_TYPE_INFO, payload: userDocumentType})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertUserDocumentType(userDocumentType) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/user/document-type/create`, userDocumentType, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_USER_DOCUMENT_TYPE, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteUserDocumentType(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/user/document-type/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_USER_DOCUMENT_TYPE, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


