import {List, Map, merge} from "immutable"
import {
    DELETE_TEACHER_LOAD,
    EDIT_TEACHER_LOAD_INFO,
    EDIT_TEACHER_LOAD_ITEM,
    EDIT_TEACHER_LOADS,
    FETCH_TEACHER_LOAD,
    FETCH_TEACHER_LOAD_RATES,
    FETCH_TEACHER_LOAD_STATISTICS,
    FETCH_TEACHER_LOADS,
    FETCH_TEACHERS_TO_LOAD,
    INSERT_TEACHER_LOAD_ITEM
} from "./types"


const initialState = Map({
    tableHeader: List(),
    tableBody: List(),
    teachers: List(),
    rates: List(),
    statisticsHeader: List(),
    rows: List(),
    statistics: Map({
        teacher: "",
        table: [],
        header: [],
        statistics: [],
    })
});
export default function (state = initialState, action) {
    let index;
    let rows;
    const tableBody = state.get("tableBody");
    switch (action.type) {
        case FETCH_TEACHER_LOAD:
            const {header, body} = action.payload;
            return Map({
                tableHeader: List(header),
                tableBody: List(body),
                teachers: List(),
                rates: List(),
                rows: List(),
                statisticsHeader: List(),
                statistics: Map({
                    teacher: "",
                    table: [],
                    statistics: [],
                    header: [],
                })
            });
        case FETCH_TEACHERS_TO_LOAD:
            return state.set("teachers", List(action.payload));
        case FETCH_TEACHER_LOAD_RATES:
            return state.set("rates", List(action.payload));
        case FETCH_TEACHER_LOADS:
            return state.set("rows", List(action.payload));
        case FETCH_TEACHER_LOAD_STATISTICS:
            return state.set("statistics", Map(action.payload));
        case EDIT_TEACHER_LOADS:
            return state.set("rows", List(action.payload));
        case EDIT_TEACHER_LOAD_ITEM:
            index = tableBody.findIndex(i => i.id === action.payload.id);
            const row = tableBody.get(index);
            action.payload.hasHourlyLoad = row.hasHourlyLoad;
            rows = tableBody.update(index, r => merge(r, action.payload));
            return state.set("tableBody", rows);
        case EDIT_TEACHER_LOAD_INFO:
            index = tableBody.findIndex(i => i.id === action.payload.id);
            rows = tableBody.set(index, action.payload);
            return state.set("tableBody", rows);
        case INSERT_TEACHER_LOAD_ITEM:
            index = tableBody.findIndex(i => i.id === action.id);
            rows = tableBody.insert(index + 1, action.payload);
            rows = rows.update(index, r => merge(r, {hasHourlyLoad: "true"}));
            return state.set("tableBody", rows);
        case DELETE_TEACHER_LOAD:
            index = tableBody.findIndex(i => i.id === action.payload);
            rows = tableBody.delete(index);
            rows = rows.update((index - 1), r => merge(r, {hasHourlyLoad: "false"}));
            return state.set("tableBody", rows);
        default:
            return state;
    }
}