import {List} from "immutable";
import {DELETE_WEEKEND, EDIT_WEEKEND_INFO, FETCH_WEEKENDS, INSERT_WEEKEND} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_WEEKENDS:
            return List(action.payload);
        case EDIT_WEEKEND_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case INSERT_WEEKEND:
            return state.insert(0, action.payload);
        case DELETE_WEEKEND:
            return state.delete(state.findIndex(i => i.id === action.payload));
        default:
            return state;
    }
}