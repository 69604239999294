export const FETCH_UNIVERSITY_GROUPS = "FETCH_UNIVERSITY_GROUPS";


export const GENERATE_UNIVERSITY_GROUP = "GENERATE_UNIVERSITY_GROUP";

export const DELETE_UNIVERSITY_GROUP = "DELETE_UNIVERSITY_GROUP";

export const EDIT_UNIVERSITY_GROUP_INFO = "EDIT_UNIVERSITY_GROUP_INFO";


