import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {fetchTeacherLoadStatistics} from "../../../../actions";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";

class TeacherRateAccordion extends PureComponent {
    renderTeacherName = (teacher) => {
        return (<Table.Row>
            <Table.Cell negative colSpan={5}>{teacher}</Table.Cell>
        </Table.Row>)
    };
    renderTeacherRates = (teacherRate) => {
        return (<Table.Row>
            <Table.Cell>{teacherRate.department}</Table.Cell>
            <Table.Cell>{teacherRate.normHours}</Table.Cell>
            <Table.Cell>{teacherRate.rateValue}</Table.Cell>
            <Table.Cell>{teacherRate.rateHourly}</Table.Cell>
            <Table.Cell>{teacherRate.date}</Table.Cell>
        </Table.Row>)
    };

    render() {

        return (
            <Table celled textAlign={"center"} verticalAlign={"middle"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Кафедра</Table.HeaderCell>
                        <Table.HeaderCell>Норма годин </Table.HeaderCell>
                        <Table.HeaderCell>Ставка</Table.HeaderCell>
                        <Table.HeaderCell>Погодинка</Table.HeaderCell>
                        <Table.HeaderCell>Початок-Кінець</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.props.rates.map(row => {
                        return row.teacher ? this.renderTeacherName(row.teacher) : this.renderTeacherRates(row)
                    })}
                </Table.Body>
            </Table>
        );
    }
}

function mapStateToProps(state) {
    return {
        rates: state.get("teacherLoad").get("rates"),
    }
}

export default connect(mapStateToProps, {
    fetchTeacherLoadStatistics
})(TeacherRateAccordion);

