import {List} from "immutable"
import {DELETE_DEPARTMENT, EDIT_DEPARTMENT_INFO, FETCH_DEPARTMENTS, INSERT_DEPARTMENT} from "./types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_DEPARTMENTS:
            return List(action.payload);
        case EDIT_DEPARTMENT_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case INSERT_DEPARTMENT:
            return state.insert(0, action.payload);
        case DELETE_DEPARTMENT:
            return state.delete(state.findIndex(i => i.id === action.payload));
        default:
            return state;
    }
}