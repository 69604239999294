import React, {PureComponent} from "react";
import {connect} from "react-redux";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import ReactToPrint from "react-to-print";
import Select from "react-select";
import Timetable from "./components/Timetable";
import {getPagePrintStyle} from "../../printPageStyle";
import {NoOptionsMessage} from "../../../../utils";
import {writeErrors} from "../../../../utils/logs";
import {
    fetchSpecialities4SelectWithStudyLevel, fetchStudyYears4Select,
    fetchWeeks4Select,
    fetchWeeksBySemester
} from "../../../../components/Data4Select/actions";
import {fetchDepartmentDean, fetchFacultyTimetable} from "../../actions";

class FacultyTimetable extends PureComponent {
    state = {
        studyYear: null,
        week: null,
        specialities: localStorage.getItem('OA.UM-timetable_specialities') !== null && localStorage.getItem('OA.UM-timetable_specialities').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-timetable_specialities')) : [],
        year: localStorage.getItem('OA.UM-timetable_specialities_year') !== null && localStorage.getItem('OA.UM-timetable_specialities_year').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-timetable_specialities_year')) : {},
    };
    loadLessons = (object) => {
        localStorage.setItem('OA.UM-timetable_specialities', JSON.stringify(object.specialities));
        localStorage.setItem('OA.UM-timetable_specialities_week', JSON.stringify(object.week));
        localStorage.setItem('OA.UM-timetable_specialities_year', JSON.stringify(object.year));
        object.specialities = object.specialities.map(i => i.value).join(",");
        object.year = object.year.value || "";
        object.week = object.week.value || "";
        this.props.fetchFacultyTimetable(object);
        this.props.fetchDepartmentDean();
    };
    prevWeek = () => {
        let index = this.state.week ? this.props.weeks.findIndex(i => i.value === this.state.week.value) - 1 : this.props.weeks.findIndex(i => i.isSelected) - 1;
        if (index > -1) {
            const week = this.props.weeks.get(index);
            this.setState({
                week: week,

            });
            this.loadLessons({
                week: week,
                specialities: this.state.specialities,
                year: this.state.year
            })
        }
    };
    nextWeek = () => {
        let index = this.state.week ? this.props.weeks.findIndex(i => i.value === this.state.week.value) + 1 : this.props.weeks.findIndex(i => i.isSelected) + 1;
        if (index < this.props.weeks.size) {
            const week = this.props.weeks.get(index);
            this.setState({
                week: week,

            });
            this.loadLessons({
                week: week,
                specialities: this.state.specialities,
                year: this.state.year
            })
        }

    };
    renderTableFilters = (currentWeek,currentStudyYear) => {
        return (<div>
            <Table compact={"very"} className={"table table-bordered"}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell className={"text-center"}>Навчальний рік</Table.HeaderCell>
                        <Table.HeaderCell className={"text-center"}>Тижні</Table.HeaderCell>
                        <Table.HeaderCell className={"text-center"}>Спеціальності</Table.HeaderCell>
                        <Table.HeaderCell className={"text-center"}>Курс</Table.HeaderCell>
                        <Table.HeaderCell/>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <Select
                                options={this.props.studyYears.toArray()}
                                onChange={selectedValue => {
                                    let studyYear = null;
                                    if (selectedValue !== null) {
                                        studyYear = selectedValue;
                                    }
                                    this.setState({studyYear});
                                    this.props.fetchWeeksBySemester(selectedValue.value)
                                }}
                                value={currentStudyYear}
                                noOptionsMessage={NoOptionsMessage}
                                isClearable={false}
                                placeholder={"Виберіть навчальний рік"}
                            />
                        </Table.Cell>
                        <Table.Cell className={"w-30"}>
                            <div className={"w-100"}
                                 style={{
                                     display: "inline-flex",
                                     direction: "row"
                                 }}>
                                <div>
                                    <Button icon='left chevron'
                                            onClick={
                                                this.prevWeek
                                            }
                                            style={{
                                                padding: "0.78571429em 0.8571429em 1.1em",
                                                margin: "0"
                                            }}/>
                                </div>
                                <div style={{
                                    flex: "1 0 auto"
                                }}>
                                    <Select
                                        options={this.props.weeks.toArray()}
                                        onChange={selectedValue => {
                                            let week = null;
                                            if (selectedValue !== null) {
                                                week = selectedValue;
                                            }
                                            this.setState({week});
                                            this.loadLessons({
                                                week: week,
                                                specialities: this.state.specialities,
                                                year: this.state.year
                                            })
                                        }}
                                        value={this.state.week || currentWeek}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Виберіть навчальний тиждень"}
                                    />

                                </div>
                                <div>
                                    <Button icon='right chevron' onClick={
                                        this.nextWeek
                                    }
                                            style={{
                                                padding: "0.78571429em 0.8571429em 1em"
                                            }}/>
                                </div>
                            </div>
                        </Table.Cell>
                        <Table.Cell>
                            <Select
                                options={this.props.specialities.toArray()}
                                onChange={selectedValue => {
                                    this.setState({specialities: selectedValue});
                                    this.loadLessons({
                                        week: this.state.week || currentWeek,
                                        year: this.state.year,
                                        specialities: selectedValue
                                    })
                                }}
                                isMulti
                                value={this.state.specialities}
                                noOptionsMessage={NoOptionsMessage}
                                placeholder={"Виберіть спеціальності..."}
                            />

                        </Table.Cell>
                        <Table.Cell className={"w-15"}>
                            <Select
                                options={[
                                    {label: 1, value: 1},
                                    {label: 2, value: 2},
                                    {label: 3, value: 3},
                                    {label: 4, value: 4},
                                    {label: 5, value: 5},
                                    {label: 6, value: 6},
                                ]}
                                onChange={selectedValue => {
                                    this.setState({year: selectedValue});
                                    this.loadLessons({
                                        week: this.state.week || currentWeek,
                                        year: selectedValue,
                                        specialities: this.state.specialities,
                                    })
                                }}
                                value={this.state.year}
                                noOptionsMessage={NoOptionsMessage}
                                placeholder={"Виберіть курс..."}
                            />
                        </Table.Cell>
                        <Table.Cell textAlign={"center"} verticalAlign={"middle"}>
                            <ReactToPrint
                                trigger={() => <Button icon color={"teal"}> <Icon
                                    name={"print"}/> Друк</Button>}
                                content={() => this.componentRef}
                                copyStyles={false}
                                pageStyle={getPagePrintStyle()}
                            />

                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>);
    };

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    componentDidMount() {
        this.props.fetchStudyYears4Select();
        this.props.fetchWeeks4Select();
        this.props.fetchSpecialities4SelectWithStudyLevel();
        this.props.fetchDepartmentDean();
        this.props.fetchFacultyTimetable({
            specialities: localStorage.getItem('OA.UM-timetable_specialities') !== null && localStorage.getItem('OA.UM-timetable_specialities').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-timetable_specialities')).map(i => i.value).join(',') : null,
            year: localStorage.getItem('OA.UM-timetable_specialities_year') !== null && localStorage.getItem('OA.UM-timetable_specialities_year').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-timetable_specialities_year')).value : null
        });
    }

    render() {
        let weekValue = this.state.week;
        let studyYearValue = this.state.studyYear;
        if (!weekValue) {
            const filter = this.props.weeks.filter(i => i.isSelected);
            weekValue = filter.size > 0 ? filter.get(0) : this.props.weeks.get(0);
        }
        if (!studyYearValue) {
            const filter = this.props.studyYears.filter(i => i.isSelected);
            studyYearValue = filter.size > 0 ? filter.get(0) : this.props.studyYears.get(0);
        }
        if (this.props.lessons.size < 1) {
            return (
                <div>
                    {this.renderTableFilters(weekValue,studyYearValue)}
                    <div className="text-center">Немає даних</div>
                </div>
            )
        }
        const states = {...this.state};
        states.week = weekValue;
        states.studyYear = studyYearValue;
        return (
            <div style={{overflow: "auto", height: "78vh"}}>
                <div>
                    {this.renderTableFilters(weekValue,studyYearValue)}
                </div>
                <div>
                    <Timetable {...states} ref={el => (this.componentRef = el)}/>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        weeks: state.get("data4Select").get("weeksBySemester"),
        studyYears: state.get("data4Select").get("studyYears4Select"),
        specialities: state.get("data4Select").get("specialitiesWithStudyLevel"),
        lessons: state.get("timetable").get("faculty"),
    }
}

export default connect(mapStateToProps, {
    fetchWeeks4Select,
    fetchSpecialities4SelectWithStudyLevel,
    fetchFacultyTimetable,
    fetchDepartmentDean,
    fetchWeeksBySemester,
    fetchStudyYears4Select
})(FacultyTimetable);
