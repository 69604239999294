import axios from "axios";
import {FETCH_LESSON_SCHEDULE} from "./types";
import {ROOT_URL} from "../../../utils";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchLessonTimes() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/lessonTimes`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_LESSON_SCHEDULE, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}
