import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {
    DELETE_SPECIALITY,
    EDIT_SPECIALITY_INFO,
    FETCH_SPECIALITIES,
    INSERT_SPECIALITY,
} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchSpecialities() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/specialities`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_SPECIALITIES, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function editSpecialitiesInfo(speciality) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/university/specialities/edit`, speciality, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_SPECIALITY_INFO, payload: speciality})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertSpeciality(speciality) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/university/speciality/create`, speciality, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_SPECIALITY, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteSpeciality(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/university/speciality/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_SPECIALITY, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


