import axios from "axios";
import {
    DELETE_CALENDAR_PLAN_ITEM,
    EDIT_CALENDAR_PLAN_INFO,
    FETCH_CALENDAR_PLAN,
    INSERT_CALENDAR_PLAN_ITEM,
} from "./types";
import {ROOT_URL} from "../../../utils";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";

export function fetchCalendarPlan() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/documents/calendarPlan`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_CALENDAR_PLAN, payload: data});
        }).catch((error) => {
            writeErrors(error);
        });
    }
}


export function editCalendarPlanInfo( calendarPlanItem) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/documents/calendarPlanItem/edit`, calendarPlanItem, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_CALENDAR_PLAN_INFO, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertCalendarPlanItem(calendarPlanItem) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/documents/calendarPlanItem/create`, calendarPlanItem, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_CALENDAR_PLAN_ITEM, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteCalendarPlanItem(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/documents/calendarPlanItem/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_CALENDAR_PLAN_ITEM, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

