import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {List, merge} from "immutable";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import "./styles.css";
import {generateUniversityGroup} from "../../actions";
import {NoOptionsMessage} from "../../../../../utils";
import Select from "react-select";

const defaultGroup = {
    speciality: null,
    year: 1,
    countGroups: 1,
    countSubGroups: 0,
    disciplineShortName: '',
    isFacultyGroup: false,
    type: 0
};
const defaultSpecialGroup = {
    speciality: null,
    year: 1,
    countGroups: 1,
    countSubGroups: 0,
    disciplineShortName: '',
    isFacultyGroup: false,
    type: 1
};

class GroupsModal extends PureComponent {
    state = {
        groups: List([defaultGroup]),
        specialGroups: List([defaultSpecialGroup]),
        tabIndex: 0
    };

    handleSave = () => {
        const groups = this.state.tabIndex === 0 ? this.state.groups : this.state.specialGroups;
        this.props.generateUniversityGroup(
            groups
        );
        this.props.hide("groupsModal")
    };


    handleAddBasicGroupsRow = () => {
        let object = defaultGroup;
        object.type = 0;
        const groups = this.state.groups.insert(0, object);
        this.setState({
            groups
        });
    };
    renderGroups = (specialities) => {
        return this.state.groups.map((row, i) => {
            return <Table.Row key={`row_${i}`}>
                <Table.Cell className={"w-30"}>
                    <Select
                        options={specialities}
                        onChange={selectedValue => {
                            if (selectedValue) {
                                const groups =
                                    this.state.groups.update(i, r => merge(r,
                                        {speciality: selectedValue.value}));
                                this.setState({groups});
                            }
                        }}
                        noOptionsMessage={NoOptionsMessage}
                        isClearable={false}
                        placeholder={"Вкажіть спеціальность..."}
                    />
                </Table.Cell>
                <Table.Cell>
                    <input type={"number"} className={"form-control"} min={1} placeholder='Курс' value={row.year}
                           onChange={(e) => {
                               const groups = this.state.groups.update(i, r => merge(r, {
                                   year: e.target.value
                               }));
                               this.setState({groups});
                           }}/>
                </Table.Cell>
                <Table.Cell>
                    <input className={"form-control"} type={"number"} min={1} placeholder='К-сть груп'
                           value={row.countGroups}
                           onChange={(e) => {
                               const groups = this.state.groups.update(i, r => merge(r, {
                                   countGroups: e.target.value
                               }));
                               this.setState({groups});
                           }}/>
                </Table.Cell>
                <Table.Cell>
                    <input className={"form-control"} type={"number"} placeholder='К-сть підгруп'
                           value={row.countSubGroups}
                           onChange={(e) => {
                               const groups = this.state.groups.update(i, r => merge(r, {
                                   countSubGroups: e.target.value
                               }));
                               this.setState({groups});
                           }}/>
                </Table.Cell>
                <Table.Cell className={"text-center w-1"}>
                    <Popup
                        trigger={<Button
                            id={i}
                            onClick={this.handleDeleteRow}
                            icon='trash'
                            color="red"/>}
                        content="Видалити запис"
                        hideOnScroll
                        on='hover'
                    />
                </Table.Cell>
            </Table.Row>
        })
    };
    renderSpecialGroups = (specialities) => {
        return this.state.specialGroups.map((row, i) => {
            return <Table.Row key={`row_${i}`}>
                <Table.Cell className={"w-30"}>
                    <Select
                        options={specialities}
                        onChange={selectedValue => {
                            if (selectedValue) {
                                const specialGroups = this.state.specialGroups.update(i, r => merge(r, {speciality: selectedValue.value}));
                                this.setState({specialGroups});
                            }
                        }}
                        noOptionsMessage={NoOptionsMessage}
                        isClearable={false}
                        placeholder={"Вкажіть спеціальность..."}
                    />
                </Table.Cell>
                <Table.Cell>
                    <input type={"number"} min={1} className={"form-control"} placeholder='Курс' value={row.year}
                           onChange={(e) => {
                               const specialGroups = this.state.specialGroups.update(i, r => merge(r, {
                                   year: e.target.value
                               }));
                               this.setState({specialGroups});
                           }}/>
                </Table.Cell>
                <Table.Cell className={"w-30"}>
                    <input className={"form-control"} min={1} placeholder='Скорочена назва предмету'
                           value={row.disciplineShortName}
                           onChange={(e) => {
                               const specialGroups =
                                   this.state.specialGroups.update(i, r => merge(r, {
                                       disciplineShortName: e.target.value
                                   }));
                               this.setState({specialGroups});
                           }}/>
                </Table.Cell>
                <Table.Cell>
                    <input type={"number"} className={"form-control"} placeholder='К-сть підгруп'
                           value={row.countGroups}
                           onChange={(e) => {
                               const specialGroups = this.state.specialGroups.update(i, r => merge(r, {
                                   countGroups: e.target.value
                               }));
                               this.setState({specialGroups});
                           }}/>
                </Table.Cell>
                <Table.Cell
                    style={{
                        textAlign: "center"
                    }}>
                    <div className="pretty p-default  p-curve p-fill" style={{
                        fontSize: "24px"
                    }}>
                        <input onChange={() => {
                            const specialGroups = this.state.specialGroups.update(i, r => merge(r, {isFacultyGroup: !row.isFacultyGroup}));
                            this.setState({specialGroups});
                        }
                        }
                               type="checkbox" defaultChecked={row.isFacultyGroup}/>
                        <div className="state p-info">
                            <label/>
                        </div>
                    </div>
                </Table.Cell>
                <Table.Cell className={"text-center w-1"}>
                    <Popup
                        trigger={<Button
                            id={i}
                            onClick={this.handleDeleteRow}
                            icon='trash'
                            color="red"/>}
                        content="Видалити запис"
                        hideOnScroll
                        on='hover'
                    />
                </Table.Cell>
            </Table.Row>;

        })
    };
    handleAddSpecialGroupsRow = () => {
        let object = defaultSpecialGroup;
        const specialGroups = this.state.specialGroups.insert(0, object);
        this.setState({
            specialGroups
        });
    };
    handleDeleteRow = (e, {id}) => {
        if (this.state.tabIndex === 0 && this.state.groups.size > 1) {
            const groups = this.state.groups.delete(id);
            this.setState({
                groups
            });
        } else if (this.state.specialGroups.size > 1) {
            const specialGroups = this.state.specialGroups.delete(id);
            this.setState({
                specialGroups
            });
        }
    };

    render() {
        const {show} = this.props;
        const specialities = this.props.specialities.toArray();
        return <PureModal
            header={"Механізм формування університетських груп"}
            width={"95vw"}
            footer={
                <div className={"float-right"}>
                    <Button color={"red"} icon onClick={() =>
                        this.props.hide("groupsModal")
                    }>
                        <Icon name='remove'/> Закрити
                    </Button>
                    <Button primary onClick={this.handleSave}>
                        <Icon name='save'/> Зберегти
                    </Button>
                </div>
            }
            isOpen={show}
            onClose={() => this.props.hide("groupsModal")}
        >
            <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({tabIndex})}>
                <TabList>
                    <Tab>Академічні групи</Tab>
                    <Tab>Нетипові групи(Інші)</Tab>
                </TabList>

                <TabPanel>
                    <Table celled compact striped

                    >
                        <Table.Header className={"text-center"}>
                            <Table.Row>
                                <Table.HeaderCell>Спеціальність</Table.HeaderCell>
                                <Table.HeaderCell>Курс</Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Popup
                                        trigger={<span>К-сть груп</span>}
                                        content="Кількість груп"
                                        hideOnScroll
                                        on='hover'
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Popup
                                        trigger={<span>К-сть підгруп</span>}
                                        content="Кількість підгруп"
                                        hideOnScroll
                                        on='hover'
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell className={"text-center w-1"}>
                                    <Popup
                                        trigger={<Button
                                            onClick={this.handleAddBasicGroupsRow}
                                            icon='add'
                                            color="green"/>}
                                        content="Додати запис"
                                        hideOnScroll
                                        on='hover'
                                    />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.renderGroups(specialities)
                            }
                        </Table.Body>
                    </Table>
                </TabPanel>
                <TabPanel>
                    <Table celled compact striped

                    >
                        <Table.Header className={"text-center"}>
                            <Table.Row>
                                <Table.HeaderCell>Спеціальність</Table.HeaderCell>
                                <Table.HeaderCell>Курс</Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Popup
                                        trigger={<span>Предмет</span>}
                                        content="Скорочена назва предмету"
                                        hideOnScroll
                                        on='hover'
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Popup
                                        trigger={<span>К-сть підгруп</span>}
                                        content="Кількість підгруп"
                                        hideOnScroll
                                        on='hover'
                                    /></Table.HeaderCell>

                                <Table.HeaderCell>
                                    <Popup
                                        trigger={<span>Факультету</span>}
                                        content="Об'єданання груп всього курсу факультету"
                                        hideOnScroll
                                        on='hover'
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell className={"text-center w-1"}>
                                    <Popup
                                        trigger={<Button
                                            onClick={
                                                this.handleAddSpecialGroupsRow
                                            }
                                            icon='add'
                                            color="green"/>}
                                        content="Додати запис"
                                        hideOnScroll
                                        on='hover'
                                    />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.renderSpecialGroups(specialities)
                            }
                        </Table.Body>
                    </Table>
                </TabPanel>
            </Tabs>
        </PureModal>;
    }
}

function mapStateToProps(state) {
    return {
        specialities: state.get("data4Select").get("specialitiesWithStudyLevel"),
    }
}

export default compose(
    connectModal({
        name: 'groupsModal',
        getModalState: state => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        generateUniversityGroup
    }))(GroupsModal);

