import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {
    DELETE_TEACHERS_CONSULTATION,
    EDIT_TEACHERS_CONSULTATION_INFO,
    FETCH_TEACHERS_CONSULTATIONS,
    INSERT_TEACHERS_CONSULTATION,
} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchTeachersConsultations() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/teachers/consultations`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHERS_CONSULTATIONS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function editTeacherConsultationInfo(consultation) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/teachers/consultations/edit`, consultation, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_TEACHERS_CONSULTATION_INFO, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertTeacherConsultation(teacherConsultation) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/teachers/consultation/create`, teacherConsultation, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_TEACHERS_CONSULTATION, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteTeacherConsultation(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/teachers/consultation/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_TEACHERS_CONSULTATION, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}
