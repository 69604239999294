import {List} from "immutable"
import {FETCH_LOGS} from "../../types";

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_LOGS:
            return List(action.payload);
        default:
            return state;
    }
}