import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {createLessonObject, fetchLessons, fetchOnlyLessons, updateFilters} from "../../actions";
import Divider from "semantic-ui-react/dist/es/elements/Divider/Divider";
import {NoOptionsMessage} from "../../../../utils";
import Select from "react-select";

class LessonsSettings extends PureComponent {

    handleSelectChange = (selectedValue) => {
        const key = `OA.UM-semesters`;
        const filter = {};
        createLessonObject(filter);
        localStorage.setItem(key, JSON.stringify(selectedValue));
        filter.semesters = selectedValue.map(i => i.value).join(',');
        this.props.updateFilters("semesters", selectedValue);
        this.props.fetchLessons(filter);
    };

    render() {
        const {showZeroRow, showMore5Rows, showSaturday, showSunday} = this.props;
        return (
            <div>
                <div className={"mb-3"}>
                    <div className="pretty p-default  p-curve p-fill" style={{fontSize: "16px"}}>
                        <input onChange={() => {
                            const {isVisibleGroups} = this.props;

                            if (isVisibleGroups) {
                                localStorage.removeItem('OA.UM-groups');
                                this.props.updateFilters("groups", null);
                            }
                            this.props.updateFilters("isVisibleGroups", !isVisibleGroups);
                            this.props.fetchOnlyLessons();
                        }}
                               type="checkbox" checked={this.props.isVisibleGroups}/>
                        <div className="state p-info">
                            <label>Групи</label>
                        </div>
                    </div>
                </div>

                <div className={"mb-3"}>
                    <div className="pretty p-default  p-curve p-fill" style={{fontSize: "16px"}}>
                        <input onChange={() => {
                            const {isVisibleTeacher} = this.props;

                            if (isVisibleTeacher) {
                                localStorage.removeItem('OA.UM-teacher');
                                this.props.updateFilters("teacher", null);

                            }
                            this.props.updateFilters("isVisibleTeacher", !isVisibleTeacher)
                            this.props.fetchOnlyLessons();
                        }}
                               type="checkbox" checked={this.props.isVisibleTeacher}/>
                        <div className="state p-info">
                            <label>Викладач</label>
                        </div>
                    </div>
                </div>
                <div className={"mb-3"}>
                    <div className="pretty p-default  p-curve p-fill" style={{fontSize: "16px"}}>
                        <input onChange={() => {
                            const {isVisibleDiscipline} = this.props;

                            if (isVisibleDiscipline) {
                                localStorage.removeItem('OA.UM-discipline');
                                this.props.updateFilters("discipline", null);
                            }
                            this.props.updateFilters("isVisibleDiscipline", !isVisibleDiscipline)
                            this.props.fetchOnlyLessons();
                        }}
                               type="checkbox" checked={this.props.isVisibleDiscipline}/>
                        <div className="state p-info">
                            <label>Предмет</label>
                        </div>
                    </div>
                </div>
                <Divider/>
                <div className={"mb-3"}>
                    <div className="pretty p-default  p-curve p-fill" style={{fontSize: "16px"}}>
                        <input
                            type="checkbox"
                            onChange={() => {
                                this.props.updateFilters("showZeroRow", !this.props.showZeroRow)
                            }}
                            checked={showZeroRow}
                            disabled={this.props.disableZeroRow}
                        />
                        <div className="state p-info">
                            <label>0 Пара</label>
                        </div>
                    </div>
                </div>
                <div className={"mb-3"}>
                    <div className="pretty p-default  p-curve p-fill" style={{fontSize: "16px"}}>
                        <input
                            type="checkbox"
                            onChange={() => {
                                this.props.updateFilters("showMore5Rows", !this.props.showMore5Rows)
                            }}
                            checked={showMore5Rows}
                            disabled={this.props.disableMore5Rows}
                        />
                        <div className="state p-info">
                            <label>>5 Пар</label>
                        </div>
                    </div>
                </div>
                <div className={"mb-3"}>
                    <div className="pretty p-default  p-curve p-fill" style={{fontSize: "16px"}}>
                        <input
                            type="checkbox"
                            onChange={() => {
                                this.props.updateFilters("showSaturday", !this.props.showSaturday)
                            }}
                            checked={showSaturday}
                            /*disabled={this.props.disableSaturday}*/
                        />
                        <div className="state p-info">
                            <label>Субота</label>
                        </div>
                    </div>
                </div>
                <div className={"mb-3"}>
                    <div className="pretty p-default  p-curve p-fill" style={{fontSize: "16px"}}>
                        <input
                            type="checkbox"
                            onChange={() => {
                                this.props.updateFilters("showSunday", !this.props.showSunday)
                            }}
                            defaultChecked={showSunday}
                            /* disabled={this.props.disableSunday}*/
                        />
                        <div className="state p-info">
                            <label>Неділя</label>
                        </div>
                    </div>
                </div>
                <div className={"mb-3"}>
                    <div className="pretty p-default  p-curve p-fill" style={{fontSize: "16px"}}>
                        <input onChange={() => {
                            const {validations} = this.props;
                            localStorage.setItem('OA.UM-user_validations', (!validations).toString());
                            this.props.updateFilters("validations", !validations);
                            this.props.fetchOnlyLessons();
                        }}
                               type="checkbox" checked={this.props.validations}/>
                        <div className="state p-info">
                            <label>Відключити перевірку по студентам</label>
                        </div>
                    </div>
                </div>
                <Divider/>
                <div className={"mb-3"}>
                    <div className={"text-center"}>
                        <label className={"h5"}>Семестр</label>
                    </div>
                    <Select
                        options={[
                            {value: 1, label: 1},
                            {value: 2, label: 2},
                        ]}
                        onChange={this.handleSelectChange}
                        defaultValue={this.props.semesters}
                        noOptionsMessage={NoOptionsMessage}
                        isClearable
                        isMulti
                        placeholder={"Виберіть поточний семестр"}
                    />

                </div>
            </div>


        );
    }

}


function mapStateToProps(state) {
    return {
        showMore5Rows: state.get("lessons").get("showMore5Rows"),
        showSaturday: state.get("lessons").get("showSaturday"),
        showSunday: state.get("lessons").get("showSunday"),
        showZeroRow: state.get("lessons").get("showZeroRow"),
        isVisibleTeacher: state.get("lessons").get("isVisibleTeacher"),
        isVisibleGroups: state.get("lessons").get("isVisibleGroups"),
        isVisibleDiscipline: state.get("lessons").get("isVisibleDiscipline"),
        semesters: state.get("lessons").get("semesters"),
        validations: state.get("lessons").get("validations"),
    }
}

export default connect(mapStateToProps, {
    fetchOnlyLessons,
    fetchLessons,
    updateFilters
})(LessonsSettings);