import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import {compose} from "redux";
import {connect} from "react-redux";
import TeacherLoadAccordion from "./components/TeacherLoadAccordion";
import TeacherRateAccordion from "./components/TeacherRateAccordion";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

class TeacherLoadStatModal extends PureComponent {


    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={<div><h4>Детальна інформація про навантаження кафедри</h4></div>}
                width={"95vw"}
                onClose={() => {
                    this.props.hide("teacherLoadStatModal");
                }}
                isOpen={show}
            >
                <Tabs>
                    <TabList>
                        <Tab>Навантаження</Tab>
                        <Tab>Погодинка</Tab>
                        <Tab>Без погодинка</Tab>
                        <Tab>Статистика</Tab>
                    </TabList>

                    <TabPanel>
                        <TeacherLoadAccordion/>
                    </TabPanel>
                    <TabPanel>
                        <TeacherLoadAccordion renderHourlyValue/>
                    </TabPanel>
                    <TabPanel>
                        <TeacherLoadAccordion renderWithoutHourlyValue/>
                    </TabPanel>
                    <TabPanel>
                        <TeacherRateAccordion/>
                    </TabPanel>
                </Tabs>

            </PureModal>
        );
    }
}


export default compose(
    connectModal({
        name: 'teacherLoadStatModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(null, {
        hide
    }))(TeacherLoadStatModal);

