import React, {PureComponent} from "react";
import {connect} from "react-redux";
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import {Accordion, AccordionItem} from "react-sanfona";
import {fetchTeacherLoadStatistics} from "../../../../actions";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import PropTypes from "prop-types";
import "./styles.css";

class TeacherLoadAccordion extends PureComponent {
    static propTypes = {
        renderHourlyValue: PropTypes.bool.isRequired
    };
    static defaultProps = {
        renderHourlyValue: false
    };
    renderTable = (body) => {
        let items = body;
        let tmp;
        if (this.props.renderHourlyValue) {
            tmp = items.map(cells => {
                const numberCells = cells.length;
                const hourlyValue = cells[numberCells - 1].hourlyValue;
                const number = parseInt(hourlyValue,10);
                if (hourlyValue !== null && hourlyValue !== "" && number !== 0) {
                    return cells;
                }
                return null;
            });
            items = tmp.filter(x => x !== null);
        }
console.log(items,"items")
        return items.map((cells, i) => {
            return (
                <Table.Row key={`row_${i}`}>
                    {this.getTableCells(cells)}
                </Table.Row>
            )
        })
    };
    getTableCells = (cells) => {
        return cells.map((cell, i) => {
            return (
                <Table.Cell
                    positive={cell.isTeacher}
                    colSpan={cell.colSpan}
                    rowSpan={cell.rowSpan}
                    key={`cell_${i}`}>
                    {this.getCellValue(cell)}
                </Table.Cell>
            );
        })
    };
    getCellValue = (cell) => {
        if (this.props.renderHourlyValue) {
            if (cell.isText) {
                return cell.value;
            } else {
                return cell.hourlyValue;
            }
        } else if (this.props.renderWithoutHourlyValue) {
            if (cell.isText) {
                return cell.value;
            } else {
                const result = parseFloat(cell.value) - parseFloat(cell.hourlyValue);
                if (isNaN(result)) return "";
                return result;
            }

        }
        else {
            return cell.value;
        }

    };

    render() {
        const {statistics} = this.props;
        const teacherId = statistics.get("teacher");
        const table = statistics.get("table");
        const header = statistics.get("header");
        return (
            <Accordion
                onChange={(data) => {
                    if (data.activeItems) {
                        if (data.activeItems.length > 0) {
                            this.props.fetchTeacherLoadStatistics({
                                teacherId: this.props.teachers.get(data.activeItems[0]).id
                            });
                        }
                    }
                }}>
                {this.props.teachers.toArray().map(teacher => {
                    return (
                        <AccordionItem className={"text-transform"} key={teacher.id} title={teacher.name}
                                       titleTag={"div"}
                        >
                            {teacher.id === teacherId ?
                                <div style={{overflow: 'auto'}}>
                                    <Table celled textAlign={"center"} verticalAlign={"middle"}>
                                        <Table.Header>
                                            <Table.Row>
                                                {header.map((cell, i) => {
                                                    if (cell.shortName && cell.shortName.length > 0) {
                                                        return (
                                                            <Table.HeaderCell textAlign={"center"} key={`header_${i}`}>
                                                                <Popup
                                                                    trigger={<span>{cell.shortName}</span>}
                                                                    content={cell.name}
                                                                    hideOnScroll
                                                                    on='hover'
                                                                />
                                                            </Table.HeaderCell>);
                                                    } else {
                                                        return (
                                                            <Table.HeaderCell textAlign={"center"} key={`header_${i}`}>
                                                                {cell.name}
                                                            </Table.HeaderCell>);
                                                    }

                                                })}
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {this.renderTable(table)}
                                        </Table.Body>
                                    </Table>
                                </div> : <div/>}
                        </AccordionItem>
                    );
                })}
            </Accordion>
        );
    }
}

function mapStateToProps(state) {
    return {
        teachers: state.get("teacherLoad").get("teachers"),
        statistics: state.get("teacherLoad").get("statistics"),
    }
}

export default connect(mapStateToProps, {
    fetchTeacherLoadStatistics
})(TeacherLoadAccordion);

