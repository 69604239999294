import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editDepartmentsInfo, insertDepartment} from "../../actions";
import {getDefaultOption, getDefaultValue, getOptions, NoOptionsMessage} from "../../../../../utils";
import Select from "react-select";

class DepartmentsModal extends PureComponent {
    handleSave = () => {
        const object = {
            id: this.state.id,
            name: this.state.name,
            shortName: this.state.shortName,
            isArchived: this.state.isArchived.value,
            facultyId: this.state.facultyId.id,
            facultyValue: this.state.facultyValue,
        };
        if (this.state.isCreate) {
            this.props.insertDepartment(object);
        } else {
            this.props.editDepartmentsInfo(object);
        }

        this.props.hide("departmentModal")
    };

    constructor(props) {
        super(props);
        let row = {};
        if (props.isCreate) {
            row = {};
        } else {
            row = props.row._original
        }
        const defaultValue = getDefaultValue();
        this.state = {
            id: row.id || "",
            isCreate: props.isCreate,
            name: row.name || "",
            shortName: row.shortName || "",
            isArchived: row.isArchived ? {value: row.isArchived, label: row.isArchived} : defaultValue,
            facultyId: row.facultyId ? {
                id: row.facultyId,
                shortName: row.facultyValue
            } : null,
            facultyValue: row.facultyValue || "",

        }
    }

    render() {
        const {show} = this.props;
        const faculties = this.props.faculties.toArray();
        return (
            <PureModal
                header={"Створення та редагування інформації про кафедру"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("departmentModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
                onClose={() => {
                    this.props.hide("departmentModal");
                }}
            >
                <div>
                    <Table celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    Назва кафедри
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({name: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.name}
                                        placeholder={"Введіть назву кафедри"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Скорочена назва кафедри
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({shortName: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.shortName}
                                        placeholder={"Введіть скорочену назву кафедри"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Факультет
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={faculties}
                                        onChange={(selectedValue) => this.setState({
                                            facultyId: selectedValue || getDefaultOption(),
                                            facultyValue: selectedValue.shortName || null
                                        })}
                                        getOptionLabel={(option) => option.shortName}
                                        getOptionValue={(option) => option.id}
                                        defaultValue={this.state.facultyId}
                                        noOptionsMessage={NoOptionsMessage}
                                        placeholder={"Виберіть факультет"}
                                        isClearable={false}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    В архів
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={getOptions()}
                                        onChange={(selected) => {
                                            this.setState({
                                                isArchived: selected || getDefaultValue()
                                            })
                                        }}
                                        defaultValue={this.state.isArchived}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Перемістити запис в архів"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>

            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        faculties: state.get("faculties"),
    }
}

export default compose(
    connectModal({
        name: 'departmentModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        insertDepartment,
        editDepartmentsInfo,
    }))(DepartmentsModal);

