import React, {PureComponent} from "react";
import {fetchStudyWorkPlansWithTeacherLoadsBySemester,} from "../../actions";
import Select from "react-select";
import {getSemesters, NoOptionsMessage} from "../../../../../utils";
import {connect} from "react-redux";

class StudyWorkPlanWithLoadsSettings extends PureComponent {
    state = {
        semester: localStorage.getItem("OA.UM-StudyWotkPlanWithLoad_semesters") && localStorage.getItem("OA.UM-StudyWotkPlanWithLoad_semesters").indexOf("{") > -1 ? JSON.parse(localStorage.getItem("OA.UM-StudyWotkPlanWithLoad_semesters")) : null,
    };
    handleSelectChange = (selectedValue) => {
        const key = `OA.UM-StudyWotkPlanWithLoad_semesters`;
        localStorage.setItem(key, JSON.stringify(selectedValue));
        this.props.fetchStudyWorkPlansWithTeacherLoadsBySemester(selectedValue ? selectedValue.value : 0);
        this.setState({semester: selectedValue});
    };

    render() {
        return (
            <div>
                <div className={"mb-3"}>
                    <div className={"text-center"}>
                        <label className={"h5"}>Семестр</label>
                    </div>
                    <Select
                        options={getSemesters()}
                        onChange={this.handleSelectChange}
                        defaultValue={this.state.semester}
                        isClearable
                        noOptionsMessage={NoOptionsMessage}
                        placeholder={"Вкажіть наскрізний семестр"}
                    />

                </div>

            </div>


        );
    }

}


export default connect(null, {
    fetchStudyWorkPlansWithTeacherLoadsBySemester,
})(StudyWorkPlanWithLoadsSettings);