import {List} from "immutable"
import {
    DELETE_TEACHER_POSITION, EDIT_TEACHER_POSITION, FETCH_TEACHERS_POSITIONS,
    INSERT_TEACHER_POSITION
} from "./types"

const initialState = List();
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_TEACHERS_POSITIONS:
            return List(action.payload);
        case EDIT_TEACHER_POSITION:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case INSERT_TEACHER_POSITION:
            return state.insert(0, action.payload);
        case DELETE_TEACHER_POSITION:
            return state.delete(state.findIndex(i => i.id === action.payload));
        default:
            return state;
    }
}