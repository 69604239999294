import {List} from "immutable"
import {
    COPY_STUDY_WORK_PLAN_ITEM,
    COPY_TEACHER_LOAD_ITEM,
    DELETE_TEACHER_LOAD_ITEM,
    DELETE_WORK_PLAN_ITEM,
    EDIT_STUDY_WORK_PLAN_INFO,
    EDIT_TEACHER_LOAD_INFO,
    FETCH_STUDY_WORK_PLANS_WITH_TEACHER_LOADS, FETCH_STUDY_WORK_PLANS_WITH_TEACHER_LOADS_BY_SEMESTER,
    INSERT_STUDY_WORK_PLAN_ITEM,
    INSERT_TEACHER_LOAD_ITEM,
    TOGGLE_REQUEST_STATUS
} from "./types";

const initialState = List();
let number, index;
export default function (state = initialState, action) {
    switch (action.type) {
        case TOGGLE_REQUEST_STATUS:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
            case FETCH_STUDY_WORK_PLANS_WITH_TEACHER_LOADS_BY_SEMESTER:
            case FETCH_STUDY_WORK_PLANS_WITH_TEACHER_LOADS:
            return List(action.payload);
        case EDIT_STUDY_WORK_PLAN_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case EDIT_TEACHER_LOAD_INFO:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case DELETE_TEACHER_LOAD_ITEM:
            return state.set(state.findIndex(i => i.id === action.id), action.payload);
        case DELETE_WORK_PLAN_ITEM:
            return state.delete(state.findIndex(i => i.id === action.payload));
        case INSERT_STUDY_WORK_PLAN_ITEM:
            return state.insert(0, action.payload);
        case INSERT_TEACHER_LOAD_ITEM:
            return state.set(state.findIndex(i => i.id === action.payload.id), action.payload);
        case COPY_STUDY_WORK_PLAN_ITEM:
            number = state.findIndex(i => i.id === action.id);
            index = number <= 0 ? 0 : number;
            return state.insert(index, action.payload);
        case COPY_TEACHER_LOAD_ITEM:
            number = state.findIndex(i => i.id === action.payload.id);
            index = number ;
            console.log(index,state.get(index),action)
            return state.set(index, action.payload);
        default:
            return state;
    }
}