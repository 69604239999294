import axios from "axios";
import {DELETE_WEEK, EDIT_WEEK_INFO, FETCH_WEEKS, GENERATE_WEEKS} from "./types";
import {ROOT_URL} from "../../../utils";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchWeeks() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/university/weeks`, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_WEEKS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function editWeekInfo(week) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/university/week/edit`, week, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: EDIT_WEEK_INFO, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function generateWeeks(weeks) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/university/week/generate`, weeks, {
        headers: {"Authorization": `Bearer ${token}`}
    });
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: GENERATE_WEEKS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteWeek(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/university/week/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_WEEK, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}



