import axios from "axios";
import {ROOT_URL} from "../../../utils";
import {EDIT_TEACHER_LOAD_COLUMN_INFO,FETCH_TEACHER_LOAD_COLUMNS,INSERT_TEACHER_LOAD_COLUMN} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchTeacherLoadColumns() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/teacherLoadColumns`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type:FETCH_TEACHER_LOAD_COLUMNS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function editTeacherLoadColumnInfo(teacherColumn) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/teacherLoadColumns/edit`, teacherColumn, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_TEACHER_LOAD_COLUMN_INFO, payload: teacherColumn})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertTeacherLoadColumn(teacherColumn) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/teacherLoadColumn/create`, teacherColumn, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_TEACHER_LOAD_COLUMN, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}
