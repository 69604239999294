import React, {Component} from "react";
import {connect} from "react-redux";
import {
    deleteTeacherLoadItem,
    editTeacherLoadInfo,
    fetchTeacherLoad,
    fetchTeacherLoads,
    fetchTeacherRates,
    fetchTeachers2Load,
    insertHourlyTeacherLoad,
} from "./actions";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {show} from "redux-modal";
import {writeErrors} from "../../../utils/logs";
import {loadingText, nextText, noDataText, ofText, pageText, previousText, rowsText} from "../../../utils/locale";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import TeacherLoadHourlyModal from "./components/TeacherLoadHourlyModal";
import TeacherLoadStatModal from "./components/TeacherLoadStatModal";
import TeacherLoadModal from "./components/TeacherLoadModal";
import {fetchDepartments4Select} from "../../../components/Data4Select/actions";
class TeacherLoad extends Component {

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    componentDidMount() {
        this.props.fetchDepartments4Select();
        this.props.fetchTeacherLoad();
    }

    getColumns() {
        const columns = [
            {
                Header: "Предмет",
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {keys: ["discipline"]}),
                filterAll: true,
                accessor: "discipline",
                className:"text-left"

            },
            {
                Header: "Семестр",
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {keys: ["semester"]}),
                filterAll: true,
                accessor: "semester",
                width: 40,
            },
            {
                Header: <Popup
                    trigger={<span>Групи</span>}
                    content="Група/Потік"
                    hideOnScroll
                    on={['hover']}
                />,
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {keys: ["groups"]}),
                filterAll: true,
                accessor: "groups",

            },
            {
                Header: <Popup
                    trigger={<span>К-сть студ.</span>}
                    content="Кількість студентів"
                    hideOnScroll
                    on={['hover']}
                />,
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {keys: ["students"]}),
                filterAll: true,
                accessor: "students",
                width: 40,
            },
            {
                Header: "Викладач",
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {keys: ["teacher"]}),
                filterAll: true,
                accessor: "teacher",

            },

            {
                Header: <Popup
                    trigger={<span>Л.</span>}
                    content="Кількість годин лекцій"
                    hideOnScroll
                    on={['hover']}
                />,
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {keys: ["hoursLectures"]}),
                filterAll: true,
                accessor: "hoursLectures",
                width: 40,
            },
            {
                Header: <Popup
                    trigger={<span>Пр.</span>}
                    content="Кількість годин практичних"
                    hideOnScroll
                    on={['hover']}
                />,
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, {keys: ["hoursPractical"]}),
                filterAll: true,
                accessor: "hoursPractical",
                width: 40,
            },
        ];
        if (this.props.header.size > 0) {
            this.props.header.filter(i => i.accessor !== 'students').filter(column => {
                const columnItem = {
                    Header: <Popup
                        trigger={<span>{column.shortName}</span>}
                        content={column.name}
                        hideOnScroll
                        on={['hover']}
                    />,
                    filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {keys: [column.accessor]}),
                    filterAll: true,
                    accessor: column.accessor,
                    width: 40,
                };
                return columns.push(columnItem);
            })
        }
        columns.push({
            Header: "Тип",
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {keys: ["teacherLoadTypeName"]}),
            filterAll: true,
            accessor: "teacherLoadTypeName",
            width: 40,
        });
        columns.push({
            Header: () => {
                return (
                    <Popup
                        trigger={<Icon name={"area graph"} size={"big"} color={"brown"}
                                       onClick={this.handleTeacherLoadStat.bind(this)}
                        />}
                        content={"Переглянути плахту"}
                        hideOnScroll
                        on={['hover']}
                    />
                )
            },
            sortable: false,
            filterable: false,
            style: {
                cursor: "pointer",
                fontSize: 25,
                padding: "0",
                textAlign: "center",
                userSelect: "none"
            },
            width: 100,
            accessor: "isHourlyLoad",
            Cell: ({row}) => {

                let isHourlyLoad = false;
                let hasHourlyLoad = true;
                if (row) {
                    isHourlyLoad = row._original
                        .isHourlyLoad === "true";
                    hasHourlyLoad = row._original
                        .hasHourlyLoad !== "true";
                }
                if (isHourlyLoad) {
                    return (<div style={{marginTop: "15px"}}>
                        <Popup
                            trigger={<Icon name={"edit"}
                                           color={"blue"}
                                           onClick={this.handleEditTeacherLoadHourly.bind(this, row)}
                            />}
                            content={`Редагувати ${ isHourlyLoad ? "погодинку" : "навантаження"}`}
                            hideOnScroll
                            on='hover'
                        />
                        <Popup
                            trigger={<Icon name={"trash"}
                                           color={"red"}
                                           onClick={this.handleDeleteTeacherLoadItem.bind(this, row._original.id)}
                            />}
                            content={`Видалити запис ${ isHourlyLoad ? "погодинки" : "навантаження"}`}
                            hideOnScroll
                            on='hover'
                        />
                    </div>)
                } else {
                    return (<div style={{marginTop: "15px"}}>
                        {hasHourlyLoad &&
                        <Popup
                            trigger={<Icon name={"add"}
                                           color={"green"}
                                           onClick={this.handleAddTeacherLoadItem.bind(this, row._original.id)}
                            />}
                            content={`Додати запис погодинки`}
                            hideOnScroll
                            on='hover'
                        />
                        }
                        <Popup
                            trigger={<Icon name={"edit"}
                                           color={"blue"}
                                           onClick={this.onEditTeacherLoad.bind(this, row._original.id)}
                            />}
                            content={`Редагувати ${ isHourlyLoad ? "погодинку" : "навантаження"}`}
                            hideOnScroll
                            on='hover'
                        />
                    </div>)
                }
            }
        });

        return columns;

    }

    handleEditTeacherLoadHourly(row) {
        this.props.show("teacherLoadHourlyModal", {row});
    }

    onEditTeacherLoad(id) {
        this.props.fetchTeacherLoads(id);
        this.props.show("teacherLoadModalWindow");
    }

    handleAddTeacherLoadItem(id) {
        this.props.insertHourlyTeacherLoad(id)
    }

    handleDeleteTeacherLoadItem(id) {
        this.props.deleteTeacherLoadItem(id);
    }

    handleTeacherLoadStat() {
        this.props.fetchTeachers2Load();
        this.props.fetchTeacherRates();
        this.props.show("teacherLoadStatModal");
    }

    render() {

        return (
            <div>
                <TeacherLoadHourlyModal/>
                <TeacherLoadModal/>
                <TeacherLoadStatModal/>
                <Tabs>
                    <TabList>
                        <Tab>Навантаження</Tab>
                        <Tab>Погодинка</Tab>
                        <Tab>Навантаження (Інше)</Tab>
                    </TabList>

                    <TabPanel>
                        <ReactTable
                            data={this.props.rows.filter(row => row.disciplineType !== "true").toArray()}
                            filterable
                            columns={this.getColumns()}
                            defaultPageSize={10}
                            style={{
                                height: window.innerHeight - 200 + 'px',
                                width: window.innerWidth + 'px'
                            }}
                            className="table-bordered table-striped white-bg text-center"
                            getTrProps={(state, rowInfo) => {
                                let isHourlyLoad = false;
                                if (rowInfo) {
                                    isHourlyLoad = rowInfo.row._original
                                        .isHourlyLoad === "true";
                                }
                                return {
                                    style: {
                                        background: isHourlyLoad ? "orange" : "white"
                                    }
                                };
                            }}
                            noDataText={noDataText}
                            previousText={previousText}
                            nextText={nextText}
                            loadingText={loadingText}
                            pageText={pageText}
                            ofText={ofText}
                            rowsText={rowsText}
                        />
                    </TabPanel>

                    <TabPanel>
                        <ReactTable
                            data={this.props.rows.filter(row => row.isHourlyLoad === "true" && row.disciplineType !== "true").toArray()}
                            filterable
                            columns={this.getColumns()}
                            defaultPageSize={10}
                            style={{
                                height: window.innerHeight - 200 + 'px',
                                width: window.innerWidth + 'px'
                            }}
                            className="table-bordered table-striped white-bg text-center"
                            noDataText={noDataText}
                            previousText={previousText}
                            nextText={nextText}
                            loadingText={loadingText}
                            pageText={pageText}
                            ofText={ofText}
                            rowsText={rowsText}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ReactTable
                            data={this.props.rows.filter(row => row.disciplineType === "true").toArray()}
                            filterable
                            columns={this.getColumns()}
                            defaultPageSize={10}
                            style={{
                                height: window.innerHeight - 200 + 'px',
                                width: window.innerWidth + 'px'
                            }}
                            className="table-bordered table-striped white-bg text-center"
                            getTrProps={(state, rowInfo) => {
                                let isHourlyLoad = false;
                                if (rowInfo) {
                                    isHourlyLoad = rowInfo.row._original
                                        .isHourlyLoad === "true";
                                }
                                return {
                                    style: {
                                        background: isHourlyLoad ? "orange" : "white"
                                    }
                                };
                            }}
                            noDataText={noDataText}
                            previousText={previousText}
                            nextText={nextText}
                            loadingText={loadingText}
                            pageText={pageText}
                            ofText={ofText}
                            rowsText={rowsText}
                        />
                    </TabPanel>
                </Tabs>
            </div>
        );


    }
}


function mapStateToProps(state) {
    return {
        rows: state.get("teacherLoad").get("tableBody"),
        header: state.get("teacherLoad").get("tableHeader"),
    }
}

export default connect(mapStateToProps, {
    fetchTeacherLoad,
    deleteTeacherLoadItem,
    editTeacherLoadInfo,
    insertHourlyTeacherLoad,
    fetchTeachers2Load,
    fetchTeacherRates,
    show,
    fetchTeacherLoads,
    fetchDepartments4Select
})(TeacherLoad)
