import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import DatePicker from 'react-date-picker';
import {editCalendarPlanInfo, insertCalendarPlanItem} from "../../actions";
import {NoOptionsMessage} from "../../../../../utils";
import Select from "react-select";

class CalendarPlanModal extends PureComponent {
    handleSave = () => {
        const object = {
            id: this.state.id,
            title: this.state.title,
            shortName: this.state.shortName,
            startValue: this.state.start,
            endValue: this.state.end,
            groups: this.state.groupsIds.map(i => i.value).filter(i => i).join(","),
        };
        if (this.state.isCreate) {
            this.props.insertCalendarPlanItem(object);
        } else {
            this.props.editCalendarPlanInfo(object);
        }

        this.props.hide("calendarPlanModal")
    };

    constructor(props) {
        super(props);
        let row = {};
        if (props.isCreate) {
            row = {};
        } else {
            row = props.row._original;
        }

        this.state = {
            id: row.id || "",
            isCreate: props.isCreate,
            title: row.title || "",
            shortName: row.shortName || "",
            start: row.start ? new Date(row.start) : new Date(),
            end: row.end ? new Date(row.end) : new Date(),
            groupsIds: row.groupsIds,

        }
    }

    render() {
        const {show} = this.props;
        return (
            <PureModal
                header={"Створення та редагування інформації про графік освітнього процесу"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("calendarPlanModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                onClose={() => {
                    this.props.hide("calendarPlanModal");
                }}
                isOpen={show}
            >
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                Назва
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({title: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.title}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Скорочена назва
                            </Table.Cell>
                            <Table.Cell>
                                <input
                                    onChange={e => this.setState({shortName: e.target.value})}
                                    className={"form-control"}
                                    value={this.state.shortName}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Початок
                            </Table.Cell>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) => this.setState({start: value})}
                                    locale={"uk-UA"}
                                    value={this.state.start}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Кінець
                            </Table.Cell>
                            <Table.Cell>
                                <DatePicker
                                    onChange={(value) => this.setState({end: value})}
                                    locale={"uk-UA"}
                                    value={this.state.end}
                                />
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                Групи
                            </Table.Cell>
                            <Table.Cell>
                                <Select
                                    options={this.props.groups.toArray()}
                                    onChange={(selected) => {
                                        this.setState({groupsIds: selected})
                                    }}
                                    noOptionsMessage={NoOptionsMessage}
                                    isMulti
                                    defaultValue={this.state.groupsIds}
                                    placeholder={"Вкажіть групи"}
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </PureModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        groups: state.get("data4Select").get("groups"),
    }
}

export default compose(
    connectModal({
        name: 'calendarPlanModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editCalendarPlanInfo,
        insertCalendarPlanItem,
    }))(CalendarPlanModal);

