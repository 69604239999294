
export const INSERT_TEACHER_LOAD_ITEM = "INSERT_TEACHER_LOAD_ELEMENT";

export const DELETE_TEACHER_LOAD_ITEM = "DELETE_TEACHER_LOAD_ELEMENT";

export const COPY_TEACHER_LOAD_ITEM = "COPY_TEACHER_LOAD_ELEMENT";


export const DELETE_WORK_PLAN_ITEM = "DELETE_WORK_PLAN_ELEMENT";

export const FETCH_STUDY_WORK_PLANS_WITH_TEACHER_LOADS = "FETCH_STUDY_WORK_PLANS_WITH_TEACHER_LOADS";
export const FETCH_STUDY_WORK_PLANS_WITH_TEACHER_LOADS_BY_SEMESTER = "FETCH_STUDY_WORK_PLANS_WITH_TEACHER_LOADS_BY_SEMESTER";


export const INSERT_STUDY_WORK_PLAN_ITEM = "INSERT_WORK_PLAN_ELEMENT";

export const COPY_STUDY_WORK_PLAN_ITEM = "COPY_STUDY_WORK_PLAN_ELEMENT";

export const EDIT_STUDY_WORK_PLAN_INFO = "EDIT_WORK_PLAN_INFO";

export const EDIT_TEACHER_LOAD_INFO = "EDIT_TEACHER_LOAD";

export const TOGGLE_REQUEST_STATUS = "TOGGLE_REQUEST_STATUS";
