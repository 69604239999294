import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Table from "semantic-ui-react/dist/es/collections/Table/Table";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import {editSpecialitiesInfo, insertSpeciality} from "../../actions";
import {
    getDefaultOption,
    getDefaultValue,
    getOptions,
    getUserDepartmentOption,
    NoOptionsMessage
} from "../../../../../utils";
import Select from "react-select";

class SpecialitiesModal extends PureComponent {
    getStudyLevels = () => {
        return [
            {value: "Бакалавр", label: "Бакалавр"},
            {value: "Магістр", label: "Магістр"},
            {value: "Аспірантура", label: "Аспірантура"},
        ];
    };
    handleSave = () => {
        const object = {
            id: this.state.id,
            name: this.state.name,
            departmentId: this.state.departmentId.value,
            departmentValue: this.state.departmentValue,
            studyLevel: this.state.studyLevel.value,
            isArchived: this.state.isArchived.value,
            specialization: this.state.specialization,
            shortName: this.state.shortName,
        };
        if (this.state.isCreate) {
            this.props.insertSpeciality(object);
        } else {
            this.props.editSpecialitiesInfo(object);
        }

        this.props.hide("specialityModal")
    };

    constructor(props) {
        super(props);
        let row = {};
        if (props.isCreate) {
            row = {};
        } else {
            row = props.row._original
        }
        const defaultValue = getDefaultValue();
        this.state = {
            id: row.id || "",
            isCreate: props.isCreate,
            name: row.name || "",
            studyLevel: row.studyLevel ? {
                value: row.studyLevel,
                label: row.studyLevel
            } : this.getStudyLevels()[0],
            specialization: row.specialization,
            shortName: row.shortName || "",
            departmentValue: row.departmentValue || "",
            departmentId: row.departmentId ? {
                value: row.departmentId,
                label: row.departmentValue
            } : getUserDepartmentOption(),
            isArchived: row.isArchived ? {value: row.isArchived, label: row.isArchived} : defaultValue,

        }
    }

    render() {
        const {show} = this.props;
        const departments = this.props.departments.toArray();
        return (
            <PureModal
                header={"Створення та редагування інформації про спеціальність"}
                width={"80vw"}
                footer={
                    <div className={"float-right"}>
                        <Button color={"red"} icon onClick={() =>
                            this.props.hide("specialityModal")
                        }>
                            <Icon name='remove'/> Закрити
                        </Button>
                        <Button primary onClick={this.handleSave}>
                            <Icon name='save'/> Зберегти
                        </Button>
                    </div>
                }
                isOpen={show}
                onClose={() => {
                    this.props.hide("specialityModal");
                }}
            >
                <div>
                    <Table celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    Назва спеціальності
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({name: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.name}
                                        placeholder={"Введіть назву спеціальності"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Скорочена назва спеціальності
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({shortName: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.shortName}
                                        placeholder={"Введіть назву спеціальності"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Код спеціальності
                                </Table.Cell>
                                <Table.Cell>
                                    <input
                                        onChange={e => this.setState({specialization: e.target.value})}
                                        className={"form-control"}
                                        value={this.state.specialization}
                                        placeholder={"Введіть код спеціальності"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Кафедра
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={departments}
                                        onChange={(selectedValue) => this.setState({
                                            departmentId: selectedValue || getDefaultOption(),
                                            departmentValue: selectedValue.label || null
                                        })}
                                        defaultValue={this.state.departmentId}
                                        noOptionsMessage={NoOptionsMessage}
                                        placeholder={"Вкажіть кафедру"}
                                        isClearable={false}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    Навчальний рівень
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={this.getStudyLevels()}
                                        onChange={(selected) => {
                                            this.setState({
                                                studyLevel: selected || getDefaultOption()
                                            })
                                        }}
                                        defaultValue={this.state.studyLevel}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Вкажіть навчальний рівень спеціальності"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell>
                                    В архів
                                </Table.Cell>
                                <Table.Cell>
                                    <Select
                                        options={getOptions()}
                                        onChange={(selected) => {
                                            this.setState({
                                                isArchived: selected || getDefaultValue()
                                            })
                                        }}
                                        defaultValue={this.state.isArchived}
                                        noOptionsMessage={NoOptionsMessage}
                                        isClearable={false}
                                        placeholder={"Перемістити запис в архів"}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>

            </PureModal>
        )
            ;
    }
}

function mapStateToProps(state) {
    return {
        departments: state.get("data4Select").get("departments"),
    }
}

export default compose(
    connectModal({
        name: 'specialityModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(mapStateToProps, {
        hide,
        editSpecialitiesInfo,
        insertSpeciality,
    }))(SpecialitiesModal);

