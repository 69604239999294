import React, {Component} from 'react';
import {connect} from "react-redux";
import {fetchLessons, fetchOnlyLessons, updateFilters} from "../../actions";
import {
    fetchDisciplines4Lessons,
    fetchTeachers4Lessons,
    fetchUniversityGroups4Lessons,
    fetchWeeks4Select
} from "../../../../components/Data4Select/actions";
import {NoOptionsMessage} from "../../../../utils";
import Select from "react-select";

const defaultFilter = {
    groups: localStorage.getItem('OA.UM-groups') !== null && localStorage.getItem('OA.UM-groups').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-groups')).map(i => i.value).join(",") : '',
    week: localStorage.getItem('OA.UM-week') !== null && localStorage.getItem('OA.UM-week').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-week')).value : "",
    teacher: localStorage.getItem('OA.UM-teacher') !== null && localStorage.getItem('OA.UM-teacher').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-teacher')).value : "",
    discipline: localStorage.getItem('OA.UM-discipline') && localStorage.getItem('OA.UM-discipline').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-discipline')).value : "",
    semesters: localStorage.getItem('OA.UM-semesters') !== null && localStorage.getItem('OA.UM-semesters').indexOf("{") > -1 ? JSON.parse(localStorage.getItem('OA.UM-semesters')).map(i => i.value).join(",") : '',
    validations: localStorage.getItem('OA.UM-user_validations') === 'true',
};

class TimetableFilters extends Component {
    state = {};

    componentDidMount() {
        this.props.fetchUniversityGroups4Lessons();
        this.props.fetchWeeks4Select();
        this.props.fetchDisciplines4Lessons();
        this.props.fetchTeachers4Lessons();
        this.props.fetchLessons(defaultFilter);
    }

    handleSelectChange(name, selectedValue) {
        const key = `OA.UM-${name}`;
        const filter = {
        };
        localStorage.setItem(key, JSON.stringify(selectedValue));
        if (name === 'groups') {
            filter[name] = selectedValue.map(i => i.value).join(',');
        } else {
            filter[name] = selectedValue ? selectedValue.value : '';
        }
        this.props.updateFilters(name, selectedValue);
        this.props.fetchLessons(filter);
    }

    render() {
        const {isVisibleTeacher, isVisibleGroups, isVisibleDiscipline} = this.props;
        return (
            <div>

                <table className={"w-100 table-bordered"}>
                    <tbody>
                    <tr>
                        <td width="w-20">
                            <label className="text-center font-weight-bold w-100">Тиждень</label>
                            <Select
                                options={this.props.weeks.toArray()}
                                onChange={this.handleSelectChange.bind(this, "week")}
                                value={this.props.selectedWeek}
                                noOptionsMessage={NoOptionsMessage}
                                isClearable={false}
                                placeholder={"Виберіть навчальний тиждень"}
                            />
                        </td>
                        <td className={isVisibleGroups ? "" : "d-none"}>
                            <label className="text-center font-weight-bold w-100">Групи</label>
                            <Select
                                options={this.props.groups.toArray()}
                                onChange={this.handleSelectChange.bind(this, "groups")}
                                value={this.props.selectedGroup}
                                noOptionsMessage={NoOptionsMessage}
                                isMulti
                                placeholder={"Виберіть академічну групу"}
                            />
                        </td>
                        <td className={isVisibleTeacher ? "" : "d-none"}>
                            <label className="text-center font-weight-bold w-100">Викладач</label>
                            <Select
                                options={this.props.teachers.toArray()}
                                onChange={this.handleSelectChange.bind(this, "teacher")}
                                value={this.props.selectedTeacher}
                                isClearable
                                noOptionsMessage={NoOptionsMessage}
                                placeholder={"Виберіть викладача"}
                            />
                        </td>
                        <td className={isVisibleDiscipline ? "" : "d-none"}>
                            <label className="text-center font-weight-bold w-100">Предмет</label>
                            <Select
                                options={this.props.disciplines.toArray()}
                                onChange={this.handleSelectChange.bind(this, "discipline")}
                                value={this.props.selectedDiscipline}
                                isClearable
                                noOptionsMessage={NoOptionsMessage}
                                placeholder={"Виберіть навчальну дисципліну"}
                            />

                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>


        );
    }

}

function mapStateToProps(state) {
    return {
        disciplines: state.get("data4Select").get("disciplines4Lessons"),
        weeks: state.get("data4Select").get("weeks"),
        teachers: state.get("data4Select").get("teachers4Lessons"),
        groups: state.get("data4Select").get("groups4Lessons"),
        isVisibleTeacher: state.get("lessons").get("isVisibleTeacher"),
        isVisibleGroups: state.get("lessons").get("isVisibleGroups"),
        isVisibleDiscipline: state.get("lessons").get("isVisibleDiscipline"),
        selectedTeacher: state.get("lessons").get("teacher"),
        selectedDiscipline: state.get("lessons").get("discipline"),
        selectedGroup: state.get("lessons").get("groups"),
        selectedWeek: state.get("lessons").get("week"),

    }
}

export default connect(mapStateToProps, {
    fetchWeeks4Select,
    fetchUniversityGroups4Lessons,
    fetchTeachers4Lessons,
    fetchDisciplines4Lessons,
    fetchLessons,
    fetchOnlyLessons,
    updateFilters
})(TimetableFilters)

