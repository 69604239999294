import React, {PureComponent} from "react";
import {connectModal, hide} from 'redux-modal'
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import {connect} from "react-redux";
import {compose} from "redux";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import Button from "semantic-ui-react/dist/es/elements/Button/Button";
import UserTimetable from "../../../../Timetable/UserTimetable/components/UserTimetable";

class TeacherTimetableModal extends PureComponent {


    render() {
        const {show, hide, lesson} = this.props;
        return (
            <PureModal
                header={`Розклад занять -${lesson.teacher}`}
                footer={<div className="float-right">
                    <Button color={"red"} icon onClick={() => hide("teacherTimetableModal")}>
                        <Icon name='remove'/> Закрити
                    </Button>
                </div>}
                width={"80vw"}
                isOpen={show}
                onClose={() => {
                    this.props.hide("teacherTimetableModal");
                }}
            >
                <UserTimetable/>
            </PureModal>
        );
    }
}


export default compose(
    connectModal({
        name: 'teacherTimetableModal',
        getModalState: (state) => state.get("modal")
    }),
    connect(null, {
        hide
    })
)(TeacherTimetableModal);