import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {deleteAudience, fetchAudiences} from "./actions";
import Loader from "semantic-ui-react/dist/es/elements/Loader/Loader";
import {fetchFaculties4SelectWithDefault} from "../../../components/Data4Select/actions";
import {writeErrors} from "../../../utils/logs";
import AudienesModal from "./components/AudienesModal";
import {show} from "redux-modal";
import matchSorter from "match-sorter";
import {loadingText, nextText, noDataText, ofText, pageText, previousText, rowsText} from "../../../utils/locale";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";
import Icon from "semantic-ui-react/dist/es/elements/Icon/Icon";
import ReactTable from "react-table";

class Audiences extends PureComponent {

    componentDidMount() {
        this.props.fetchFaculties4SelectWithDefault();
        this.props.fetchAudiences();
    }

    componentDidCatch(error, errorInfo) {
        writeErrors({error, errorInfo});
    }

    handleAddAudience() {
        this.props.show("audienceModal", {isCreate: true});
    }

    handleEditAudienceInfo(row) {
        this.props.show("audienceModal", {row, isCreate: false});
    }

    handleDeleteAudience(id) {
        this.props.deleteAudience(id);
    }


    render() {
        if (this.props.rows.size < 1 || this.props.faculties.size < 1) {
            return (<Loader active size={"massive"} content='Завантаження аудиторного фонду'/>)
        }
        return (<div>
            <AudienesModal/>
            <ReactTable
                data={this.props.rows.toArray()}
                filterable
                columns={[

                    {
                        Header: "Назва",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["name"]}),
                        filterAll: true,
                        accessor: "name",
                    }, {
                        Header: <Popup
                            trigger={
                                <div>К-сть місць</div>
                            }
                            content='Кількість місць в аудиторії'
                            on={'hover'}
                            style={{zIndex: 3001}}
                        />,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["countSeats"]}),
                        filterAll: true,
                        accessor: "countSeats",
                        className: "text-center"
                    }, {
                        Header: "Факультет",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["facultyValue"]}),
                        filterAll: true,
                        accessor: "facultyValue",
                        className: "text-center"
                    }, {
                        Header: "Комп'ютерна аудиторія",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["isCompAudience"]}),
                        filterAll: true,
                        accessor: "isCompAudience",
                        className: "text-center"
                    }, {
                        Header: "Має проектор",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["hasProjector"]}),
                        filterAll: true,
                        accessor: "hasProjector",
                        className: "text-center"
                    }, {
                        Header: "В архів",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["isArchived"]}),
                        filterAll: true,
                        accessor: "isArchived",
                        className: "text-center"
                    },
                    {

                        Header: <Popup
                            trigger={
                                <Icon name='add' size={"big"}
                                      color={"green"}
                                      onClick={
                                          this.handleAddAudience.bind(this)}/>
                            }
                            content='Додати факультет'
                            on={'hover'}
                            style={{zIndex: 3001}}
                        />,
                        accessor: "id",
                        width: 100,
                        Cell: ({row}) => {
                            return (<div style={{marginTop: "15px"}}>

                                <Popup
                                    trigger={<Icon name={"edit"}
                                                   color={"blue"}
                                                   onClick={this.handleEditAudienceInfo.bind(this, row)}
                                    />}
                                    content="Редагувати інформацію"
                                    hideOnScroll
                                    on='hover'
                                />
                                <Popup
                                    trigger={<Icon name={"trash"}
                                                   color={"red"}
                                                   onClick={this.handleDeleteAudience.bind(this, row._original.id)}
                                    />}
                                    content="Видалити факультет"
                                    hideOnScroll
                                    on='hover'
                                />
                            </div>)
                        },
                        sortable: false,
                        filterable: false,
                        style: {
                            cursor: "pointer",
                            fontSize: 25,
                            padding: "0",
                            textAlign: "center",
                            userSelect: "none"
                        }
                    },
                ]}
                defaultPageSize={10}
                style={{
                    height: "80vh",
                }}
                className="table-bordered table-striped white-bg"
                noDataText={noDataText}
                previousText={previousText}
                nextText={nextText}
                loadingText={loadingText}
                pageText={pageText}
                ofText={ofText}
                rowsText={rowsText}
            />
        </div>);


    }
}


function mapStateToProps(state) {
    return {
        rows: state.get("audiences"),
        faculties: state.get("data4Select").get("facultiesWithDefault"),
    }
}

export default connect(mapStateToProps, {
    deleteAudience,
    fetchAudiences,
    fetchFaculties4SelectWithDefault,
    show
})(Audiences)