import axios from "axios/index";
import {CLEAR_USERS, EDIT_USER_INFO, FETCH_USER, FETCH_USERS} from "./types";
import Cookie from "js-cookie";
import {writeErrors} from "../../../../utils/logs";
import {ROOT_URL} from "../../../../utils";

export function getUser(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/user`, {params:{id},headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_USER, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}
export function fetchUsersByValue(value) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/usersByValue`, {params:{value},headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_USERS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}
export function editUserInfo(user,key,value) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/user/edit`,user, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_USER_INFO, key,value})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function clearUsers() {
    return (dispatch) => {
        dispatch({type: CLEAR_USERS})
    };
}