import axios from "axios";
import {DELETE_TEACHER_STATUS, EDIT_TEACHER_STATUS, FETCH_TEACHERS_STATUS, INSERT_TEACHER_STATUS} from "./types";
import {ROOT_URL} from "../../../utils";
import Cookie from "js-cookie";
import {writeErrors} from "../../../utils/logs";


export function fetchTeachersStatus() {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.get(`${ROOT_URL}/v1/teachers/statuses`, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: FETCH_TEACHERS_STATUS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}



export function editTeachersStatus(teachersStatus) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.put(`${ROOT_URL}/v1/teachers/statuses/edit`, teachersStatus, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(() => {
            dispatch({type: EDIT_TEACHER_STATUS, payload: teachersStatus})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}


export function insertTeacherStatus(teachersStatus) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.post(`${ROOT_URL}/v1/teachers/statuses/create`, teachersStatus, {headers: {"Authorization": `Bearer ${token}`}});
    return (dispatch) => {
        request.then(({data}) => {
            dispatch({type: INSERT_TEACHER_STATUS, payload: data})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}

export function deleteTeacherStatus(id) {
    const token = Cookie.get('OA.UM-Auth');
    const request = axios.delete(`${ROOT_URL}/v1/teachers/statuses/delete`, {
        headers: {"Authorization": `Bearer ${token}`},
        params: {id}
    });
    return (dispatch) => {
        request.then(() => {
            dispatch({type: DELETE_TEACHER_STATUS, payload: id})
        }).catch((error) => {
            writeErrors(error);
        });
    }

}